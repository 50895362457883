import {
  Col,
  ErrorText,
  Row,
  SectionTitle,
  SwitchButtons
} from '@elotech/components';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { Roles } from '../../../roles';

const Label = styled.label`
  font-size: 12px;
`;

const ValoresTransferenciaSection = ({
  tipoItbi,
  valoresItbi,
  valoresFinanciamento,
  valorCalculado = 0,
  onChangeValoresItbi,
  onChangePercentualIsencaoItbi,
  onChangeObservacao,
  errors,
  errorMessages,
  onShowAvaliacoes,
  hasRole
}) => {
  const normalizedOnChange = ({ floatValue }, { target: { name } }) => {
    onChangeValoresItbi({
      target: {
        name,
        value: floatValue
      }
    });
  };

  const onChangeTipoFiltro = () => {
    if (valorIsencao === 'VALOR') {
      setValorIsencao('PERCENTUAL');
    } else {
      setValorIsencao('VALOR');
    }
  };

  const tipoFiltroOptions = [
    {
      key: 'PERCENTUAL',
      value: 'PERCENTUAL',
      label: 'Percentual'
    },
    {
      key: 'VALOR',
      value: 'VALOR',
      label: 'Valor'
    }
  ];

  const normalizedOnChangePercentualIsencao = (
    { floatValue },
    { target: { name } }
  ) => {
    onChangePercentualIsencaoItbi({
      target: {
        name,
        value: floatValue
      }
    });
  };

  const [valorIsencao, setValorIsencao] = useState('VALOR');

  return (
    <div>
      <SectionTitle>Valores da Transferência</SectionTitle>
      <Row>
        <Col md={2} className="form-group">
          <Label htmlFor="valorTransacaoInput">Valor Transação</Label>
          <NumberFormat
            prefix="R$ "
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            value={valoresItbi.valorTransacao}
            name="valorTransacao"
            disabled
          />
        </Col>
        <Col md={2} className="form-group">
          <Label htmlFor="valorTransacaoAvaliacaoInput">Valor Calculado</Label>
          <NumberFormat
            prefix="R$ "
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            value={valorCalculado}
            name="valorTerritorialCalculado"
            id="valorTransacaoAvaliacaoInput"
            disabled
          />
        </Col>
        <Col md={2} className="form-group">
          <Label htmlFor="valorTransacaoAvaliacaoInput">Valor Avaliação</Label>
          <NumberFormat
            prefix="R$ "
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            allowNegative={false}
            fixedDecimalScale
            value={valoresItbi.valorTransacaoAvaliacao}
            name="valorTransacaoAvaliacao"
            id="valorTransacaoAvaliacaoInput"
            onValueChange={normalizedOnChange}
            className={classnames({
              error: errors?.valorTransacaoAvaliacao,
              right: true
            })}
          />
          {errors?.valorTransacaoAvaliacao && (
            <ErrorText>{errorMessages?.valorTransacaoAvaliacao}</ErrorText>
          )}
        </Col>
        <Col md={2} className="form-group">
          <Label htmlFor="aliquotaTransacao">Alíquota</Label>
          <NumberFormat
            suffix=" %"
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            value={valoresItbi.aliquotaTransacao}
            name="aliquotaTransacao"
            disabled
          />
        </Col>
        <Col md={2} className="form-group">
          <Label htmlFor="valorTransacaoItbi">Valor ITBI</Label>
          <NumberFormat
            prefix="R$ "
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            value={valoresItbi.valorTransacaoItbi}
            name="valorTransacaoItbi"
            disabled
          />
        </Col>
        {hasRole(Roles.itbi_consultar_ultimas_avaliacoes.name) && (
          <Col md={2} className="form-group">
            <Label className="label" />
            <button className="btn module-color" onClick={onShowAvaliacoes}>
              Últimas avaliações
            </button>
          </Col>
        )}
      </Row>
      {tipoItbi.financiado || tipoItbi.anuencia ? (
        <Row>
          <Col md={2} className="form-group">
            <Label htmlFor="valorFinanciadoInput">
              {tipoItbi.financiado ? 'Valor Financiado' : 'Valor Anuência'}
            </Label>
            <NumberFormat
              prefix="R$ "
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              value={valoresFinanciamento.valorFinanciado}
              name="valorFinanciadoInput"
              disabled
            />
          </Col>
          <Col md={2} mdOffset={2} className="form-group">
            <Label htmlFor="valorFinanciadoAvaliacaoInput">
              {tipoItbi.financiado
                ? 'Valor Financiado Avaliação'
                : 'Valor Anuência Avaliação'}
            </Label>
            <NumberFormat
              prefix="R$ "
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              value={valoresFinanciamento.valorFinanciadoAvaliacao}
              name="valorFinanciadoAvaliacao"
              id="valorFinanciadoAvaliacaoInput"
              onValueChange={normalizedOnChange}
              className={classnames({
                error: errors.valorFinanciadoAvaliacao,
                right: true
              })}
            />
            {errors.valorFinanciadoAvaliacao && (
              <ErrorText>{errorMessages.valorFinanciadoAvaliacao}</ErrorText>
            )}
          </Col>
          <Col md={2} className="form-group">
            <Label htmlFor="aliquotaFinanciado">Alíquota</Label>
            <NumberFormat
              suffix=" %"
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              value={valoresFinanciamento.aliquotaFinanciado}
              name="aliquotaFinanciado"
              disabled
            />
          </Col>
          <Col md={2} className="form-group">
            <Label htmlFor="valorFinanciadoItbi">Valor ITBI</Label>
            <NumberFormat
              prefix="R$ "
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              value={valoresFinanciamento.valorFinanciadoItbi}
              name="valorFinanciadoItbi"
              disabled
            />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col md={2} mdOffset={4} className="form-group">
          <Label htmlFor="totalAvaliado">Valor Total Avaliação</Label>
          <NumberFormat
            prefix="R$ "
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            value={
              valoresItbi.valorTransacaoAvaliacao +
              valoresFinanciamento.valorFinanciadoAvaliacao
            }
            name="totalAvaliado"
            disabled
          />
        </Col>
        <Col md={2} mdOffset={2} className="form-group">
          <Label htmlFor="totalITBI">Valor Total ITBI</Label>
          <NumberFormat
            prefix="R$ "
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            value={
              valoresItbi.valorTransacaoItbi +
              valoresFinanciamento.valorFinanciadoItbi
            }
            name="totalITBI"
            disabled
          />
        </Col>
      </Row>
      {tipoItbi.isencao && (
        <Row>
          <Col md={2}>
            <label className="label">Tipo de Isenção</label>
            <SwitchButtons
              value={valorIsencao}
              onChange={onChangeTipoFiltro}
              name="tipoIsencao"
              options={tipoFiltroOptions}
            />
          </Col>
        </Row>
      )}
      <Row>
        {tipoItbi.isencao && valorIsencao === 'VALOR' && (
          <Col md={2} className="form-group">
            <Label htmlFor="valorIsencaoInput">Valor Total Isenção</Label>
            <NumberFormat
              prefix="R$ "
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              allowNegative={false}
              fixedDecimalScale
              name="valorIsencao"
              id="valorIsencaoInput"
              value={valoresItbi.valorIsencao}
              onValueChange={normalizedOnChange}
              className={classnames({
                error:
                  errors.valorIsencaoMenorIgualZero ||
                  errors.valorIsencaoMaiorAvaliacao,
                right: true
              })}
            />
            {errors.valorIsencao && (
              <ErrorText>{errorMessages.valorIsencaoMenorIgualZero}</ErrorText>
            )}
            {errors.valorIsencaoMaiorAvaliacao && (
              <ErrorText>{errorMessages.valorIsencaoMaiorAvaliacao}</ErrorText>
            )}
          </Col>
        )}
        {tipoItbi.isencao && valorIsencao === 'PERCENTUAL' && (
          <Col md={2} className="form-group">
            <Label htmlFor="PercentualIsencaoInput">
              Percentual Total Isenção
            </Label>
            <NumberFormat
              prefix="% "
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={0}
              allowNegative={false}
              fixedDecimalScale
              name="percentualIsencao"
              id="PercentualIsencaoInput"
              onValueChange={normalizedOnChangePercentualIsencao}
              className={classnames({
                error:
                  errors.percentualIsencaoMenorIgualZero ||
                  errors.percentualIsencaoMaiorCem,
                right: true
              })}
            />
            {errors.percentualIsencaoMenorIgualZero && (
              <ErrorText>
                {errorMessages.percentualIsencaoMenorIgualZero}
              </ErrorText>
            )}
            {errors.percentualIsencaoMaiorCem && (
              <ErrorText>{errorMessages.percentualIsencaoMaiorCem}</ErrorText>
            )}
          </Col>
        )}
        {tipoItbi.isencao && (
          <Col md={12}>
            <div className="form-group">
              <label htmlFor="observacaoIsencaoInput">Motivo Isenção</label>
              <textarea
                id="observacaoIsencaoInput"
                name="observacaoIsencao"
                onChange={onChangeObservacao}
              />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

ValoresTransferenciaSection.propTypes = {
  tipoItbi: PropTypes.object.isRequired,
  valorCalculado: PropTypes.number,
  observacaoIsencao: PropTypes.string,
  valoresItbi: PropTypes.shape({
    valorTransacao: PropTypes.number,
    valorTransacaoAvaliacao: PropTypes.number,
    aliquotaTransacao: PropTypes.number,
    valorTransacaoItbi: PropTypes.number,
    valorIsencao: PropTypes.number
  }),
  valoresFinanciamento: PropTypes.shape({
    valorFinanciado: PropTypes.number,
    valorFinanciadoAvaliacao: PropTypes.number,
    aliquotaFinanciado: PropTypes.number,
    valorFinanciadoItbi: PropTypes.number
  }),
  errors: PropTypes.object,
  errorMessages: PropTypes.object,
  onChangeValoresItbi: PropTypes.func.isRequired,
  onChangePercentualIsencaoItbi: PropTypes.func.isRequired,
  onChangeObservacao: PropTypes.func.isRequired,
  hasRole: PropTypes.func.isRequired
};

export default ValoresTransferenciaSection;
