import { CollapseRow, EmptyRow } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

import TiposItbiRowDiff from './TiposItbiRowDiff';

class TiposItbiRowRevisions extends Component {
  state = {
    open: false
  };

  onToggle = () => {
    this.setState(state => {
      return { open: !state.open };
    });
  };

  renderHeader = () => {
    const { itbiAlteracoes } = this.props;
    return (
      <React.Fragment>
        <td data-header="Data">
          <b className="td-label">Data</b>
          <FormattedDate value={itbiAlteracoes.revision.revisionDate} />{' '}
          <FormattedTime value={itbiAlteracoes.revision.revisionDate} />
        </td>
        <td data-header="Nome do Responsável">
          <b className="td-label">Nome do Responsável</b>
          {itbiAlteracoes.revision.userName}
        </td>
        <td>
          <b className="td-label">Tipo de Alteração</b>
          {itbiAlteracoes.revisionType === 'ADD' ? 'Adicionado' : 'Modificado'}
        </td>
      </React.Fragment>
    );
  };

  render() {
    const { itbiAlteracoes } = this.props;

    return (
      <CollapseRow
        className="reduced"
        renderHeader={this.renderHeader}
        buttonLabel={'Visualizar modificações'}
      >
        <tr style={{ backgroundColor: '#eaf1f9' }}>
          <td colSpan="5">
            <div className="panel table" style={{ marginBottom: 0 }}>
              <div className="panel-body">
                <div className="table table-responsive">
                  <div className="panel-table-outer">
                    <table className="panel-table border fancy">
                      <thead>
                        <tr>
                          <th>Alteração</th>
                          <th>Campo</th>
                          <th>Antes</th>
                          <th>Depois</th>
                        </tr>
                      </thead>
                      <tbody>
                        {itbiAlteracoes.diff.map((diff, index) => (
                          <TiposItbiRowDiff key={index} diff={diff} />
                        ))}
                        <EmptyRow
                          show={itbiAlteracoes.diff.length === 0}
                          colSpan={4}
                        >
                          Sem Alterações
                        </EmptyRow>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </CollapseRow>
    );
  }
}

TiposItbiRowRevisions.propTypes = {
  itbiAlteracoes: PropTypes.object.isRequired
};

export default TiposItbiRowRevisions;
