import DeepDiff from 'deep-diff';

const innerDiff = (oldObj, newObj, prefilter) => {
  const diff = DeepDiff.diff(oldObj, newObj, prefilter) || [];

  return diff.map(diffItem => {
    if (diffItem.kind === 'A') {
      return {
        ...diffItem,
        itemDiff: DeepDiff.diff(
          diffItem.item.lhs || {},
          diffItem.item.rhs || {}
        )
      };
    }
    return diffItem;
  });
};

const innerDiffChild = (oldObj, newObj, path) => {
  const diff = DeepDiff.diff(oldObj, newObj) || [];

  return diff.map(diffItem => {
    const pathDiff =
      diffItem.kind === 'E' ? [...path, ...diffItem.path] : [...path];

    return {
      ...diffItem,
      path: pathDiff
    };
  });
};

export const parseDiff = (history, initialObj = {}, prefilter) => {
  return history.map((element, index, arr) => ({
    entity: element.entity,
    revision: element.revision,
    revisionType: element.revisionType,
    diff:
      history.length === index + 1
        ? innerDiff(initialObj, element.entity, prefilter)
        : innerDiff(arr[index + 1].entity, element.entity, prefilter)
  }));
};

const normalize = (previousHistory, currentHistory, identity) => {
  const elementFoundById = (array, element) =>
    array.find(e => identity(e, element));

  const indexByElementFound = (array, element) =>
    array.findIndex(e => identity(e, element));

  const previousHistoryNormalized = [];
  const currentHistoryNormalized = [];

  previousHistory.forEach((element, index) => {
    const found = elementFoundById(currentHistory, element);
    const samePosition = indexByElementFound(currentHistory, element) === index;

    if (!samePosition) {
      currentHistoryNormalized.push(found ? found : undefined);
    }
    previousHistoryNormalized.push(element);
  });

  currentHistory.forEach((element, index) => {
    const found = elementFoundById(previousHistory, element);
    const samePosition =
      indexByElementFound(previousHistory, element) !== index;

    if (!samePosition) {
      previousHistoryNormalized.push(found ? found : undefined);
    }
    currentHistoryNormalized.push(element);
  });

  return [previousHistoryNormalized, currentHistoryNormalized];
};

export const parseDiffChildren = (histories, labelsChildren, identity) => {
  return histories.map((history, indexHistory, array) => {
    const isFirstHistory = histories.length === indexHistory + 1;
    const elementsDiff = [];

    labelsChildren.forEach(labelChild => {
      const [previousHistory, currentHistory] = isFirstHistory
        ? normalize([], history.entity[labelChild], identity)
        : normalize(
            array[indexHistory + 1].entity[labelChild],
            history.entity[labelChild],
            identity
          );

      previousHistory.map((element, index) => {
        const path = [labelChild];

        const isAtualizacao =
          previousHistory[index] !== undefined &&
          currentHistory[index] !== undefined;

        if (isAtualizacao) {
          path.push(
            history.entity[labelChild].findIndex(child =>
              identity(child, previousHistory[index])
            )
          );
        }

        elementsDiff.push(
          ...innerDiffChild(previousHistory[index], currentHistory[index], path)
        );
      });
    });

    return {
      entity: history.entity,
      revision: history.revision,
      revisionType: history.revisionType,
      diff: [...history.diff, ...elementsDiff]
    };
  });
};
