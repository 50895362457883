import React from 'react';

import Checkbox from '../inputs/Checkbox';
import BasicInput, { Props as BasicInputProps } from './BasicInput';

type Props = {
  id?: string;
  className?: string;
  simNaoValue?: boolean;
} & BasicInputProps;

const FormikCheckBox: React.FC<Props> = ({
  id,
  className = '',
  label,
  hint = '',
  simNaoValue,
  ...props
}) => {
  return (
    <BasicInput
      name={props.name}
      size={props.size}
      noLabel
      {...props}
      render={({ field }) => (
        <Checkbox
          id={id ?? props.name}
          name={props.name}
          checked={
            simNaoValue
              ? field.value === 'S' || field.value === true
              : field.value
          }
          onChange={field.onChange}
          label={label}
          className={className}
          hintText={hint}
          disabled={field.disabled}
        />
      )}
    />
  );
};

export default FormikCheckBox;
