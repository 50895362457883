import {
  ActionButton,
  ActionsGroup,
  DisplayDataItem,
  Switch,
  withAuth
} from '@elotech/components';
import { TIPO_IMOVEL_RURAL } from 'itbi-common/type';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

import { Roles } from '../../../roles';
import LaudoFormInformacao from './LaudoFormInformacao';

class LaudoAnaliseForm extends React.Component {
  static propTypes = {
    laudo: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    showQuickViewAvaliacoes: PropTypes.func.isRequired,
    auth: PropTypes.shape({
      hasRole: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    input: undefined,
    modified: false,
    desconsideraAreaConstruida: this.props.laudo.desconsideraAreaConstruida,
    modifiedSwitch: false
  };

  onSave = laudo => () => {
    const { update } = this.props;

    if (
      this.state.input &&
      this.state.desconsideraAreaConstruida !==
        this.props.laudo.desconsideraAreaConstruida
    ) {
      update({
        ...laudo,
        desconsideraAreaConstruida: this.state.desconsideraAreaConstruida,
        valorAvaliado: this.state.input
      }).then(() =>
        this.setState({
          input: undefined,
          modified: false,
          modifiedSwitch: false
        })
      );
    } else if (!this.state.input) {
      update({
        ...laudo,
        desconsideraAreaConstruida: this.state.desconsideraAreaConstruida
      }).then(() => this.setState({ modified: false, modifiedSwitch: false }));
    } else {
      update({
        ...laudo,
        valorAvaliado: this.state.input
      }).then(() =>
        this.setState({
          input: undefined,
          modified: false,
          modifiedSwitch: false
        })
      );
    }
  };

  onRecalcular = ({ valorCalculado }) => () => {
    this.setState({ input: valorCalculado, modified: true });
  };

  onChangeValue = ({ floatValue }) => {
    this.setState({ input: floatValue, modified: true });
  };

  onQuickViewAvaliacoes = () => {
    const { showQuickViewAvaliacoes, laudo } = this.props;
    const cadastro =
      laudo.tipoImovel === TIPO_IMOVEL_RURAL
        ? laudo.cadastroRural
        : laudo.cadastroImobiliario;
    showQuickViewAvaliacoes(cadastro?.id, laudo.tipoImovel);
  };

  canConfirmarAvaliacao = () =>
    this.props.auth.hasRole(Roles.laudo_confirmar_avaliacao.name);

  onHandleDesconsideraAreaConstruida = valor => {
    if (valor !== this.props.laudo.desconsideraAreaConstruida) {
      this.setState({
        modifiedSwitch: true,
        desconsideraAreaConstruida: !this.state.desconsideraAreaConstruida
      });
    } else {
      this.setState({
        modifiedSwitch: false,
        desconsideraAreaConstruida: !this.state.desconsideraAreaConstruida
      });
    }
  };

  renderConfirmButton = laudo => {
    if (
      (this.state.modified || this.state.modifiedSwitch) &&
      this.canConfirmarAvaliacao()
    ) {
      return (
        <ActionButton
          data-test-id="buttonConfirma"
          key={`confirma-${laudo.id}`}
          onClick={this.onSave(laudo)}
          label={'Confirma'}
          icon={'check'}
        />
      );
    }

    return null;
  };

  renderRecalcularButton = laudo => {
    if (this.canConfirmarAvaliacao()) {
      return (
        <ActionButton
          data-test-id="buttonEditar"
          key={`editar-${laudo.id}`}
          onClick={this.onRecalcular(laudo)}
          label={'Calcular valor'}
          icon={'calculator'}
        />
      );
    }
    return null;
  };

  isImovelRural = laudo => laudo?.tipoImovel === TIPO_IMOVEL_RURAL;

  renderComponent = laudo => {
    const { input, modified } = this.state;
    return (
      <>
        <DisplayDataItem md={2} title="Valor Avaliado">
          <NumberFormat
            prefix="R$ "
            data-test-id="inputValorAvaliado"
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            allowNegative={false}
            value={modified ? input : laudo.valorAvaliado}
            name={laudo.id}
            onValueChange={this.onChangeValue}
            style={modified ? { borderColor: 'orange' } : {}}
            disabled={!this.canConfirmarAvaliacao()}
            fixedDecimalScale
          />
        </DisplayDataItem>
      </>
    );
  };

  renderButtons = laudo => {
    const { desconsideraAreaConstruida } = this.state;
    return (
      <>
        <DisplayDataItem title="Desconsiderar área construída">
          <Switch
            data-test-id="desconsidera-area-switch-button"
            active={desconsideraAreaConstruida}
            onChange={() =>
              this.onHandleDesconsideraAreaConstruida(
                !desconsideraAreaConstruida
              )
            }
            name={'desconsideraAreaConstruida'}
          />
        </DisplayDataItem>
        <ActionsGroup>
          {this.renderConfirmButton(laudo)}
          {this.renderRecalcularButton(laudo)}
          <ActionButton
            key={`avaliacoes-${laudo.id}`}
            onClick={this.onQuickViewAvaliacoes}
            label={'Ver últimas avaliações'}
            icon="clipboard-list"
          />
        </ActionsGroup>
      </>
    );
  };

  render() {
    const { laudo } = this.props;

    return (
      <React.Fragment key={laudo.id}>
        <LaudoFormInformacao
          laudo={laudo}
          renderComponent={this.renderComponent}
          renderButtons={this.renderButtons}
          desconsideraAreaConstruida={this.state.desconsideraAreaConstruida}
        />
      </React.Fragment>
    );
  }
}

const ComponentWithAuth = withAuth(LaudoAnaliseForm);

export { ComponentWithAuth as default, LaudoAnaliseForm };
