import { BreadCrumbRoute } from '@elotech/components';

import DocumentosItbiFormPage from '../pages/documentos/DocumentosItbiFormPage';
import DocumentosItbiHistoryPage from '../pages/documentos/DocumentosItbiHistoryPage';
import DocumentosItbiListPage from '../pages/documentos/DocumentosItbiListPage';
import ParametroFormPage from '../pages/parametro/ParametroFormPage';
import ParamParcelamentoFormPage from '../pages/parcelamento/parametro/ParamParcelamentoFormPage';
import ParamParcelamentoListPage from '../pages/parcelamento/parametro/ParamParcelamentoListPage';
import SincronizacaoDadosPage from '../pages/sincronizacao-dados/SincronizacaoDadosPage';
import TermoResponsabilidadeFormPage from '../pages/termo-responsabilidade/TermoResponsabilidadeFormPage';
import TermoResponsabilidadeListPage from '../pages/termo-responsabilidade/TermoResponsabilidadeListPage';
import TiposItbiPageAlteracoes from '../pages/tipos-itbi/history/TiposItbiPageAlteracoes';
import TiposItbiFormPage from '../pages/tipos-itbi/TiposItbiFormPage';
import TiposItbiListPage from '../pages/tipos-itbi/TiposItbiListPage';
import { Roles } from '../roles';

const routesConfiguracoes: BreadCrumbRoute[] = [
  {
    title: 'Tipos de ITBI',
    icon: 'building',
    path: '/tipos-itbi',
    component: TiposItbiListPage,
    role: Roles.itbi_visualizar.name
  },
  {
    title: 'Tipos de ITBI',
    icon: 'building',
    parent: '/tipos-itbi',
    path: '/tipos-itbi/novo',
    component: TiposItbiFormPage,
    role: Roles.cadastro_tipos_itbi_adicionar.name
  },
  {
    title: 'Tipos de ITBI',
    icon: 'building',
    parent: '/tipos-itbi',
    path: '/tipos-itbi/:id',
    component: TiposItbiFormPage,
    role: Roles.cadastro_tipos_itbi_editar.name
  },
  {
    title: 'Tipos de ITBI',
    icon: 'building',
    parent: '/tipos-itbi',
    path: '/tipos-itbi/:id/historico',
    component: TiposItbiPageAlteracoes,
    role: Roles.itbi_visualizar.name
  },
  {
    title: 'Documentos ITBI',
    icon: 'file-alt',
    path: '/documentos',
    component: DocumentosItbiListPage,
    role: Roles.documentos_itbi_visualizar.name
  },
  {
    title: 'Documentos ITBI',
    icon: 'file-alt',
    parent: '/documentos',
    path: '/documentos/novo',
    component: DocumentosItbiFormPage,
    role: Roles.cadastro_documento_itbi_adicionar.name
  },
  {
    title: 'Documentos ITBI',
    icon: 'file-alt',
    parent: '/documentos',
    path: '/documentos/:id',
    component: DocumentosItbiFormPage,
    role: Roles.cadastro_documento_itbi_editar.name
  },
  {
    title: 'Documentos ITBI',
    icon: 'file-alt',
    parent: '/documentos',
    path: '/documentos/:id/history',
    component: DocumentosItbiHistoryPage,
    role: Roles.documentos_itbi_visualizar.name
  },
  {
    title: 'Parâmetros',
    icon: 'list-alt',
    path: '/parametro',
    component: ParametroFormPage
  },
  {
    title: 'Parâmetros de Parcelamento',
    icon: 'file-invoice-dollar',
    path: '/parametro-parcelamento',
    component: ParamParcelamentoListPage
  },
  {
    title: 'Parâmetros de Parcelamento',
    icon: 'file-invoice-dollar',
    parent: '/parametro-parcelamento',
    path: '/parametro-parcelamento/:id',
    component: ParamParcelamentoFormPage
  },
  {
    title: 'Termo de Responsabilidade',
    icon: 'gavel',
    path: '/termo-responsabilidade',
    component: TermoResponsabilidadeListPage,
    role: Roles.termo_responsabilidade_visualizar.name
  },
  {
    title: 'Termo de Responsabilidade',
    icon: 'gavel',
    parent: '/termo-responsabilidade',
    path: '/termo-responsabilidade/:id',
    component: TermoResponsabilidadeFormPage,
    role: Roles.cadastro_termo_responsabilidade.name
  },
  {
    title: 'Sincronização de dados',
    icon: 'sync',
    path: '/sincronizacao-dados',
    component: SincronizacaoDadosPage,
    role: Roles.acessar_sincronizacao_dados.name
  }
];

export default routesConfiguracoes;
