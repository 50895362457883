import {
  ForbiddenPage,
  NotFoundPage,
  ProtectedRoute
} from '@elotech/components';
import { RouteWithRedirect } from 'itbi-common/components';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../pages/home/Home';
import NotificacaoPage from '../pages/notificacao/NotificacaoPage';
import MyAccountPage from '../pages/user/MyAccountPage';
import routesConfiguracoes from './RoutesConfiguracoes';
import routesItbi from './RoutesItbi';
import routesLaudo from './RoutesLaudo';

const rootRoutes = [
  {
    title: 'MyAccountPage',
    icon: 'user',
    path: '/minha-conta',
    component: MyAccountPage
  },
  {
    title: 'Notificações',
    icon: 'envelope',
    path: '/notificacoes',
    component: NotificacaoPage
  }
];

const routeComponents = routes =>
  routes.map(({ path, component, role, exact = true }, key) =>
    role ? (
      <ProtectedRoute
        role={role}
        exact={exact}
        path={path}
        component={component}
        key={key}
      />
    ) : (
      <RouteWithRedirect
        exact={exact}
        path={path}
        component={component}
        key={key}
      />
    )
  );

const Routes = () => {
  return (
    <Switch>
      <RouteWithRedirect path="/" component={Home} exact />
      {routeComponents(rootRoutes)}
      {routeComponents(routesLaudo)}
      {routeComponents(routesItbi)}
      {routeComponents(routesConfiguracoes)}
      <Route path="/sem-permissao" component={ForbiddenPage} exact />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

const allRoutes = [
  ...rootRoutes,
  ...routesLaudo,
  ...routesItbi,
  ...routesConfiguracoes
];

export { allRoutes };
export default Routes;
