import { Col, ErrorText, Icon, Row, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Roles } from '../../roles';

const StyledButton = styled.button``;

const errorMessages = {
  tipoCampo: 'O tipo do campo é obrigatório',
  label: 'O nome do campo é obrigatório'
};

const CamposDocumentoSection = ({
  currentCampo,
  onChange,
  tipoCampos,
  selectedCampos,
  onAdd,
  onRemove,
  hasRole,
  error
}) => {
  const onAddClick = () => {
    onAdd();
  };

  return (
    <div>
      <SectionTitle>Campos Documento</SectionTitle>
      <div>
        {hasRole(Roles.cadastro_documento_itbi_adicionar_campo.name) && (
          <Row>
            <Col md={8}>
              <Row>
                <Col md={5}>
                  <label htmlFor="nomeCampoDocumento" className="label">
                    Nome Campo
                  </label>
                  <input
                    id="nomeCampoDocumento"
                    type="text"
                    name="label"
                    value={currentCampo.label || ''}
                    onChange={onChange}
                  />
                  {error.label && <ErrorText>{errorMessages.label}</ErrorText>}
                </Col>
                <Col md={5}>
                  <label htmlFor="tipoCampoSelect" className="label">
                    Tipo Campo
                  </label>
                  <select
                    id="tipoCampoSelect"
                    name="tipoCampo"
                    onChange={onChange}
                    value={currentCampo.tipoCampo}
                  >
                    <option value="">Selecione</option>
                    {tipoCampos.map(tipoCampo => (
                      <option key={tipoCampo.name} value={tipoCampo.name}>
                        {tipoCampo.descricao}
                      </option>
                    ))}
                  </select>
                  {error.tipoCampo && (
                    <ErrorText>{errorMessages.tipoCampo}</ErrorText>
                  )}
                </Col>
                <Col md={2}>
                  <label className="label" />
                  <button
                    data-test-id="addButton"
                    type="button"
                    className="btn module-color"
                    onClick={onAddClick}
                  >
                    Adicionar
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {selectedCampos.length > 0 && (
          <Row>
            <Col md={8}>
              <div className="table-editable">
                <div className="table-editable-header hidden-xs">
                  <Row>
                    <Col md={5}>Nome do Campo</Col>
                    <Col md={5}>Tipo do Campo</Col>
                    <Col md={2} />
                  </Row>
                </div>
                <div className="table-editable-content">
                  {selectedCampos.map((documento, index) => (
                    <Row key={documento.id + index}>
                      <Col md={5} className="column" data-header="CPF">
                        {documento.label}
                      </Col>
                      <Col md={5} className="column" data-header="Nome">
                        {documento.tipoCampo
                          ? documento.tipoCampo.descricao
                          : ''}
                      </Col>
                      <Col md={2} className="btn-actions">
                        {hasRole(
                          Roles.cadastro_documento_itbi_remover_campo.name
                        ) && (
                          <StyledButton
                            data-test-id="removeButton"
                            className="btn"
                            onClick={() => onRemove(index)}
                          >
                            <Icon
                              icon="trash-alt"
                              darkPrimary
                              hoverColor="white"
                              hoverParent={StyledButton}
                            />
                            <div className="btn-actions-label"> Remover</div>
                          </StyledButton>
                        )}
                      </Col>
                    </Row>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

CamposDocumentoSection.propTypes = {
  tipoCampos: PropTypes.array,
  currentCampo: PropTypes.object,
  selectedCampos: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  hasRole: PropTypes.func.isRequired
};

export default CamposDocumentoSection;
