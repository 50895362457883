import { Alert } from '@elotech/components';
import { DeclaracaoUtils as DeclaracaoUtilsCommon } from 'itbi-common/utils';

const DeclaracaoUtils = {
  transferir: (serviceFunction, setState) => {
    return new Promise((resolve, reject) => {
      Alert.question({
        title: 'Deseja realmente fazer a transferência?'
      }).then(result => {
        if (result.value) {
          setState({ submitting: true });
          serviceFunction()
            .then(() => {
              setState({ submitting: false });
              Alert.success({
                title: 'Transferência Concluída',
                text: 'Imóvel transferido manualmente'
              });
              resolve();
            })
            .catch(error => {
              setState({ submitting: false });
              switch (error?.response?.data?.status) {
                case 400:
                  Alert.warning({
                    title: 'Transferência não permitida',
                    text: error?.response?.data?.message
                  });
                  break;
                default:
                  Alert.error({ title: 'Erro ao fazer Transferência' }, error);
                  break;
              }
              reject(error);
            });
        }
      });
    });
  },

  notificar: (serviceFunction, setState) => {
    return new Promise((resolve, reject) => {
      Alert.question({
        title: 'Deseja realmente notificar o requerente?'
      }).then(result => {
        if (result.value) {
          setState({ submitting: true });
          serviceFunction()
            .then(() => {
              setState({ submitting: false });
              Alert.success({
                title: 'Requerente Notificado!',
                text: 'Processo finalizado e requerente notificado.'
              });
              resolve();
            })
            .catch(error => {
              setState({ submitting: false });
              Alert.error({ title: 'Erro ao notificar cidadão' }, error);
              reject(error);
            });
        }
      });
    });
  },

  estornar: (serviceFunction, setState, inputValidator) => {
    return new Promise((resolve, reject) => {
      Alert.question({
        title: 'Qual o motivo deste estorno?',
        input: 'textarea',
        inputPlaceholder: 'Descreva o motivo do estorno',
        inputValidator: inputValidator,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          setState({ submitting: true });
          serviceFunction(result.value)
            .then(async () => {
              setState({ submitting: false });
              await Alert.success({
                title: 'Estorno efetuado',
                text: 'O estorno foi efetuado com sucesso'
              });
              resolve();
            })
            .catch(error => {
              setState({ submitting: false });
              Alert.error({ title: 'Erro ao fazer Estorno' }, error);
              reject(error);
            });
        }
      });
    });
  },

  cancelar: (serviceFunction, setState, inputValidator) => {
    return new Promise((resolve, reject) => {
      Alert.question({
        title: 'Qual o motivo deste cancelamento?',
        input: 'textarea',
        inputPlaceholder: 'Descreva o motivo do cancelamento',
        inputValidator: inputValidator,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          setState({ submitting: true });
          serviceFunction(result.value)
            .then(async () => {
              setState({ submitting: false });
              await Alert.success({
                title: 'Cancelamento efetuado',
                text: 'O cancelamento/indeferimento foi efetuado com sucesso'
              });
              resolve(result);
            })
            .catch(error => {
              setState({ submitting: false });
              Alert.error({ title: 'Erro ao fazer Cancelamento' }, error);
              reject(error);
            });
        }
      });
    });
  },
  downloadCertidaoQuitacao: DeclaracaoUtilsCommon.downloadCertidaoQuitacao,
  printCertidaoIsencao: DeclaracaoUtilsCommon.imprimirDocumentoIsencao
};

export default DeclaracaoUtils;
