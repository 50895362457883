export const rolesLAUDO = {
  laudo_assumir_pedido: {
    name: 'laudo_assumir_pedido',
    description: 'Permite assumir um novo pedido de laudo'
  },
  laudo_confirmar_avaliacao: {
    name: 'laudo_confirmar_avaliacao',
    description: 'Permite confirmar a avaliação do cadastro'
  },
  laudo_editar_pedido_terceiros: {
    name: 'laudo_editar_pedido_terceiros',
    description: '	Permite editar pedido de laudo de outros usuários'
  },
  laudo_estornar: {
    name: 'laudo_estornar',
    description:
      'Permissão para fazer um laudo não pago sair do status LIBERADO e voltar para ANALISANDO'
  },
  laudo_finalizar_analise: {
    name: 'laudo_finalizar_analise',
    description:
      'Permite finalizar a análise do lote referente ao laudo de avaliação'
  },
  laudo_imprimir: {
    name: 'laudo_imprimir',
    description: 'Permite imprimir o laudo de avaliação gerado'
  },
  laudo_visualizar: {
    name: 'laudo_visualizar',
    description: 'Permite visualizar os laudos de avaliação'
  }
};
