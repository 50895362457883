import {
  DisplayDataGrid,
  DisplayDataItem,
  FormattedCurrency,
  Row
} from '@elotech/components';
import { TIPO_IMOVEL_RURAL } from 'itbi-common/type';
import { LaudoUtils } from 'itbi-common/utils';
import React from 'react';

type Props = {
  laudo: any;
  renderComponent(laudo: any): any;
  renderButtons?(laudo: any): any;
  desconsideraAreaConstruida?: any;
};

const LaudoFormInformacao: React.FC<Props> = props => {
  const isImovelRural = () => props.laudo?.tipoImovel === TIPO_IMOVEL_RURAL;

  return (
    <>
      <DisplayDataGrid column className="has-btn-actions">
        <Row className="active">
          <>{props.renderButtons && props.renderButtons(props.laudo)}</>
        </Row>
        <Row className="active">
          <DisplayDataItem md={2} title="Cadastro">
            {isImovelRural()
              ? props.laudo.cadastroRural?.cadastro
              : props.laudo.cadastroImobiliario?.cadastro}
          </DisplayDataItem>
          <DisplayDataItem md={5} title="Endereço">
            {LaudoUtils.buildEnderecoCadastro(props.laudo)}
          </DisplayDataItem>
          <DisplayDataItem md={2} title="Valor Estimado">
            {<FormattedCurrency value={props.laudo.valorEstimado} />}
          </DisplayDataItem>
          <>{props.renderComponent(props.laudo)}</>
        </Row>
        {isImovelRural() ? (
          <>
            <Row>
              <DisplayDataItem md={6} title={'Nome'}>
                {props.laudo.cadastroRural?.nome}
              </DisplayDataItem>
              <DisplayDataItem md={6} title={'Endereço Descrito'}>
                {props.laudo.cadastroRural?.enderecoDescrito}
              </DisplayDataItem>
            </Row>
            <Row>
              <DisplayDataItem md={4} title={'Logradouro'}>
                {props.laudo.cadastroRural?.logradouro}
              </DisplayDataItem>
              <DisplayDataItem md={2} title={'Bairro'}>
                {props.laudo.cadastroRural?.bairro}
              </DisplayDataItem>
              <DisplayDataItem md={2} title={'Complemento'}>
                {props.laudo.cadastroRural?.complemento}
              </DisplayDataItem>
              <DisplayDataItem md={2} title={'Distrito'}>
                {props.laudo.cadastroRural?.distrito}
              </DisplayDataItem>
              <DisplayDataItem md={2} title={'Setor'}>
                {props.laudo.cadastroRural?.setor}
              </DisplayDataItem>
            </Row>
            <Row>
              <DisplayDataItem md={2} title={'NIRF'}>
                {props.laudo.cadastroRural?.numeroReceita}
              </DisplayDataItem>
              <DisplayDataItem md={2} title={'Número no INCRA'}>
                {props.laudo.cadastroRural?.numeroIncra}
              </DisplayDataItem>
              <DisplayDataItem md={2} title={'Qtd. Alqueires'}>
                {props.laudo.cadastroRural?.alqueires}
              </DisplayDataItem>
              <DisplayDataItem md={2} title={'Qtd. Hectares'}>
                {props.laudo.cadastroRural?.hectares}
              </DisplayDataItem>
              <DisplayDataItem md={4} title={'Área Total'}>
                {props.laudo.cadastroRural?.areaTotal
                  ? `${props.laudo.cadastroRural?.areaTotal} m²`
                  : ''}
              </DisplayDataItem>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <DisplayDataItem md={5} title={'Proprietário'}>
                {props.laudo.cadastroImobiliario?.proprietario}
              </DisplayDataItem>
              <DisplayDataItem md={1} title={'Zona'}>
                {props.laudo.cadastroImobiliario?.zona}
              </DisplayDataItem>
              <DisplayDataItem md={1} title={'Quadra'}>
                {props.laudo.cadastroImobiliario?.quadra}
              </DisplayDataItem>
              <DisplayDataItem md={1} title={'Data/Lote'}>
                {props.laudo.cadastroImobiliario?.lote}
              </DisplayDataItem>
              <DisplayDataItem md={2} title={'Área terreno'}>
                {props.laudo.cadastroImobiliario?.areaTerreno
                  ? `${props.laudo.cadastroImobiliario.areaTerreno} m²`
                  : ''}
              </DisplayDataItem>
              <DisplayDataItem md={2} title={'Área construída'}>
                {props.laudo.cadastroImobiliario?.areaConstruida &&
                props.desconsideraAreaConstruida !== true
                  ? `${props.laudo.cadastroImobiliario.areaConstruida} m²`
                  : ''}
              </DisplayDataItem>
            </Row>
            <Row>
              <DisplayDataItem md={6} title={'Complemento'}>
                {props.laudo.cadastroImobiliario?.complemento}
              </DisplayDataItem>

              <DisplayDataItem md={6} title={'Complemento Lote'}>
                {props.laudo.cadastroImobiliario?.complementoLote}
              </DisplayDataItem>
            </Row>
            <Row>
              <DisplayDataItem md={6} title={'Condomínio'}>
                {props.laudo.cadastroImobiliario?.condominio
                  ? `${props.laudo.cadastroImobiliario.condominio} - ${props.laudo.cadastroImobiliario.descricaoCondominio}`
                  : ''}
              </DisplayDataItem>
            </Row>
            {props.laudo.observacao ? (
              <Row>
                <DisplayDataItem md={12} title={'Observação sobre o cadastro'}>
                  {props.laudo.observacao}
                </DisplayDataItem>
              </Row>
            ) : null}
          </>
        )}
      </DisplayDataGrid>
    </>
  );
};

export default LaudoFormInformacao;
