import LaudoForm from 'itbi-common/components/laudo/LaudoForm';
import { LoteLaudo } from 'itbi-common/type';
import React from 'react';
import { useHistory } from 'react-router';

type Props = {};

const LoteLaudoFormPage: React.FC<Props> = () => {
  const history = useHistory();
  const onSubmit = (value: LoteLaudo) => {
    const path =
      value.situacaoLaudo.nome === 'ANALISANDO'
        ? `/laudos/${value.id}`
        : `/laudos/${value.id}/resumo`;
    history.replace(path);
  };

  return (
    <div>
      <LaudoForm servidor afterSubmit={onSubmit} />
    </div>
  );
};

export default LoteLaudoFormPage;
