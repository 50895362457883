import { AutoComplete, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

export const NewResponsavelSelector = ({
  selectedValue,
  onChange,
  loadOptions
}) => {
  return (
    <div>
      <SectionTitle>Novo responsável</SectionTitle>
      <div>
        <AutoComplete
          placeholder="Selecione um novo usuário"
          isClearable
          value={selectedValue}
          onSearch={loadOptions}
          onSelect={onChange}
          getOptionLabel={pessoa => pessoa.nome}
        />
      </div>
    </div>
  );
};

NewResponsavelSelector.propTypes = {
  selectedValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired
};
