import { Col, Row, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Bold = styled.span`
  text-weigth: bold;
`;

const ObservacaoSection = props => {
  const { observacaoCidadao, observacaoServidor, onChange } = props;
  return (
    <div>
      <SectionTitle>Observações</SectionTitle>
      {observacaoCidadao && (
        <Row>
          <Col md={12}>
            <div className="info">
              <Bold>Observação do cidadão: </Bold>
              {observacaoCidadao}
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12}>
          <div className="form-group">
            <label htmlFor="observacaoServidorInput">
              Observação sobre o requerimento
            </label>
            <textarea
              id="observacaoServidorInput"
              name="observacaoServidor"
              value={observacaoServidor}
              onChange={onChange}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

ObservacaoSection.propTypes = {
  observacaoCidadao: PropTypes.string.isRequired,
  observacaoServidor: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ObservacaoSection;
