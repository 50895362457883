import { Roles } from '../types';

export const rolesPrioritario: Roles = {
  acesso_prioritario: {
    name: 'acesso_prioritario',
    description: 'Permite marcar o pedido como prioritário'
  },
  acesso_total_cadastros: {
    name: 'acesso_total_cadastros',
    description: 'Todos os acessos relacionados ao menu de cadastro',
    composite: true,
    composites: {
      client: {
        'itbi-backend': [
          'cadastro_tipos_itbi_remover',
          'cadastro_tipos_itbi_alterar_visibilidade',
          'cadastro_documento_itbi_editar',
          'cadastro_tipos_itbi_editar',
          'cadastro_documento_itbi_remover',
          'cadastro_documento_itbi_adicionar_campo',
          'cadastro_tipos_itbi_adicionar',
          'cadastro_documento_itbi_adicionar',
          'cadastro_documento_itbi_adicionar_vinculo',
          'cadastro_documento_itbi_remover_vinculo',
          'cadastro_documento_itbi_remover_campo'
        ]
      }
    }
  },
  acesso_total_declaracao_itbi: {
    name: 'acesso_total_declaracao_itbi',
    description: 'Todos os acessos relacionados ao pedido de ITBI',
    composite: true,
    composites: {
      client: {
        'itbi-backend': [
          'notificar_cidadao',
          'itbi_restaurar_construcao',
          'itbi_transferir_manual',
          'itbi_consultar_ultimas_avaliacoes',
          'itbi_remover_proprietario_remanescente',
          'itbi_remover_construcao',
          'itbi_finalizar_pedido',
          'itbi_estornar_pedido',
          'itbi_editar_pedido_terceiros',
          'itbi_imprimir_boleto',
          'transferir_responsavel',
          'itbi_editar_anuente',
          'itbi_remover_vendedor',
          'itbi_gerar_debito',
          'itbi_editar_vendedor',
          'itbi_editar_comprador',
          'itbi_remover_comprador',
          'itbi_remover_anuente',
          'itbi_editar_construcao',
          'acesso_prioritario'
        ]
      }
    }
  },
  acesso_total_laudo: {
    name: 'acesso_total_laudo',
    description: 'Todos os acessos do pedido de laudo',
    composite: true,
    composites: {
      client: {
        'itbi-backend': [
          'notificar_cidadao',
          'transferir_responsavel',
          'laudo_confirmar_avaliacao',
          'laudo_editar_pedido_terceiros',
          'laudo_assumir_pedido',
          'laudo_finalizar_analise',
          'acesso_prioritario',
          'laudo_imprimir'
        ]
      }
    }
  },
  acesso_total_menu_inicial: {
    name: 'acesso_total_menu_inicial',
    description: 'Todos os acessos relacionados ao menu inicial (Home)',
    composite: true,
    composites: {
      client: {
        'itbi-backend': [
          'notificar_cidadao',
          'itbi_estornar_pedido',
          'itbi_imprimir_boleto',
          'acessar_sincronizacao_dados',
          'transferir_responsavel',
          'laudo_assumir_pedido',
          'itbi_assumir_pedido'
        ]
      }
    }
  }
};
