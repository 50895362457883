import { ErrorText } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const TiposItbiAise = props => {
  const {
    tiposItbiAise = {},
    onChangeSelect,
    listTiposITBIAise,
    error,
    errorMessages
  } = props;

  return (
    <div className="form-group">
      <label htmlFor="requerente">Tipo ITBI do Aise</label>
      <select
        data-test-id="tipoItbiAiseSelect"
        name="tipoItbiAise"
        className={error.tipoItbiAise ? 'error' : ''}
        onChange={onChangeSelect}
        value={tiposItbiAise.id}
      >
        <option value="">Selecione</option>
        {listTiposITBIAise.map(item => (
          <option key={item.id} value={item.id}>
            {item.descricao}
          </option>
        ))}
      </select>
      {error.tipoItbiAise && (
        <ErrorText>{errorMessages.tipoItbiAise}</ErrorText>
      )}
    </div>
  );
};

TiposItbiAise.propTypes = {
  tipoItbiAise: PropTypes.object,
  onChangeSelect: PropTypes.func.isRequired,
  error: PropTypes.object,
  listTiposITBIAise: PropTypes.array.isRequired
};

export default TiposItbiAise;
