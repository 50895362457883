import {
  ActionButton,
  ActionsGroup,
  Container,
  Loading,
  NotificationActions,
  Panel,
  SearchFilter,
  SearchPagination,
  Table
} from '@elotech/components';
import { Field, PagedResponse } from '@elotech/components/src/type';
import { AxiosResponse } from 'axios';
import { History } from 'history';
import { ParamParcelamentoService } from 'itbi-common/service';
import React from 'react';
import { Notification } from 'react-notification-system';
import { connect } from 'react-redux';

import { ParamParcelamento } from '../../../types';

type Props = {
  history: Pick<History, 'push'>;
  showNotification(notification: Notification): void;
};

type State = {
  loading: boolean;
  paramParcelamentos: ParamParcelamento[];
  searchParam: string;
  pagination?: any;
};

const searchFields: Field[] = [
  {
    label: 'Código',
    name: 'id',
    type: 'NUMBER'
  },
  {
    label: 'Descrição',
    name: 'descricao',
    type: 'STRING'
  },
  {
    label: 'Tipo',
    name: 'tipo',
    type: 'ENUM',
    options: [
      {
        name: 'P',
        descricao: 'Parcelamento'
      },
      {
        name: 'R',
        descricao: 'Reparcelamento'
      }
    ]
  },
  {
    label: 'Ativo',
    name: 'ativo',
    type: 'ENUM',
    options: [
      {
        name: 'true',
        descricao: 'Ativo'
      },
      {
        name: 'false',
        descricao: 'Inativo'
      }
    ]
  },
  {
    label: 'Disponível Cidadão',
    name: 'disponivelCidadao',
    type: 'ENUM',
    options: [
      {
        name: 'false',
        descricao: 'Indisponível'
      },
      {
        name: 'true',
        descricao: 'Disponível'
      }
    ]
  }
];

class ParamParcelamentoListPage extends React.Component<Props, State> {
  state: State = {
    loading: false,
    paramParcelamentos: [],
    searchParam: '',
    pagination: undefined
  };

  onSearch = (searchParam: string = this.state.searchParam, page?: any) => {
    this.setState({ loading: true });

    ParamParcelamentoService.findByRsql(searchParam, page)
      .then(this.onSearchSuccess)
      .finally(() => this.setState({ loading: false }));
  };

  onSearchSuccess = (
    response: AxiosResponse<PagedResponse<ParamParcelamento>>
  ) => {
    const {
      content,
      number,
      totalPages,
      first,
      last,
      numberOfElements,
      size
    } = response.data;

    if (content && content.length > 0) {
      return this.setState({
        paramParcelamentos: content,
        pagination: {
          number,
          totalPages,
          first,
          last,
          numberOfElements,
          size
        }
      });
    }
    this.props.showNotification({
      level: 'warning',
      message:
        'Não foi possível encontrar nenhum parâmetro de parcelamento com o filtro informado.'
    });
    return this.setState({ paramParcelamentos: [], pagination: undefined });
  };

  onEdit = (paramParcelamento: ParamParcelamento) => {
    this.props.history.push(`/parametro-parcelamento/${paramParcelamento.id}`);
  };

  paginationSearch = (page: any) => this.onSearch(undefined, page);

  render() {
    const { loading, paramParcelamentos, pagination } = this.state;

    return (
      <Container title="Parâmetros de Parcelamento" icon="file-invoice-dollar">
        <Loading loading={loading} />
        <Panel isTable>
          <SearchFilter fields={searchFields} search={this.onSearch} />
          <Table<ParamParcelamento> values={paramParcelamentos}>
            <Table.Column<ParamParcelamento>
              header="Código"
              value={item => item.id}
            />
            <Table.Column<ParamParcelamento>
              header="Descrição"
              value={item => item.descricao}
            />
            <Table.Column<ParamParcelamento>
              header="Tipo"
              value={item =>
                item.tipo === 'P' ? 'Parcelamento' : 'Reparcelamento'
              }
            />
            <Table.Column<ParamParcelamento>
              header="Disponível Cidadão"
              value={item =>
                item.disponivelCidadao ? 'Disponível' : 'Indisponível'
              }
            />
            <Table.Column<ParamParcelamento>
              header="Ativo"
              value={item => (item.ativo ? 'Ativo' : 'Inativo')}
            />
            <Table.Column<ParamParcelamento>
              header=""
              value={item => (
                <ActionsGroup>
                  <ActionButton
                    data-test-id="edit-button"
                    key="edit-button"
                    icon="pencil-alt"
                    label="Editar"
                    onClick={() => this.onEdit(item)}
                  />
                </ActionsGroup>
              )}
            />
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={this.paginationSearch}
            />
          )}
        </Panel>
      </Container>
    );
  }
}

const ConnectedComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(ParamParcelamentoListPage);

export { ConnectedComponent as default, ParamParcelamentoListPage };
