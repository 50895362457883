import { Col, ErrorText, Icon, Row, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Roles } from '../../roles';

const StyledButton = styled.button``;

const errorMessages = {
  tipoItbi: 'Tipo ITBI é obrigatório'
};

const VinculoTipoItbiSection = ({
  currentTipo,
  onChange,
  tipos,
  selectedTipos,
  onAdd,
  onRemove,
  hasRole,
  error
}) => {
  const filterOption = object =>
    !selectedTipos.some(selectedTipo => selectedTipo.id === object.id);

  const options = tipos.filter(filterOption);

  const onAddClick = () => {
    onAdd(currentTipo);
  };

  return (
    <div>
      <SectionTitle>Vinculo Tipo ITBI</SectionTitle>
      <div>
        {hasRole(Roles.cadastro_documento_itbi_adicionar_vinculo.name) && (
          <Row>
            <Col md={8}>
              <Row>
                <Col md={5}>
                  <label className="label" htmlFor="tipoItbiSelect">
                    Tipo ITBI
                  </label>
                  <select
                    id="tipoItbiSelect"
                    name="tipoItbi"
                    onChange={onChange}
                    value={currentTipo.tipoItbi}
                  >
                    <option value="">Selecione</option>
                    {options.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.descricao}
                      </option>
                    ))}
                  </select>
                  {error.tipoItbi && (
                    <ErrorText>{errorMessages.tipoItbi}</ErrorText>
                  )}
                </Col>
                <Col md={2}>
                  <label className="label" />
                  <button
                    data-testid="addButton"
                    type="button"
                    className="btn module-color"
                    onClick={onAddClick}
                  >
                    Adicionar
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {selectedTipos.length > 0 && (
          <Row>
            <Col md={8}>
              <div className="table-editable">
                <div className="table-editable-header hidden-xs">
                  <Row>
                    <Col md={10}>Tipo de Itbi</Col>
                    <Col md={2} />
                  </Row>
                </div>
                <div className="table-editable-content">
                  {selectedTipos.map((tipoItbi, index) => (
                    <Row key={tipoItbi.id + index}>
                      <Col md={10} className="column" data-header="CPF">
                        {tipoItbi.descricao}
                      </Col>
                      <Col md={2} className=" btn-actions">
                        {hasRole(
                          Roles.cadastro_documento_itbi_remover_vinculo.name
                        ) && (
                          <StyledButton
                            data-testid="removeButton"
                            className="btn"
                            onClick={() => onRemove(index)}
                          >
                            <Icon
                              icon="trash-alt"
                              darkPrimary
                              hoverColor="white"
                              hoverParent={StyledButton}
                            />
                            <div className="btn-actions-label"> Remover</div>
                          </StyledButton>
                        )}
                      </Col>
                    </Row>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

VinculoTipoItbiSection.propTypes = {
  currentTipo: PropTypes.object,
  tipos: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  hasRole: PropTypes.func.isRequired
};

export default VinculoTipoItbiSection;
