import {
  Button,
  Container,
  ErrorText,
  FAB,
  Masks,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

const errorMessages = {
  cpfCnpj: 'O CPF é obrigatório.',
  nome: 'O nome é obrigatório.',
  email: 'O email é obrigatório.'
};

type PrivacyProps = {
  onResetPassword(): void;
};

type FormProps = {
  user: any;
  error: any;
  loading: boolean;
  onResetPassword(): void;
  onChangeField(): void;
  onChangeFieldMask(): void;
  onSave(): void;
};
const Privacy: React.FunctionComponent<PrivacyProps> = props => {
  const { onResetPassword } = props;
  return (
    <div>
      <SectionTitle>Privacidade e Segurança</SectionTitle>
      <Button onClick={onResetPassword}>REDEFINIR A SENHA</Button>
    </div>
  );
};

const MyAccountForm: React.FunctionComponent<FormProps> = props => {
  const {
    user,
    error,
    onChangeField,
    onChangeFieldMask,
    onSave,
    onResetPassword,
    loading
  } = props;
  const { cpfCnpj, nome, email } = user;
  return (
    <Container title="Minha Conta" icon="user">
      <SectionTitle marginTop="0px">Dados Pessoais</SectionTitle>
      <div className="row">
        <div className="form-group col-md-3">
          <label htmlFor="cpfCnpjInput" className="label">
            CPF
          </label>
          <MaskedInput
            id="cpfCnpjInput"
            type="text"
            name="cpfCnpj"
            value={cpfCnpj}
            onChange={onChangeFieldMask}
            mask={Masks.MASK_CPF}
            disabled={true}
            className={error.cpfCnpj ? 'error' : ''}
            maxLength={15}
          />
        </div>

        <div className="form-group col-md-3">
          <label htmlFor="nameInput" className="label">
            Nome Completo
          </label>
          <input
            id="nameInput"
            type="text"
            value={nome}
            name="nome"
            onChange={onChangeField}
          />
          {error.nome && <ErrorText>{errorMessages.nome}</ErrorText>}
        </div>

        <div className="form-group col-md-3">
          <label htmlFor="emailInput" className="label">
            E-mail
          </label>
          <input
            id="emailInput"
            type="text"
            value={email}
            name="email"
            onChange={onChangeField}
          />
          {error.email && <ErrorText>{errorMessages.email}</ErrorText>}
        </div>
      </div>

      <Privacy onResetPassword={onResetPassword} />
      <div className="btn-save">
        <FAB
          loading={loading}
          icon="check"
          iconColor="white"
          title="Salvar"
          onClick={onSave}
        />
      </div>
    </Container>
  );
};

Privacy.propTypes = {
  onResetPassword: PropTypes.func.isRequired
};

export { Privacy };
export default MyAccountForm;
