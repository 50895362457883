import { Alert, Container, FAB, Icon, withAuth } from '@elotech/components';
import {
  DocumentoItbiService,
  ParametroService,
  withService
} from 'itbi-common/service';
import { ObjectUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import CamposDocumentoSection from './CamposDocumentoSection';
import DadosDocumentoSection from './DadosDocumentoSection';
import VinculoTipoItbiSection from './VinculoTipoItbiSection';

const CenteredIcon = styled(Icon)`
  margin: 10% 45%;
`;

class DocumentosItbiFormPage extends React.Component {
  static propTypes = {
    documentoService: PropTypes.shape({
      getById: PropTypes.func.isRequired,
      save: PropTypes.func.isRequired
    }).isRequired,
    parametroService: PropTypes.shape({
      loadTipoCamposDocumentoItbi: PropTypes.func.isRequired,
      loadTiposItbi: PropTypes.func.isRequired
    }).isRequired,
    auth: PropTypes.shape({
      hasRole: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    loading: false,
    documento: {
      campos: [],
      tiposItbi: [],
      instrucao: ''
    },
    error: {
      tipoCampo: false,
      label: false,
      nome: false,
      tipoItbi: false
    },
    currentCampo: {},
    tipoCampos: [],
    currentTipo: {},
    tiposItbi: []
  };

  componentDidMount() {
    const { params } = this.props.match;

    if (params.id) {
      this.getDocumento(params.id);
    }

    this.getTipoCampos();
    this.getTipoItbi();
  }

  getDocumentoSuccess = response => {
    this.setState({ documento: response.data, isNew: false, loading: false });
  };

  getDocumentoError = error => {
    Alert.error(
      {
        title: 'Erro ao carregar o documento.'
      },
      error
    );
  };

  getDocumento = id => {
    this.setState({ loading: true }, () => {
      this.props.documentoService
        .getById(id)
        .then(this.getDocumentoSuccess)
        .catch(this.getDocumentoError);
    });
  };

  getTipoCampoSuccess = response => {
    this.setState({ tipoCampos: response.data });
  };

  getTipoCampoError = error => {};

  getTipoCampos = () => {
    this.props.parametroService
      .loadTipoCamposDocumentoItbi()
      .then(this.getTipoCampoSuccess)
      .catch(this.getTipoCampoError);
  };

  getTipoItbiSuccess = response => {
    this.setState({ tiposItbi: response.data });
  };

  getTipoItbiError = error => {};

  getTipoItbi = () => {
    this.props.parametroService
      .loadTiposItbi()
      .then(this.getTipoItbiSuccess)
      .catch(this.getTipoItbiError);
  };

  onChange = fieldName => event => {
    const { name, value, checked, type } = event.target;
    this.setState(state => {
      const newValue = type === 'checkbox' ? checked : value;
      const { [fieldName]: field, error } = state;
      const newError = error[name] ? !newValue : error[name];
      const newFieldValue =
        name === '' ? newValue : { ...field, [name]: newValue };
      return {
        [fieldName]: newFieldValue,
        error: { ...error, [name]: newError }
      };
    });
  };

  onChangeDocumento = this.onChange('documento');

  onChangeCampo = this.onChange('currentCampo');

  onChangeTipoItbi = this.onChange('currentTipo');

  onAddCampo = () => {
    const { currentCampo, tipoCampos } = this.state;

    const campo = {
      label: currentCampo.label,
      tipoCampo: tipoCampos.find(item => item.name === currentCampo.tipoCampo)
    };

    const newError = {};
    if (!campo.tipoCampo) {
      newError.tipoCampo = true;
    }
    if (!campo.label) {
      newError.label = true;
    }
    if (!ObjectUtils.isEmptyObject(newError)) {
      return this.setState(prevState => ({
        error: { ...prevState.error, ...newError }
      }));
    }

    this.setState(prevState => {
      return {
        documento: {
          ...prevState.documento,
          campos: [...prevState.documento.campos, campo]
        },
        currentCampo: { label: '', tipoCampo: '' }
      };
    });
  };

  onRemoveCampo = removeIndex => {
    this.setState(state => {
      const camposWithoutRemoved = state.documento.campos.filter(
        (campo, index) => index !== removeIndex
      );
      return {
        documento: {
          ...state.documento,
          campos: camposWithoutRemoved
        }
      };
    });
  };

  onAddTipoItbi = () => {
    const newError = {};

    const { currentTipo, tiposItbi } = this.state;

    const tipoItbi = tiposItbi.find(item => item.id === currentTipo.tipoItbi);

    if (!tipoItbi) {
      newError.tipoItbi = true;
    }
    if (!ObjectUtils.isEmptyObject(newError)) {
      return this.setState(prevState => ({
        error: { ...prevState.error, ...newError }
      }));
    }
    this.setState(prevState => {
      return {
        documento: {
          ...prevState.documento,
          tiposItbi: [...prevState.documento.tiposItbi, tipoItbi]
        },
        error: { ...prevState.error, tipoItbi: false },
        currentTipo: {}
      };
    });
  };

  onRemoveTipoItbi = removeIndex => {
    this.setState(state => {
      const tiposWithoutRemoved = state.documento.tiposItbi.filter(
        (campo, index) => index !== removeIndex
      );
      return {
        documento: {
          ...state.documento,
          tiposItbi: tiposWithoutRemoved
        }
      };
    });
  };

  onSave = () => {
    const { documento, error } = this.state;
    const newError = {};

    if (!documento.nome) {
      newError.nome = true;
    }

    if (!ObjectUtils.isEmptyObject(newError)) {
      return this.setState({ error: { ...error, ...newError } });
    }

    const parsedTipos = documento.campos.map(campo => {
      return { ...campo, tipoCampo: campo.tipoCampo.name };
    });
    this.setState({ loading: true });
    this.props.documentoService
      .save({ ...documento, campos: parsedTipos })
      .then(() => {
        this.props.history.goBack();
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      documento,
      currentCampo,
      tipoCampos,
      tiposItbi,
      currentTipo,
      loading,
      error
    } = this.state;

    const { auth } = this.props;

    if (loading) {
      return <CenteredIcon size="4x" icon="spinner" spin primary />;
    }
    return (
      <Container title="Documentos ITBI" icon={'file-alt'} iconSize={'lg'}>
        <DadosDocumentoSection
          onChange={this.onChangeDocumento}
          documento={documento}
          error={error}
        />
        <CamposDocumentoSection
          tipoCampos={tipoCampos}
          currentCampo={currentCampo}
          selectedCampos={documento.campos}
          onRemove={this.onRemoveCampo}
          onAdd={this.onAddCampo}
          onChange={this.onChangeCampo}
          hasRole={auth.hasRole}
          error={error}
        />

        <VinculoTipoItbiSection
          tiposItbi={tiposItbi}
          currentTipo={currentTipo}
          selectedTipos={documento.tiposItbi}
          tipos={tiposItbi}
          onRemove={this.onRemoveTipoItbi}
          onChange={this.onChangeTipoItbi}
          onAdd={this.onAddTipoItbi}
          hasRole={auth.hasRole}
          error={error}
        />
        <div className="btn-save">
          <FAB
            icon="check"
            iconColor="white"
            title="Salvar"
            onClick={this.onSave}
          />
        </div>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  documentoService: DocumentoItbiService,
  parametroService: ParametroService
})(DocumentosItbiFormPage);

const ComponentWithAuth = withAuth(ComponentWithService);

export { ComponentWithAuth as default, DocumentosItbiFormPage };
