const fieldNames = {
  cpfCnpj: 'CPF/CNPJ',
  dataLancamento: 'Data de Lançamento',
  descricao: 'Descrição',
  requerente: 'Requerente',
  valorUnitario: 'Valor Unitário',
  valorTotal: 'Valor Total',
  numero: 'Número',
  situacaoLaudo: 'Situação',
  laudos: 'Laudos',
  prioritario: 'Prioritário',
  idDebito: 'Código do débito',
  urlBoleto: 'Boleto',
  motivoPrioritario: 'Motivo da Priorização',
  nome: 'Nome',
  pago: 'Pago',
  usuario: 'Usuário',
  liberado: 'Liberado',
  qtdImoveis: 'Quantidade de Imóveis',
  responsavel: 'Responsável',
  procurador: 'Procurador',
  propriedades: 'Imóveis',
  propriedades_cadastro: 'Cadastro',
  propriedades_endereco: 'Endereço',
  propriedades_numero: 'Número',
  propriedades_cidade: 'Cidade',
  propriedades_estado: 'Estado',
  propriedades_cep: 'CEP',
  propriedades_valorEstimado: 'Valor Estimado',
  propriedades_valorAvaliado: 'Valor Avaliado',
  propriedades_proprietario: 'Proprietário',
  propriedades_zona: 'Zona',
  propriedades_quadra: 'Quadra',
  propriedades_lote: 'Lote',
  propriedades_areaTerreno: 'Área do terreno',
  propriedades_areaConstruida: 'Área Construida'
};
const ignoreFields = [
  'situacaoLaudo_nome',
  'situacaoLaudo_mostrarDashboard',
  'id',
  'propriedades_id',
  'requerente_id',
  'responsavel_id'
];

const nestedFields = ['responsavel', 'requerente', 'situacaoLaudo'];

export { fieldNames, ignoreFields, nestedFields };
