import imgModuleNewTheme from '@elotech/arc/src/assets/img/modules/oxy-arrecadacao.svg';
import { Sidebar, useAuth } from '@elotech/components';
import imgModule from '@elotech/components/assets/img/modules/arrecadacao-portalcontribuinte.png';
import imgModuleMain from '@elotech/components/assets/img/modules/arrecadacao-small.png';
import React from 'react';

import { Roles } from '../roles/Roles';

const AppSidebar = ({ showSideBar }) => {
  const { hasRole } = useAuth();

  const hasRoleCadastros =
    hasRole(Roles.documentos_itbi_visualizar.name) ||
    hasRole(Roles.tipo_itbi_visualizar.name) ||
    hasRole(Roles.termo_responsabilidade_visualizar.name);
  return showSideBar ? (
    <Sidebar
      title="ITBI Servidor"
      imgModuleMain={imgModuleMain}
      imgModule={imgModule}
      imgModuleNewTheme={imgModuleNewTheme}
    >
      <Sidebar.Item title="Início" to="/" icon="fa fa-home" />
      <Sidebar.Item
        title="Declarações ITBI"
        to="/declaracoes-itbi"
        icon="fa fa-paste"
        visible={hasRole(Roles.itbi_visualizar.name)}
      />
      <Sidebar.Item
        title="Laudos ITBI"
        to="/laudos"
        icon="fa fa-paperclip"
        visible={hasRole(Roles.laudo_visualizar.name)}
      />

      {hasRoleCadastros && (
        <Sidebar.Group icon="fa fa-plus" title="Cadastros">
          <Sidebar.Item
            title="Documentos ITBI"
            to="/documentos"
            icon="fa fa-file-alt"
            visible={hasRole(Roles.documentos_itbi_visualizar.name)}
          />
          <Sidebar.Item
            title="Tipos de ITBI"
            icon="fa fa-building"
            to="/tipos-itbi"
            visible={hasRole(Roles.tipo_itbi_visualizar.name)}
          />
          <Sidebar.Item
            title="Parâmetros"
            icon="fa fa-list-alt"
            to="/parametro"
          />
          <Sidebar.Item
            title="Parâmetros de Parcelamento"
            icon="fa fa-file-invoice-dollar"
            to="/parametro-parcelamento"
          />
          <Sidebar.Item
            title="Termo de Responsabilidade"
            to="/termo-responsabilidade"
            icon="fa fa-gavel"
            visible={hasRole(Roles.termo_responsabilidade_visualizar.name)}
          />
        </Sidebar.Group>
      )}
      <Sidebar.Item
        title="Sincronização de dados"
        to="/sincronizacao-dados"
        icon="fa fa-sync"
        visible={hasRole(Roles.acessar_sincronizacao_dados.name)}
      />
    </Sidebar>
  ) : null;
};

export default AppSidebar;
