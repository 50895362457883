import { ActionButton, Col, FormattedDecimal, Row } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

const FlexRow = styled.div`
  flex-direction: row;
`;

class RowSegmentoSection extends React.Component {
  static propTypes = {
    segmento: PropTypes.object.isRequired,
    saveSegmento: PropTypes.func.isRequired,
    deleteSegmento: PropTypes.func.isRequired,
    canEdit: PropTypes.bool.isRequired,
    canDelete: PropTypes.bool.isRequired
  };

  state = {
    showInput: false,
    inputValue: 0,
    error: undefined
  };

  componentDidMount() {
    this.setState({ inputValue: this.props.segmento.areaConstruidaAvaliacao });
  }

  onToggleInput = () => {
    this.setState(state => ({ showInput: !state.showInput }));
  };

  onSave = () => {
    const { inputValue } = this.state;

    if (!!inputValue) {
      this.props.saveSegmento(this.props.segmento.id, inputValue);
      this.setState({ showInput: false });
    } else {
      this.setState({
        error: 'Área é obrigatória'
      });
    }
  };

  onChangeInput = ({ floatValue }) => {
    this.setState({ inputValue: floatValue });
  };

  renderInput = (segmento, canEdit) => {
    const { showInput, inputValue, error } = this.state;

    if (showInput) {
      return (
        <FlexRow>
          <NumberFormat
            data-test-id="inputAreaConstruidaAvaliacao"
            autoFocus
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            value={inputValue}
            onValueChange={this.onChangeInput}
            onBlur={this.onSave}
          />
          {error && <span className="help-message">{error}</span>}
        </FlexRow>
      );
    }

    return (
      <React.Fragment>
        <FormattedDecimal value={segmento.areaConstruidaAvaliacao} />
        {canEdit && (
          <div
            data-test-id="buttonEditTable"
            className="hint clean inline module-color sm fa fa-pencil-alt"
            onClick={this.onToggleInput}
          >
            <div className="hint-content"> Editar </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  onDelete = () => {
    const {
      deleteSegmento,
      segmento: { id }
    } = this.props;

    deleteSegmento(id);
  };

  render() {
    const { segmento, canEdit, canDelete } = this.props;

    return (
      <Row key={segmento.id}>
        <Col md={2} className="column" data-header="Sequência">
          {segmento.sequencia}
        </Col>
        <Col md={3} className="column" data-header="Tipo">
          {segmento.tipoSegmento.descricao}
        </Col>
        <Col md={3} className="column" data-header="Área construída">
          <FormattedDecimal value={segmento.areaConstruida} />
        </Col>
        <Col md={3} className="column editable" data-header="Área ITBI">
          {this.renderInput(segmento, canEdit)}
        </Col>
        <Col md={1} className="column">
          {canDelete && (
            <div className="right">
              <ActionButton
                data-test-id="buttonDelete"
                onClick={this.onDelete}
                label={'Excluir'}
                icon={'trash-alt'}
              />
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default RowSegmentoSection;
