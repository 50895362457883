import 'itbi-common/polyfills';

import './IconsLibrary';

import {
  ActuatorService,
  ApplicationProvider,
  BreadCrumbProvider,
  Footer,
  KeycloakService,
  Loading,
  NotificationContainer,
  Onboarding,
  SidebarToggle,
  UrlUtils,
  getCurrentTheme
} from '@elotech/components';
import axios from 'axios';
import classnames from 'classnames';
import { EntidadeService, UserService } from 'itbi-common/service';
import { AxiosUtils } from 'itbi-common/utils';
import PromiseFinallyPolyfill from 'promise.prototype.finally';
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import AppKeycloakProvider from './AppKeycloakProvider';
import keycloakConfig from './keycloak';
import AppHeader from './menus/AppHeader';
import AppSidebar from './menus/AppSidebar';
import Routes, { allRoutes } from './routes';
import createStore from './state';
import ThemeSelector from './theme/ThemeSelector';

if (!global.Intl) {
  global.Intl = require('intl');
}

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/pt');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/pt');
}

const store = createStore({});
KeycloakService.init(keycloakConfig);

axios.interceptors.request.use(async config => {
  const { ...newConfig } = config;

  if (
    newConfig.url.startsWith('/api') ||
    newConfig.url.startsWith('/gateway') ||
    newConfig.url.startsWith('/admin') ||
    newConfig.url.startsWith('/painel-api')
  ) {
    const refreshed = await KeycloakService.refreshToken({});
    if (refreshed) {
      newConfig.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;
    }
  }

  if (newConfig.url.startsWith('/gateway')) {
    newConfig.headers.cidade = UrlUtils.getTenantIdentifier();
  }
  AxiosUtils.configureBaseUrl(newConfig);

  return Promise.resolve(newConfig);
});

PromiseFinallyPolyfill.shim();

class App extends Component {
  render() {
    return (
      <ThemeSelector>
        <Provider store={store}>
          <ThemeProvider theme={getCurrentTheme()}>
            <IntlProvider locale="pt" textComponent="span">
              <NotificationContainer>
                <AppKeycloakProvider keycloakService={KeycloakService}>
                  <ApplicationProvider
                    gatewayResource="/gateway"
                    onGetEntidadePrincipal={EntidadeService.getDadosEntidade}
                    getUserPermission={UserService.getUserPermissions}
                    onGetVersaoSobreSistema={() =>
                      ActuatorService.getInfo('/api')
                    }
                  >
                    <BreadCrumbProvider routes={allRoutes}>
                      <Onboarding>
                        <SidebarToggle>
                          {({ toggledSidebar, userExists, fullScreen }) => {
                            return (
                              <BrowserRouter basename={process.env.PUBLIC_URL}>
                                <div className="wrapper">
                                  <AppHeader showMenu={true} />
                                  <AppSidebar showSideBar={true} />
                                  <section
                                    className={classnames({
                                      'main-content': true,
                                      'sidebar-toggle': toggledSidebar,
                                      'no-sidebar': false,
                                      'sidebar-toggle-md': true,
                                      fullScreen: fullScreen
                                    })}
                                  >
                                    {userExists === undefined ? (
                                      <Loading loading={true} />
                                    ) : (
                                      <div>
                                        <Routes />
                                      </div>
                                    )}
                                  </section>
                                  <Footer />
                                </div>
                              </BrowserRouter>
                            );
                          }}
                        </SidebarToggle>
                      </Onboarding>
                    </BreadCrumbProvider>
                  </ApplicationProvider>
                </AppKeycloakProvider>
              </NotificationContainer>
            </IntlProvider>
          </ThemeProvider>
        </Provider>
      </ThemeSelector>
    );
  }
}

export default App;
