import { DisplayValue, FormattedCurrency } from '@elotech/components';
import { TIPO_IMOVEL_RURAL } from 'itbi-common/type';
import { LaudoUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';

const LoteLaudoTransferenciaItem = ({ propriedade }) => {
  return (
    <div className="display-data border small mb-xs">
      <div className="row">
        <DisplayValue
          size={1}
          label={'Cadastro'}
          value={propriedade.cadastro}
        />
        <DisplayValue
          size={6}
          label={'Endereço'}
          value={LaudoUtils.buildEnderecoCadastro(propriedade)}
        />
        <DisplayValue
          size={2}
          label={'Valor Estimado'}
          value={<FormattedCurrency value={propriedade.valorEstimado} />}
        />
        <DisplayValue
          size={2}
          label={'Valor Avaliado'}
          value={<FormattedCurrency value={propriedade.valorAvaliado} />}
        />
      </div>
      {propriedade.tipoImovel === TIPO_IMOVEL_RURAL ? (
        <>
          <div className="row">
            <DisplayValue
              size={6}
              label={'Nome'}
              value={propriedade.cadastroRural?.nome}
            />
            <DisplayValue
              size={6}
              label={'Endereço Descrito'}
              value={propriedade.cadastroRural?.enderecoDescrito}
            />
          </div>
          <div className="row">
            <DisplayValue
              size={4}
              label={'Logradouro'}
              value={propriedade.cadastroRural?.logradouro}
            />
            <DisplayValue
              size={2}
              label={'Bairro'}
              value={propriedade.cadastroRural?.bairro}
            />
            <DisplayValue
              size={2}
              label={'Complemento'}
              value={propriedade.cadastroRural?.complemento}
            />
            <DisplayValue
              size={2}
              label={'Distrito'}
              value={propriedade.cadastroRural?.distrito}
            />
            <DisplayValue
              size={2}
              label={'Setor'}
              value={propriedade.cadastroRural?.setor}
            />
          </div>
          <div className="row">
            <DisplayValue
              size={2}
              label={'NIRF'}
              value={propriedade.cadastroRural?.numeroReceita}
            />
            <DisplayValue
              size={2}
              label={'Número no INCRA'}
              value={propriedade.cadastroRural?.numeroIncra}
            />
            <DisplayValue
              size={2}
              label={'Qtd. Alqueires'}
              value={propriedade.cadastroRural?.alqueires}
            />
            <DisplayValue
              size={2}
              label={'Qtd. Hectares'}
              value={propriedade.cadastroRural?.hectares}
            />
            <DisplayValue
              size={4}
              label={'Área Total'}
              value={
                propriedade.cadastroRural?.areaTotal
                  ? `${propriedade.cadastroRural.areaTotal} m²`
                  : ''
              }
            />
          </div>
        </>
      ) : (
        <div className="row">
          <DisplayValue
            size={5}
            label={'Proprietário'}
            value={propriedade.cadastroImobiliario?.proprietario}
          />
          <DisplayValue
            size={1}
            label={'Zona'}
            value={propriedade.cadastroImobiliario?.zona}
          />
          <DisplayValue
            size={1}
            label={'Quadra'}
            value={propriedade.cadastroImobiliario?.quadra}
          />
          <DisplayValue
            size={1}
            label={'Data/Lote'}
            value={propriedade.cadastroImobiliario?.lote}
          />
          <DisplayValue
            size={2}
            label={'Área Terreno'}
            value={
              propriedade.cadastroImobiliario?.areaTerreno
                ? `${propriedade.cadastroImobiliario.areaTerreno} m²`
                : ''
            }
          />
          <DisplayValue
            size={2}
            label={'Área Construída'}
            value={
              propriedade.cadastroImobiliario?.areaConstruida
                ? `${propriedade.cadastroImobiliario.areaConstruida} m²`
                : ''
            }
          />
        </div>
      )}
    </div>
  );
};

LoteLaudoTransferenciaItem.propTypes = {
  propriedade: PropTypes.object.isRequired
};

export default LoteLaudoTransferenciaItem;
