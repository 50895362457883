import { SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import LoteLaudoTransferenciaItem from './LoteLaudoTransferenciaItem';

class LoteLaudoTransferenciaItens extends Component {
  static propTypes = {
    propriedades: PropTypes.array.isRequired
  };
  state = {
    expanded: false
  };

  renderPropriedade = propriedade => (
    <LoteLaudoTransferenciaItem
      propriedade={propriedade}
      key={propriedade.id}
    />
  );

  toggleExpanded = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { expanded } = this.state;
    const { propriedades } = this.props;
    return (
      <div>
        <SectionTitle hasButton>
          Propriedades
          <button
            data-test-id="buttonNovo"
            className="btn module-color"
            onClick={this.toggleExpanded}
          >
            {`${expanded ? 'Esconder' : 'Mostrar'} propriedades`}
          </button>
        </SectionTitle>
        {expanded ? propriedades.map(this.renderPropriedade) : undefined}
      </div>
    );
  }
}

export default LoteLaudoTransferenciaItens;
