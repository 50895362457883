import { ActionsGroup } from '@elotech/components';
import { PedidoActionButton } from 'itbi-common/components';
import React from 'react';

import { Roles } from '../../roles/Roles';

const renderViewButton = (laudo, { onViewLaudo }, options, _, props) =>
  !options.resumo ? (
    <PedidoActionButton
      key="see-button"
      icon="eye"
      title="Ver pedido de laudo"
      onClick={() => onViewLaudo(laudo)}
      {...props}
    />
  ) : null;

const renderEditButton = (
  laudo,
  { onEditLaudo },
  { currentUser },
  { hasRole },
  props
) => {
  if (
    hasRole(Roles.laudo_editar_pedido_terceiros.name) ||
    (laudo.responsavel &&
      currentUser &&
      laudo.responsavel.id === currentUser.id)
  ) {
    return (
      <PedidoActionButton
        key="edit-button"
        icon="pencil-alt"
        title="Editar Laudo"
        onClick={() => onEditLaudo(laudo)}
        {...props}
      />
    );
  }

  return null;
};

const renderFavoriteButton = (
  laudo,
  { onFavoriteLaudo },
  _,
  { hasRole },
  props
) =>
  hasRole(Roles.acesso_prioritario.name) ? (
    <PedidoActionButton
      key="favorite-button"
      icon={[laudo.prioritario ? 'fas' : 'far', 'star']}
      title="Prioritário"
      onClick={() => onFavoriteLaudo(laudo)}
      {...props}
    />
  ) : null;

const renderIndeferirButton = (
  laudo,
  { onIndeferirLaudo },
  _,
  { hasRole },
  props
) =>
  (hasRole(Roles.acesso_prioritario.name) &&
    laudo.situacaoLaudo.nome === 'ANALISANDO') ||
  (laudo.situacaoLaudo.nome === 'LIBERADO' && laudo?.pago === false) ? (
    <PedidoActionButton
      key="favorite-button"
      icon="times"
      title="Indeferir"
      onClick={() => onIndeferirLaudo(laudo)}
      {...props}
    />
  ) : null;

const renderViewFavoriteButton = (
  laudo,
  { onViewFavoriteLaudo },
  _,
  { hasRole },
  props
) => {
  if (hasRole(Roles.acesso_prioritario.name) && laudo.prioritario) {
    return (
      <PedidoActionButton
        key="view-favorite-button"
        icon={[laudo.prioritario ? 'fas' : 'far', 'question']}
        title="Motivo Prioritário"
        onClick={() => onViewFavoriteLaudo(laudo)}
        {...props}
      />
    );
  } else {
    return null;
  }
};

const renderTransferirButton = (
  laudo,
  { onTransferir },
  { currentUser },
  { hasRole },
  props
) => {
  if (
    hasRole(Roles.transferir_responsavel.name) ||
    (laudo.responsavel &&
      currentUser &&
      laudo.responsavel.id === currentUser.id)
  ) {
    return (
      <PedidoActionButton
        key="transfer-button"
        icon="user-edit"
        title="Transferir Responsável"
        onClick={() => onTransferir(laudo)}
        {...props}
      />
    );
  }
  return null;
};

const renderApagarGuiasPagamentoButton = (
  laudo,
  { onApagarGuiasPagamento },
  _,
  { hasRole },
  props
) => {
  if (hasRole(Roles.apagar_guias_pagamento_declaracoes_laudos.name)) {
    if (props.fab && laudo.urlBoletoServidor && !laudo.pago) {
      return (
        <PedidoActionButton
          key="apagar-guia-button"
          icon="trash-alt"
          title="Apagar Guias de Pagamento"
          onClick={() => onApagarGuiasPagamento(laudo)}
          {...props}
        />
      );
    }
  }
  return null;
};

const renderEstornoButton = (laudo, { onEstornar }, _, { hasRole }, props) => {
  if (hasRole(Roles.laudo_estornar.name) && !laudo.pago) {
    return (
      <PedidoActionButton
        key="estorno-button"
        icon="undo"
        title="Estornar"
        onClick={() => onEstornar(laudo)}
        {...props}
      />
    );
  }
  return null;
};

const renderPrintBoletoButton = (
  laudo,
  { onPrintBoleto },
  _,
  { hasRole },
  props
) => {
  if (
    hasRole(Roles.itbi_imprimir_boleto.name) &&
    !laudo.pago &&
    !laudo.isento
  ) {
    return (
      <PedidoActionButton
        key="pay-button"
        icon="barcode"
        title="Imprimir Boleto"
        onClick={() => onPrintBoleto(laudo)}
        {...props}
      />
    );
  }

  return null;
};

const renderAnexarDocumentoButton = (
  laudo,
  { onViewAnexos },
  options,
  _,
  props
) => {
  return (
    <PedidoActionButton
      key="anexos-button"
      icon="paperclip"
      title="Anexos"
      onClick={() => onViewAnexos(laudo)}
      {...props}
    />
  );
};

const actionButtons = {
  AGUARDANDO_ANALISE: [
    renderPrintBoletoButton,
    renderViewButton,
    renderFavoriteButton,
    renderViewFavoriteButton,
    renderTransferirButton,
    renderAnexarDocumentoButton,
    renderApagarGuiasPagamentoButton
  ],
  ANALISANDO: [
    renderPrintBoletoButton,
    renderViewButton,
    renderEditButton,
    renderTransferirButton,
    renderIndeferirButton,
    renderAnexarDocumentoButton,
    renderApagarGuiasPagamentoButton
  ],
  LIBERADO: [
    renderPrintBoletoButton,
    renderViewButton,
    renderIndeferirButton,
    renderEstornoButton,
    renderAnexarDocumentoButton,
    renderApagarGuiasPagamentoButton
  ],
  INDEFERIDO: [
    renderPrintBoletoButton,
    renderViewButton,
    renderAnexarDocumentoButton,
    renderApagarGuiasPagamentoButton
  ]
};

export const renderActionButtonsLaudo = (
  actions,
  options = {},
  auth
) => laudo => {
  return (
    <ActionsGroup>
      {(actionButtons[laudo.situacaoLaudo.nome] || []).map(renderFn =>
        renderFn(laudo, actions, options, auth, { fab: false })
      )}
    </ActionsGroup>
  );
};

export const renderFabButtons = (
  laudo,
  actions,
  options = { resumo: false },
  auth
) => {
  return (actionButtons[laudo.situacaoLaudo.nome] || [])
    .map(renderFunction =>
      renderFunction(laudo, actions, options, auth, { fab: true })
    )
    .filter(item => !!item);
};
