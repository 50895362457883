import { ErrorText } from '@elotech/components';
import React, { ChangeEventHandler } from 'react';

import { ParametroItbi } from '../../types';

type Props = {
  options: ParametroItbi[];
  currentValue?: ParametroItbi;
  onSelect: ChangeEventHandler;
  error: { parametroItbi: boolean };
  errorMessages: { parametroItbi: string };
};

const ParametroItbiSelect: React.FC<Props> = ({
  error,
  errorMessages,
  onSelect,
  currentValue,
  options
}) => {
  return (
    <div className="form-group">
      <label htmlFor="parametroItbi">Parâmetro de ITBI</label>
      <select
        id="parametroItbi"
        name="parametroItbi"
        className={error.parametroItbi ? 'error' : ''}
        onChange={onSelect}
        value={currentValue ? currentValue.id : undefined}
      >
        <option value="">Selecione</option>
        {options.map(item => (
          <option key={item.id} value={item.id}>
            {`${item.id} - ${item.descricao}`}
          </option>
        ))}
      </select>
      {error.parametroItbi && (
        <ErrorText>{errorMessages.parametroItbi}</ErrorText>
      )}
    </div>
  );
};

export default ParametroItbiSelect;
