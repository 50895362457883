const getFields = userService => {
  return [
    {
      label: 'Número do Pedido',
      name: 'numero',
      type: 'NUMBER'
    },
    {
      label: 'Número do Cadastro Imobiliário',
      name: 'laudos.cadastroImobiliario.cadastro',
      type: 'NUMBER'
    },
    {
      label: 'Número do Cadastro Rural',
      name: 'laudos.cadastroRural.cadastro',
      type: 'NUMBER'
    },
    {
      label: 'Data Lançamento',
      name: 'dataLancamento',
      type: 'DATE'
    },
    {
      label: 'Situação',
      name: 'situacao',
      type: 'ENUM',
      options: [
        {
          name: 'AGUARDANDO_ANALISE',
          descricao: 'Aguardando Análise'
        },
        {
          name: 'ANALISANDO',
          descricao: 'Em Análise'
        },
        {
          name: 'LIBERADO',
          descricao: 'Liberado'
        },
        {
          name: 'INDEFERIDO',
          descricao: 'Indeferido'
        }
      ]
    },
    {
      label: 'Pago',
      name: 'pago',
      type: 'BOOLEAN'
    },
    {
      label: 'Requerente',
      name: 'requerente',
      type: 'AUTOCOMPLETE',
      loadOptions: searchParameter =>
        userService.searchUsuarioByNome(searchParameter),
      getOptionValue: option => option.id,
      getOptionLabel: option => option.nome
    },
    {
      label: 'Responsável',
      name: 'responsavel',
      type: 'AUTOCOMPLETE',
      loadOptions: searchParameter =>
        userService.searchUsuarioOnSameEntidade(searchParameter),
      getOptionValue: option => option.id,
      getOptionLabel: option => option.nome
    },
    {
      label: 'Origem',
      name: 'origemServidor',
      type: 'ENUM',
      options: [
        {
          name: 'true',
          descricao: 'Prefeitura'
        },
        {
          name: 'false',
          descricao: 'On-line'
        }
      ]
    }
  ];
};

export { getFields };
