import { DiffRow } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const names = {
  descricao: 'Descrição',
  normal: 'Normal',
  anuencia: 'Anuência',
  financiado: 'Financiado',
  visivel: 'Visível',
  codigoItbiAise: 'Código Aise',
  parametroItbi_id: 'Parâmetro de ITBI',
  observacao: 'Observação',
  permiteTotalmenteFinanciado: 'Totalmente Financiado'
};
const ignoreFields = ['id'];
const dictionary = path => {
  if (ignoreFields.includes(path[0])) {
    return undefined;
  }

  return names[path.join('_')];
};

const TiposItbiRowDiff = props => {
  const { diff } = props;
  return <DiffRow diff={diff} nameDictionary={dictionary} />;
};

TiposItbiRowDiff.propTypes = {
  diff: PropTypes.object.isRequired
};

export default TiposItbiRowDiff;
