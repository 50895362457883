import { ActionButton, ActionsGroup } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

import { Roles } from '../../roles/Roles';

const TipoItbiActionsButtons = ({
  tipoItbi,
  hasRole,
  actions: { onEdit, onDelete, onViewAlteracoes }
}) => {
  return (
    <ActionsGroup>
      {hasRole(Roles.cadastro_tipos_itbi_editar.name) && (
        <ActionButton
          data-test-id="button-edit"
          key="edit-button"
          icon="pencil-alt"
          label="Editar"
          onClick={() => onEdit(tipoItbi)}
        />
      )}
      {hasRole(Roles.cadastro_tipos_itbi_remover.name) && (
        <ActionButton
          data-test-id="button-delete"
          key="delete-button"
          icon="trash-alt"
          label="Excluir"
          onClick={() => onDelete(tipoItbi)}
        />
      )}
      <ActionButton
        data-test-id="button-history"
        key="view-button"
        icon="history"
        label="Visualizar alterações"
        onClick={() => onViewAlteracoes(tipoItbi)}
      />
    </ActionsGroup>
  );
};

TipoItbiActionsButtons.propTypes = {
  actions: PropTypes.shape({
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onViewAlteracoes: PropTypes.func.isRequired
  }).isRequired,
  hasRole: PropTypes.func.isRequired,
  tipoItbi: PropTypes.object.isRequired
};

export default TipoItbiActionsButtons;
