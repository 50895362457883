import { Alert, Container, EmptyRow, Loading } from '@elotech/components';
import { DeepDiff } from 'deep-diff';
import { TipoItbiService, withService } from 'itbi-common/service';
import PropTypes from 'prop-types';
import React from 'react';

import TiposItbiRowRevisions from './TiposItbiRowRevisions';

export class TiposItbiPageAlteracoes extends React.Component {
  static propTypes = {
    tipoItbiService: PropTypes.object.isRequired
  };

  state = {
    itbiAlteracoes: [],
    loadingItbiAlteracoes: false
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getItbiAlteracoes(id);
  }

  getItbiAlteracoesSucess = response => {
    const parsed = response.data.map((element, index, arr) => {
      if (response.data.length === index + 1) {
        return {
          revision: element.revision,
          revisionType: element.revisionType,
          diff: DeepDiff.diff({}, element.entity)
        };
      } else {
        return {
          revision: element.revision,
          revisionType: element.revisionType,
          diff: DeepDiff.diff(arr[index + 1].entity, element.entity)
        };
      }
    });
    this.setState({ itbiAlteracoes: parsed, loadingItbiAlteracoes: false });
  };

  getItbiAlteracoesError = error => {
    this.setState({ loadingItbiAlteracoes: false });
    Alert.error(
      { title: 'Não foi possível carregar o histórico do tipo ITBI' },
      error
    );
  };

  getItbiAlteracoes = id => {
    this.setState({ loadingItbiAlteracoes: true });
    this.props.tipoItbiService
      .getHistoricoAlteracoes(id)
      .then(this.getItbiAlteracoesSucess)
      .catch(this.getItbiAlteracoesError);
  };

  renderBody = itbiAlteracoes => {
    return itbiAlteracoes.length > 0 ? (
      itbiAlteracoes.map(itbiAlteracoes => {
        return itbiAlteracoes.diff ? (
          <TiposItbiRowRevisions
            key={itbiAlteracoes.revision.revisionNumber}
            itbiAlteracoes={itbiAlteracoes}
          />
        ) : null;
      })
    ) : (
      <EmptyRow colSpan={4}>Sem Alterações</EmptyRow>
    );
  };
  render() {
    const { itbiAlteracoes, loadingItbiAlteracoes } = this.state;
    return (
      <Container title="Alterações nos Tipos de ITBI" icon="file-alt">
        <Loading loading={loadingItbiAlteracoes} />
        <div className="panel table table-responsive">
          <div className="panel-body">
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Responsável</th>
                    <th>Tipo de Alteração</th>
                    <th />
                  </tr>
                </thead>
                <tbody>{this.renderBody(itbiAlteracoes)}</tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default withService({
  tipoItbiService: TipoItbiService
})(TiposItbiPageAlteracoes);
