import {
  ActionButton,
  ActionsGroup,
  ProtectedComponent,
  SearchFilter,
  SearchPagination,
  Table
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

import { Roles } from '../../roles';

const ActionsButtons = ({
  documento,
  actions: { onView, onEdit, onDelete, onViewAlteracoes }
}) => {
  return (
    <ActionsGroup>
      <ActionButton
        key="view-button"
        icon="eye"
        label="Visualizar"
        onClick={() => onView(documento)}
      />
      <ProtectedComponent role={Roles.cadastro_documento_itbi_editar.name}>
        <ActionButton
          key="edit-button"
          icon="pencil-alt"
          label="Editar"
          onClick={() => onEdit(documento)}
        />
      </ProtectedComponent>
      <ProtectedComponent role={Roles.cadastro_documento_itbi_remover.name}>
        <ActionButton
          key="remove-button"
          icon="trash-alt"
          label="Excluir"
          onClick={() => onDelete(documento)}
        />
      </ProtectedComponent>
      <ActionButton
        key="history-button"
        icon="history"
        label="Visualizar alterações"
        onClick={() => onViewAlteracoes(documento)}
      />
    </ActionsGroup>
  );
};

const searchFields = [
  {
    label: 'Nome do Documento',
    name: 'nome',
    type: 'STRING'
  }
];

const DocumentosItbiList = props => {
  const {
    documentos,
    pagination,
    onSearch,
    onEdit,
    onDelete,
    onView,
    onViewAlteracoes
  } = props;

  const paginationSearch = page => onSearch(undefined, page);

  const actions = {
    onEdit,
    onDelete,
    onView,
    onViewAlteracoes
  };

  return (
    <div className="panel table table-responsive">
      <div className="panel-body">
        <SearchFilter fields={searchFields} search={onSearch} />

        <Table values={documentos}>
          <Table.Column header="Nome do Documento" value={item => item.nome} />
          <Table.Column
            header="Obrigatório"
            value={item => (item.obrigatorio ? 'Sim' : 'Não')}
          />
          <Table.Column
            header="Exige procuração requerente"
            value={item => (item.procuracaoRequerente ? 'Sim' : 'Não')}
          />
          <Table.Column
            header="Tipos Associados"
            value={item => (item.tiposItbi || []).length}
          />
          <Table.Column
            header="Campos Associados"
            value={item => (item.campos || []).length}
          />
          <Table.Column
            header=""
            value={item => (
              <ActionsButtons documento={item} actions={actions} />
            )}
          />
        </Table>
        {pagination && (
          <SearchPagination
            page={pagination}
            searchWithPage={paginationSearch}
          />
        )}
      </div>
    </div>
  );
};

DocumentosItbiList.propTypes = {
  documentos: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  onSearch: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired
};

export default DocumentosItbiList;
