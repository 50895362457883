import {
  ActionButton,
  ActionsGroup,
  Col,
  DisplayData,
  FormattedCurrency,
  FormattedDecimal,
  Row,
  SectionTitle,
  formatUtils
} from '@elotech/components';
import {
  DocumentacaoList,
  DocumentosAvulsosList
} from 'itbi-common/components';
import { FunctionUtils } from 'itbi-common/utils';
import React from 'react';
import { FormattedDate, injectIntl } from 'react-intl';

const HintComponent = ({ segmentos, valorTerritorialCalculado = 0, intl }) => {
  const valorConstrucaoSegmentos = segmentos
    .map(segmento => segmento.valorConstrucao)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const valorCalculado = valorConstrucaoSegmentos + valorTerritorialCalculado;

  return (
    <div className="hint inline clean module-color fa-exclamation-circle mobile md">
      <div className="hint-content">
        <span style={{ color: 'white' }}>
          Valor Calculado:
          {intl.formatNumber(valorCalculado, {
            style: 'currency',
            currency: 'BRL'
          })}
        </span>
      </div>
    </div>
  );
};

const Hint = injectIntl(HintComponent);

const DadosTransferencia = ({
  dadosTransferencia,
  onViewPessoa,
  hasContestacao
}) => {
  const hasFinanciamento = dadosTransferencia.tipoItbi.financiado;
  const hasAnuencia = dadosTransferencia.tipoItbi.anuencia;

  const total = {
    totalValue:
      dadosTransferencia.valorTransacao + dadosTransferencia.valorFinanciado,
    informedValue:
      dadosTransferencia.valorTransacaoAvaliacao +
      dadosTransferencia.valorFinanciadoAvaliacao,
    evaluatedValue:
      dadosTransferencia.valorTransacaoAvaliacao +
      dadosTransferencia.valorFinanciadoAvaliacao,
    itbiValue:
      dadosTransferencia.valorTransacaoItbi +
      dadosTransferencia.valorFinanciadoItbi
  };

  const title = !hasContestacao ? 'Dados Transferência' : 'Dados Declaração';

  return (
    <div>
      <SectionTitle>{title}</SectionTitle>
      <div className="display-data border small mt-xs">
        <Row>
          <ColumnResumo
            col={4}
            title="Data"
            value={
              <FormattedDate value={dadosTransferencia.data} timeZone={'UTC'} />
            }
          />
          <ColumnResumo
            col={4}
            title="Número do Pedido"
            value={dadosTransferencia.numero}
          />
          <ColumnResumo
            col={4}
            title="Situação"
            value={dadosTransferencia.situacaoItbi.descricao}
          />
        </Row>
        <div className="display-data border small mt-xs has-btn-actions">
          <Row>
            <ColumnResumo
              col={8}
              title="Requerente"
              value={
                dadosTransferencia.requerenteNome ||
                (dadosTransferencia.requerente &&
                  dadosTransferencia.requerente.nome)
              }
            />
            {dadosTransferencia.requerente &&
              dadosTransferencia.requerente.procurador && (
                <ColumnResumo
                  col={4}
                  title="Tipo de Procurador"
                  value={FunctionUtils.getLabelTipoProcurador(
                    dadosTransferencia.requerente.tipoProcurador
                  )}
                />
              )}
            <ActionsGroup>
              <ActionButton
                data-test-id="buttonView"
                key="view-button"
                icon="eye"
                label="Visualizar"
                onClick={() => onViewPessoa(dadosTransferencia.requerente)}
              />
            </ActionsGroup>
          </Row>
        </div>
        <Row>
          <ColumnResumo
            col={5}
            title="Responsável"
            value={
              dadosTransferencia.responsavel &&
              dadosTransferencia.responsavel.nome
            }
          />
          <ColumnResumo
            col={4}
            title="Tipo ITBI"
            value={dadosTransferencia.tipoItbi.descricao}
          />
          <ColumnResumo
            col={3}
            title="Transferência"
            value={dadosTransferencia.automatica ? 'Automática' : 'Manual'}
          />
        </Row>
        <Row>
          {!hasContestacao && (
            <>
              <ColumnResumo
                col={2}
                rightAlign
                title="Valor Transação"
                value={
                  <FormattedCurrency
                    value={dadosTransferencia.valorTransacao}
                  />
                }
              />

              <Col sm={3} style={rightAlignStyle}>
                <b className="dd-title">
                  Valor Avaliação
                  <Hint
                    segmentos={dadosTransferencia.segmentos || []}
                    valorTerritorialCalculado={
                      dadosTransferencia.valorTerritorialCalculado
                    }
                  />
                </b>
                <div className="dd-content">
                  <FormattedCurrency
                    value={dadosTransferencia.valorTransacaoAvaliacao}
                  />
                </div>
              </Col>
              <ColumnResumo
                col={1}
                rightAlign
                title="Alíquota"
                value={
                  <FormattedDecimal
                    value={dadosTransferencia.aliquotaTransacao / 100}
                    maximumSignificantDigits={3}
                    type="percent"
                  />
                }
              />
              <ColumnResumo
                col={2}
                rightAlign
                title="Valor ITBI"
                value={
                  <FormattedCurrency
                    value={dadosTransferencia.valorTransacaoItbi}
                  />
                }
              />
              {dadosTransferencia.valorIsencao !== null &&
                dadosTransferencia.valorIsencao !== undefined && (
                  <>
                    <ColumnResumo
                      col={2}
                      rightAlign
                      title="Valor Isenção"
                      value={
                        <FormattedCurrency
                          value={dadosTransferencia.valorIsencao}
                        />
                      }
                    />
                    <ColumnResumo
                      col={2}
                      rightAlign
                      title="Valor Devido"
                      value={
                        <FormattedCurrency
                          value={
                            dadosTransferencia.valorTransacaoItbi -
                            dadosTransferencia.valorIsencao
                          }
                        />
                      }
                    />
                  </>
                )}
            </>
          )}
        </Row>
        {!hasContestacao && (hasFinanciamento || hasAnuencia) && (
          <React.Fragment>
            <Row>
              <ColumnResumo
                col={3}
                rightAlign
                title={hasFinanciamento ? 'Valor Financiado' : 'Valor Anuência'}
                value={
                  <FormattedCurrency
                    value={dadosTransferencia.valorFinanciado}
                  />
                }
              />
              <ColumnResumo
                col={4}
                rightAlign
                title={
                  hasFinanciamento
                    ? 'Valor Financiado Avaliação'
                    : 'Valor Anuência Avaliação'
                }
                value={
                  <FormattedCurrency
                    value={dadosTransferencia.valorFinanciadoAvaliacao}
                  />
                }
              />
              <ColumnResumo
                col={2}
                rightAlign
                title="Alíquota"
                value={
                  <FormattedDecimal
                    value={dadosTransferencia.aliquotaFinanciado / 100}
                    maximumSignificantDigits={3}
                    type="percent"
                  />
                }
              />
              <ColumnResumo
                col={3}
                rightAlign
                title={
                  hasFinanciamento
                    ? 'Valor ITBI Financiado'
                    : 'Valor ITBI Anuência'
                }
                value={
                  <FormattedCurrency
                    value={dadosTransferencia.valorFinanciadoItbi}
                  />
                }
              />
            </Row>
            <Row>
              <Col sm={3} />
              <ColumnResumo
                col={4}
                rightAlign
                title="Total Avaliado"
                value={<FormattedCurrency value={total.informedValue} />}
              />
              <Col sm={2} />
              <ColumnResumo
                col={3}
                rightAlign
                title="Total ITBI"
                value={<FormattedCurrency value={total.itbiValue} />}
              />
            </Row>
          </React.Fragment>
        )}
        <Row>
          <ColumnResumo
            col={12}
            title="Observação do Requerente"
            value={dadosTransferencia.observacaoCidadao}
          />
        </Row>
        <Row>
          <ColumnResumo
            col={12}
            title="Observação sobre o Requerimento"
            value={dadosTransferencia.observacaoServidor}
          />
        </Row>
        {dadosTransferencia.motivoIndeferimento && (
          <Row>
            <ColumnResumo
              col={12}
              title="Motivo do indeferimento"
              value={dadosTransferencia.motivoIndeferimento}
            />
          </Row>
        )}
        {dadosTransferencia.tipoItbi.descricao === 'ISENTO' && (
          <Row>
            <ColumnResumo
              col={12}
              title="Observação da Isenção"
              value={dadosTransferencia.observacaoIsencao}
            />
          </Row>
        )}
      </div>
    </div>
  );
};

const DadosImovel = ({ cadastroImobiliario }) => (
  <div>
    <SectionTitle>Dados do Imóvel</SectionTitle>
    <div className="display-data border small mt-xs">
      <Row>
        <ColumnResumo
          col={3}
          title="Cadastro Imobiliário"
          value={cadastroImobiliario.cadastro}
        />
        <ColumnResumo
          col={4}
          title="Inscrição Cadastral"
          value={cadastroImobiliario.inscricaoCadastral}
        />
        <ColumnResumo
          col={2}
          title="Quadra"
          value={cadastroImobiliario.quadra}
        />
        <ColumnResumo
          col={2}
          title="Data/Lote"
          value={cadastroImobiliario.lote}
        />
      </Row>
      <Row>
        <ColumnResumo
          title="Complemento do Lote"
          value={cadastroImobiliario.complementoLote}
        />
      </Row>
      <Row>
        <ColumnResumo
          col={3}
          title="Área Terreno"
          value={`${cadastroImobiliario.areaTerreno || 0} m²`}
        />
        <ColumnResumo
          col={3}
          sm={6}
          title="Área Construida"
          value={`${cadastroImobiliario.areaConstruida || '0'} m²`}
        />
      </Row>
      <Row>
        <ColumnResumo
          col={2}
          title="CEP"
          value={formatUtils.formatCep(cadastroImobiliario.cep)}
        />
        <ColumnResumo
          col={6}
          title="Endereço"
          value={cadastroImobiliario.endereco}
        />
        <ColumnResumo col={2} title="Nº" value={cadastroImobiliario.numero} />
        <ColumnResumo
          col={4}
          title="Complemento"
          value={cadastroImobiliario.complemento}
        />
      </Row>
      <Row>
        <ColumnResumo
          col={5}
          title="Bairro"
          value={cadastroImobiliario.bairro}
        />
        <ColumnResumo
          col={5}
          title="Cidade"
          value={cadastroImobiliario.cidade}
        />
        <ColumnResumo
          col={2}
          title="Estado"
          value={cadastroImobiliario.estado}
        />
      </Row>
      <Row>
        <ColumnResumo
          col={12}
          title="Condomínio"
          value={
            cadastroImobiliario.condominio
              ? `${cadastroImobiliario.condominio} - ${cadastroImobiliario.descricaoCondominio}`
              : ''
          }
        />
      </Row>
    </div>
  </div>
);

const DadosCadastroRural = ({ cadastro }) => (
  <div>
    <SectionTitle>Dados do Cadastro Rural</SectionTitle>
    <div className="display-data border small mt-xs">
      <Row>
        <Col sm={3}>
          <DisplayData title="Cadastro Rural" data-cy="cadastroRural">
            {cadastro.cadastro}
          </DisplayData>
        </Col>
        <Col sm={4}>
          <DisplayData title="Nome">{cadastro.nome}</DisplayData>
        </Col>
        <Col sm={5}>
          <DisplayData title="Endereço Descrito">
            {cadastro.enderecoDescrito}
          </DisplayData>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <DisplayData title="Logradouro">{cadastro.logradouro}</DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title="Bairro">{cadastro.bairro}</DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title="Complemento">{cadastro.complemento}</DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title="Distrito">{cadastro.distrito}</DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title="Setor">{cadastro.setor}</DisplayData>
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <DisplayData title={'NIRF'}>{cadastro.numeroReceita}</DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title={'Número no Incra'}>
            {cadastro.numeroIncra}
          </DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title={'Qtd. Alqueires'}>
            {cadastro.alqueires}
          </DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title={'Qtd. Hectares'}>{cadastro.hectares}</DisplayData>
        </Col>
        <Col sm={2}>
          <DisplayData title={'Área Total'}>{cadastro.areaTotal}</DisplayData>
        </Col>
      </Row>
    </div>
  </div>
);

const PessoaRow = ({ pessoa, vendedor, proprietario, onViewPessoa }) => {
  return (
    <Row key={pessoa.id}>
      <Col sm={3}>
        <b className="dd-title">Vínculo</b>
        <div className="dd-content">
          {pessoa.vinculo ? pessoa.vinculo.descricao : ''}
        </div>
      </Col>
      <Col sm={5}>
        <b className="dd-title">Nome</b>
        <div className="dd-content">{`${formatUtils.formatCpfCnpj(
          pessoa.cpfCnpj
        )} - ${pessoa.nome}`}</div>
      </Col>
      <Col sm={2}>
        <b className="dd-title">Percentual</b>
        <div className="dd-content">{pessoa.percentual}%</div>
      </Col>
      {vendedor && (
        <Col sm={2}>
          <b className="dd-title">Perc. Venda</b>
          <div className="dd-content">{pessoa.percentualVenda}%</div>
        </Col>
      )}
      {proprietario && (
        <Col sm={2}>
          {pessoa.principal && (
            <React.Fragment>
              <label htmlFor={pessoa.id} style={{ cursor: 'default' }}>
                Principal
              </label>
            </React.Fragment>
          )}
        </Col>
      )}
      <ActionsGroup>
        <ActionButton
          data-test-id="buttonView"
          key="view-button"
          icon="eye"
          label="Visualizar"
          onClick={() => onViewPessoa(pessoa)}
        />
      </ActionsGroup>
    </Row>
  );
};

const PessoaList = ({ title, list, proprietario, onViewPessoa }) => (
  <div>
    <SectionTitle>{title}</SectionTitle>
    <div className="display-data border small mt-xs has-btn-actions">
      {list.map(pessoa => (
        <PessoaRow
          key={pessoa.id}
          vendedor={!!pessoa.percentualVenda}
          pessoa={pessoa}
          proprietario={proprietario}
          onViewPessoa={onViewPessoa}
        />
      ))}
    </div>
  </div>
);

const SegmentoRow = ({ segmento }) => {
  return (
    <Row key={segmento.id}>
      <Col sm={4}>
        <b className="dd-title">Tipo</b>
        <div className="dd-content">
          {segmento.tipoSegmento ? segmento.tipoSegmento.descricao : ''}
        </div>
      </Col>
      <Col sm={4}>
        <b className="dd-title">Área Construída</b>
        <div className="dd-content right">
          <FormattedDecimal value={segmento.areaConstruida} /> m²
        </div>
      </Col>
      <Col sm={4}>
        <b className="dd-title">Área Construída Avaliação</b>
        <div className="dd-content right">
          <FormattedDecimal value={segmento.areaConstruidaAvaliacao} /> m²
        </div>
      </Col>
    </Row>
  );
};

const SegmentoList = ({ segmentos }) => (
  <div>
    <SectionTitle>Construções</SectionTitle>
    <div className="display-data border small mt-xs">
      {segmentos.map(segmento => (
        <SegmentoRow key={segmento.id} segmento={segmento} />
      ))}
    </div>
  </div>
);

const rightAlignStyle = {
  justifyContent: 'space-between',
  alignItems: 'center'
};
const ColumnResumo = ({ col, title, value, rightAlign = false }) => (
  <Col sm={col} style={rightAlign ? rightAlignStyle : { alignItems: 'center' }}>
    <b className="dd-title">{title}</b>

    <div className="dd-content">{value}</div>
  </Col>
);

const ResumoDeclaracao = ({
  declaracao,
  onViewPessoa,
  onDownloadDocumentoAvulso
}) => (
  <React.Fragment>
    <DadosTransferencia
      dadosTransferencia={declaracao}
      onViewPessoa={onViewPessoa}
    />
    {declaracao.tipoImovel === 'URBANO' ? (
      <DadosImovel cadastroImobiliario={declaracao.cadastroImobiliario} />
    ) : (
      <DadosCadastroRural cadastro={declaracao.cadastroRural} />
    )}
    {declaracao.segmentos && declaracao.segmentos.length > 0 && (
      <SegmentoList segmentos={declaracao.segmentos} />
    )}
    {declaracao.compradores.length > 0 && (
      <PessoaList
        title="Compradores"
        list={declaracao.compradores}
        onViewPessoa={onViewPessoa}
      />
    )}
    {declaracao.vendedores.length > 0 && (
      <PessoaList
        title="Vendedores"
        list={declaracao.vendedores}
        onViewPessoa={onViewPessoa}
      />
    )}
    {declaracao.anuentes.length > 0 && (
      <PessoaList
        title="Anuentes/Cedentes"
        list={declaracao.anuentes}
        onViewPessoa={onViewPessoa}
      />
    )}
    {declaracao.proprietarios.length > 0 && (
      <PessoaList
        title="Proprietários"
        list={declaracao.proprietarios}
        proprietario
        onViewPessoa={onViewPessoa}
      />
    )}
    <DocumentacaoList
      idDeclaracao={declaracao.id}
      documentos={declaracao.documentos}
    />
    {declaracao.documentosAvulsos.length > 0 && (
      <DocumentosAvulsosList
        onDownload={onDownloadDocumentoAvulso}
        documentos={declaracao.documentosAvulsos}
        showVisibleColumn
      />
    )}
  </React.Fragment>
);

export {
  ResumoDeclaracao as default,
  DadosTransferencia,
  DadosImovel,
  DadosCadastroRural,
  PessoaList,
  SegmentoList,
  SegmentoRow,
  PessoaRow,
  ColumnResumo,
  HintComponent
};
