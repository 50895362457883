import { FastField, Field, FieldProps, getIn } from 'formik';
import React from 'react';

import EditableInput, {
  EditableInputProps
} from '../../editableInput/EditableInput';
import { hasErrors } from '../validate';

export type FormikEditableInputProps<T> = Partial<EditableInputProps<T>> & {
  fast?: boolean;
  render?: (props: FieldProps) => any;
};

const FormikEditableInput = <T extends {}>({
  fast = true,
  ...props
}: FormikEditableInputProps<T>) => {
  const FormikField = fast ? FastField : Field;
  return (
    <FormikField
      {...props}
      render={({ field, form }: FieldProps) => {
        const error = hasErrors(field.name, form);

        return (
          <>
            {(props.render && props.render({ field, form })) || (
              <EditableInput<T>
                {...field}
                maxLength={props.maxLength}
                initialValue={field.value}
                onConfirmChange={(value: T, name: string, fullPath: string) => {
                  form.setFieldValue(fullPath, value);
                  form.setFieldTouched(fullPath);
                  props.onConfirmChange &&
                    props.onConfirmChange(value, name, fullPath);
                }}
                defaultOpened={props.defaultOpened}
                readOnly={props.readOnly}
              />
            )}
            {error && (
              <p className="help-message">{getIn(form.errors, field.name)}</p>
            )}
          </>
        );
      }}
    />
  );
};

export default FormikEditableInput;
