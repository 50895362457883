import {
  Alert,
  Checkbox,
  Col,
  Container,
  DisplayData,
  FAB,
  FormattedDate,
  Loading,
  NotificationActions,
  ProtectedComponent,
  Row
} from '@elotech/components';
import { AvaliacaoQuickView } from 'itbi-common/components';
import {
  AvaliacaoService,
  LaudoService,
  withService
} from 'itbi-common/service';
import { TIPO_IMOVEL_RURAL } from 'itbi-common/type';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

import { Roles } from '../../../roles';
import LoteLaudoUtils from '../../../utils/LoteLaudoUtils';
import LaudoAnaliseForm from './LaudoAnaliseForm';

class LoteLaudoAnalisePage extends React.Component {
  static propTypes = {
    showNotification: PropTypes.func.isRequired,
    laudoService: PropTypes.shape({
      getLaudoParaAnalise: PropTypes.func.isRequired,
      finalizarAnalise: PropTypes.func.isRequired,
      updateLaudo: PropTypes.func.isRequired,
      indeferir: PropTypes.func.isRequired
    }).isRequired,
    avaliacaoService: PropTypes.shape({
      getUltimasAvaliacoes: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    loteLaudo: undefined,
    filtros: {},
    submittingLaudo: false,
    showQuickViewAvaliacoes: false
  };

  componentDidMount() {
    const {
      laudoService,
      match: { params }
    } = this.props;

    this.setState({ submittingLaudo: true });
    laudoService
      .getLaudoParaAnalise(params.id)
      .then(response =>
        this.setState({
          loteLaudo: response.data,
          laudosFiltrados: response.data.laudos
        })
      )
      .finally(() => this.setState({ submittingLaudo: false }));
  }

  onChangeFiltro = event => {
    const { name, checked, value, type } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    this.setState(state => ({
      filtros: {
        ...state.filtros,
        [name]: newValue
      }
    }));
  };

  filtrar = () => {
    const {
      filtros: { cadastro, naoAvaliados },
      loteLaudo
    } = this.state;
    let list = loteLaudo.laudos;
    if (cadastro) {
      list = list.filter(laudo => {
        const cadastroLaudo =
          laudo?.tipoImovel === TIPO_IMOVEL_RURAL
            ? laudo.cadastroRural
            : laudo.cadastroImobiliario;
        return String(cadastroLaudo?.cadastro).startsWith(cadastro);
      });
    }
    if (naoAvaliados) {
      list = list.filter(laudo => !laudo.valorAvaliado);
    }
    this.setState({ laudosFiltrados: list });
  };

  isValid = () => {
    return this.state.loteLaudo.laudos.every(laudo => !!laudo.valorAvaliado);
  };

  onFinishAnalise = () => {
    const { loteLaudo } = this.state;

    Alert.question({
      title: 'Finalizou a análise?',
      text: 'Escolha entre as opções para finalizar a análise.',
      confirmButtonText: 'Deferir',
      cancelButtonText: 'Indeferir',
      allowOutsideClick: true
    }).then(result => {
      if (result.value) {
        this.onDeferir(loteLaudo);
      } else if (result.dismiss === swal.DismissReason.cancel) {
        this.onIndeferir(loteLaudo);
      }
    });
  };

  onIndeferir = loteLaudo => {
    const { showNotification, laudoService, history } = this.props;

    LoteLaudoUtils.onIndeferirLaudo(
      laudoService,
      loteLaudo.id,
      showNotification,
      state => this.setState(state),
      undefined
    ).then(() => {
      history.goBack();
    });
  };

  onDeferir = async () => {
    const { history, showNotification, laudoService } = this.props;
    const { loteLaudo } = this.state;

    if (this.isValid()) {
      this.setState({ submittingLaudo: true });
      await laudoService
        .finalizarAnalise(loteLaudo.id)
        .then(() => {
          showNotification({
            level: 'success',
            title: 'Sucesso',
            message: 'Análise do laudo finalizada.'
          });
          history.replace(`/laudos/${loteLaudo.id}/resumo`);
        })
        .catch(error => {
          this.setState({ submittingLaudo: false });
          Alert.error(
            { title: 'Não foi possível finalizar o laudo de avaliação.' },
            error
          );
        });
    } else {
      showNotification({
        level: 'error',
        title: 'Erro',
        message: 'Há laudos sem valores avaliados.'
      });
    }
  };

  onSave = laudo => {
    return this.props.laudoService
      .updateLaudo(laudo)
      .then(response =>
        this.setState({ loteLaudo: response.data }, () => this.filtrar())
      );
  };

  renderLaudo = laudo => (
    <LaudoAnaliseForm
      data-test-id={laudo.id}
      key={laudo.id}
      laudo={laudo}
      update={this.onSave}
      showQuickViewAvaliacoes={this.onShowAvaliacoes}
    />
  );

  onShowAvaliacoes = (idCadastro, tipoImovel) => {
    const { avaliacaoService, showNotification } = this.props;

    this.setState({ submittingLaudo: true });
    avaliacaoService
      .getUltimasAvaliacoes(idCadastro, tipoImovel)
      .then(response => {
        if (response.data) {
          if (response.data.length === 0) {
            showNotification({
              level: 'info',
              message:
                'Não foi encontrado nenhuma avaliação para este cadastro.'
            });
          } else {
            this.setState({
              ultimasAvaliacoes: response.data,
              showAvaliacoes: true
            });
          }
        }
        this.setState({ submittingLaudo: false });
      })
      .catch(() => {
        showNotification({
          level: 'error',
          title: 'Erro',
          message: 'Ocorreu um erro ao buscar as avaliações.'
        });
        this.setState({ submittingLaudo: false });
      });
  };

  onCloseAvaliacoes = () => {
    this.setState({ showAvaliacoes: false });
  };

  render() {
    const {
      loteLaudo,
      filtros,
      laudosFiltrados,
      submittingLaudo,
      showAvaliacoes,
      ultimasAvaliacoes
    } = this.state;

    if (!loteLaudo) {
      return <Loading loading={submittingLaudo} />;
    }

    return (
      <Container title="Análise Laudo de Avaliação" icon="paperclip">
        <Loading loading={submittingLaudo} />
        {showAvaliacoes && (
          <AvaliacaoQuickView
            onClose={this.onCloseAvaliacoes}
            ultimasAvaliacoes={ultimasAvaliacoes}
          />
        )}
        <div className="display-data border small mb-xs">
          <Row>
            <Col sm={3}>
              <DisplayData title={'Data Lançamento'}>
                <FormattedDate value={loteLaudo.data} timeZone={'UTC'} />
              </DisplayData>
            </Col>
            <Col sm={3}>
              <DisplayData title={'Número'}>{loteLaudo.numero}</DisplayData>
            </Col>
            <Col sm={6}>
              <DisplayData title={'Requerente'}>
                {loteLaudo.requerente?.nome ?? loteLaudo.requerenteNome}
              </DisplayData>
            </Col>
          </Row>
        </div>
        <div className="panel table table-responsive">
          <div className="panel-body">
            <div className="panel-table-filter">
              <div className="row">
                <div className="form-group col-sm-3">
                  <Checkbox
                    id={'naoAvaliados'}
                    data-test-id="naoAvaliados"
                    label={'Somente não avaliados'}
                    name={'naoAvaliados'}
                    checked={filtros.naoAvaliados}
                    onChange={this.onChangeFiltro}
                  />
                </div>
                <div className="form-group col-sm-9">
                  <input
                    name={'cadastro'}
                    value={filtros.cadastro}
                    onChange={this.onChangeFiltro}
                    type={'number'}
                    placeholder={'Filtrar por número do cadastro municipal'}
                  />
                  <button
                    data-test-id="buttonFiltrar"
                    className="btn filter"
                    type="button"
                    onClick={this.filtrar}
                  >
                    Filtrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="panel table table-responsive">
          <div className="panel-body">
            <div className="panel-table-outer">
              {laudosFiltrados && laudosFiltrados.map(this.renderLaudo)}
            </div>
          </div>
        </div>
        <ProtectedComponent role={Roles.laudo_finalizar_analise.name}>
          <div className="btn-save">
            <FAB
              data-test-id="buttonFinalizar"
              icon="check"
              iconColor="white"
              title={'Finalizar análise'}
              onClick={this.onFinishAnalise}
            />
          </div>
        </ProtectedComponent>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ComponentWithService = withService({
  laudoService: LaudoService,
  avaliacaoService: AvaliacaoService
})(LoteLaudoAnalisePage);

const ConnectedComponent = connect(
  null,
  mapDispatchToProps
)(ComponentWithService);

export { ConnectedComponent as default, LoteLaudoAnalisePage };
