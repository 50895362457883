import {
  Alert,
  Container,
  FAB,
  Loading,
  NotificationActions
} from '@elotech/components';
import { LaudoService, UserService, withService } from 'itbi-common/service';
import { FunctionUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { NewResponsavelSelector } from '../../components';
import { Roles } from '../../roles';
import LoteLaudoTransferenciaDetail from './transferencia/LoteLaudoTransferenciaDetail';
import LoteLaudoTransferenciaItens from './transferencia/LoteLaudoTransferenciaItens';

class LoteLaudoTransferenciaPage extends React.Component {
  static propTypes = {
    laudoService: PropTypes.shape({
      getLaudo: PropTypes.func.isRequired,
      transferirResponsavel: PropTypes.func.isRequired
    }).isRequired,
    userService: PropTypes.shape({
      searchUsuarioOnSameEntidade: PropTypes.func.isRequired
    }).isRequired,
    showNotification: PropTypes.func.isRequired
  };

  state = {
    loading: true,
    loteLaudo: undefined
  };

  componentDidMount() {
    const { params } = this.props.match;

    this.onGetLoteLaudo(params.id);
  }

  onGetLoteLaudoSuccess = response => {
    this.setState({
      loteLaudo: response.data,
      loading: false
    });
  };

  onGetLoteLaudo = id => {
    this.setState({ loading: true }, () => {
      this.props.laudoService
        .getLaudo(id)
        .then(this.onGetLoteLaudoSuccess)
        .catch(error => {
          Alert.error(
            {
              title: 'Erro ao carregar os lotes do laudo.'
            },
            error
          );
        });
    });
  };

  searchUser = searchParameter => {
    return this.props.userService.searchUsuarioOnSameEntidade(
      searchParameter,
      Roles.laudo_assumir_pedido.name
    );
  };

  onSelectUser = (_, newUser) => {
    this.setState({ currentUser: newUser });
  };

  onTransfer = () => {
    const { loteLaudo, currentUser } = this.state;
    const { showNotification, laudoService } = this.props;

    if (!currentUser) {
      return showNotification({
        level: 'warning',
        message: 'Selecione um usuário para realizar a transferência.'
      });
    }
    this.setState({ loading: true });
    return laudoService
      .transferirResponsavel(loteLaudo.id, currentUser)
      .then(() => {
        showNotification({
          level: 'success',
          message: 'Laudo transferido com sucesso.'
        });
        return this.props.history.goBack();
      })
      .catch(error => {
        if (error.response.data.status === 403) {
          return showNotification({
            level: 'warning',
            message: error.response.data.message
          });
        }
        return showNotification({
          level: 'warning',
          message:
            'Não foi possível alterar o responsável do laudo. Tente novamente.'
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, loteLaudo, currentUser } = this.state;
    if (loading) {
      return <Loading loading={true} />;
    }
    return (
      <Container
        title="Transferência de Responsável da Avaliação do Laudo"
        icon="file-signature"
      >
        <LoteLaudoTransferenciaDetail loteLaudo={loteLaudo} />

        <NewResponsavelSelector
          loadOptions={this.searchUser}
          onChange={this.onSelectUser}
          selectedValue={currentUser}
        />

        <LoteLaudoTransferenciaItens propriedades={loteLaudo.propriedades} />

        <div className="btn-save">
          <FAB
            icon="check"
            iconColor="white"
            title="Confirmar transferência"
            onClick={this.onTransfer}
          />
        </div>
      </Container>
    );
  }
}

const enhancers = FunctionUtils.compose(
  withService({
    laudoService: LaudoService,
    userService: UserService
  }),
  connect(null, {
    showNotification: NotificationActions.showNotification
  })
);

const EnhancedComponent = enhancers(LoteLaudoTransferenciaPage);

export { EnhancedComponent as default, LoteLaudoTransferenciaPage };
