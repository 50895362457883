import {
  DocumentosAvulsosForm,
  DocumentosAvulsosList
} from 'itbi-common/components';
import PropTypes from 'prop-types';
import React from 'react';

function DocumentosAvulsosSection(props) {
  const {
    documentos,
    onRemove,
    onAdd,
    showNotification,
    onDownload,
    isArbitragem,
    customSectionTitle
  } = props;
  return (
    <React.Fragment>
      <DocumentosAvulsosForm
        onAdd={onAdd}
        showNotification={showNotification}
        isArbitragem={isArbitragem}
        customSectionTitle={customSectionTitle}
      />
      <DocumentosAvulsosList
        onDownload={onDownload}
        documentos={documentos}
        onRemove={onRemove}
        showVisibleColumn
        isArbitragem={isArbitragem}
        customSectionTitle={customSectionTitle}
      />
    </React.Fragment>
  );
}

DocumentosAvulsosSection.propTypes = {
  documentos: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  isArbitragem: PropTypes.bool,
  customSectionTitle: PropTypes.string
};

export default DocumentosAvulsosSection;
