import {
  Container,
  EmptyRow,
  HistoryTable,
  Loading,
  NotificationActions
} from '@elotech/components';
import { DocumentoItbiService, withService } from 'itbi-common/service';
import { DiffUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import DocumentosItbiHistoryRow from './DocumentosItbiHistoryRow';

export class DocumentosItbiHistoryPage extends React.Component {
  static propTypes = {
    documentoItbiService: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired
  };

  state = {
    loadingDocumentosAlteracao: false,
    history: []
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getDocumentosAlteracoes(id);
  }

  getDocumentosItbiError = () => {
    this.setState({ loadingDocumentosAlteracao: false });
    this.props.showNotification({
      level: 'warning',
      message: 'Não foi possível pesquisar os documentos ITBI'
    });
  };

  getDocumentosAlteracoes = id => {
    this.setState({ loadingDocumentosAlteracao: true });
    this.props.documentoItbiService
      .getHistory(id)
      .then(response => this.parseDiff(response.data))
      .catch(this.getDocumentosItbiError);
  };

  parseDiff = history => {
    this.setState({
      history: DiffUtils.parseDiff(history, { campos: [], tiposItbi: [] }),
      loadingDocumentosAlteracao: false
    });
  };

  renderBody = history => {
    return history.length > 0 ? (
      history.map(revision => {
        return revision.diff ? (
          <DocumentosItbiHistoryRow
            key={revision.revision.revisionNumber}
            revision={revision}
          />
        ) : null;
      })
    ) : (
      <EmptyRow colSpan={4}>Sem Alterações</EmptyRow>
    );
  };

  render() {
    const { loadingDocumentosAlteracao, history } = this.state;
    return (
      <Container title="Alterações nos Documentos ITBI" icon="file-alt">
        <Loading loading={loadingDocumentosAlteracao} />
        <HistoryTable>{this.renderBody(history)}</HistoryTable>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  documentoItbiService: DocumentoItbiService
})(DocumentosItbiHistoryPage);

const connectComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(ComponentWithService);

export default connectComponent;
