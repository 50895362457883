export const TipoIntegracaoScheduler = {
  CADASTRO: 'SINCRONIZAR_CADASTRO',
  PAGAMENTOS_ITBI: 'PAGAMENTOS_ITBI',
  PAGAMENTOS_LAUDO: 'PAGAMENTOS_LAUDO',
  ENDERECO: 'SINCRONIZAR_ENDERECO',
  PEDIDOS: 'PEDIDOS'
};

export const StatusIntegracaoScheduler = {
  CANCELADO: 'Cancelado',
  EXECUTANDO: 'Executando',
  SUCESSO: 'Sucesso',
  ERRO: 'Erro',
  ATRASADO: 'Atrasado',
  PARADO: 'Parado'
};

export type IntegracaoScheduler = {
  id: string;
  tipo: string;
  status: string;
  dataUltimaIntegracao: string;
  dataFimUltimaIntegracao: string;
  dataUltimaIntegracaoComSucesso: string;
  motivoErro: string;
  totalRegistros: number;
  totalProcessados: number;
  origemExecucao: string;
  duracao: string;
};
