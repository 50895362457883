import { useEffect, useRef } from 'react';

export default function useInterval(
  callback: (val?: any) => void,
  delay: number
) {
  const savedCallback = useRef<(val?: any) => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args: any) => savedCallback.current!(...args);

    if (delay !== null) {
      const id = setInterval(handler, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
