import {
  Alert,
  Container,
  DateUtils,
  FAB,
  FormikInputDate,
  FormikTextArea,
  Loading,
  Row,
  Yup,
  useShowNotification
} from '@elotech/components';
import { getTodayDate } from '@elotech/components/src/utils/DateUtils';
import { Formik, FormikProps } from 'formik';
import { TermoResponsabilidadeService } from 'itbi-common/service';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { TermoResponsabilidade } from '../../types';

type Props = {};

const validationSchema = Yup.object().shape({
  dataInicioValidade: Yup.date()
    .label('Data de Início de Validade')
    .required('Data de Início de Validade é obrigatória')
    .test(
      'verifyDataInicioValidadeAnteriorAHoje',
      'A Data de Início não pode ser anterior à data de hoje',
      function(dataInicio: Date) {
        return dataInicio
          ? DateUtils.convertDateToTimeInMilliseconds(getTodayDate()) <=
              DateUtils.convertDateToTimeInMilliseconds(dataInicio)
          : true;
      }
    ),
  conteudo: Yup.string()
    .trim()
    .label('Conteúdo')
    .required()
});

const initialValues: TermoResponsabilidade = {
  id: '',
  conteudo: '',
  dataInicioValidade: ''
};

const TermoResponsabilidadeFormPage: React.FC<Props> = () => {
  const history = useHistory();
  const showNotification = useShowNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [termoResponsabilidade, setTermoResponsabilidade] = useState<
    TermoResponsabilidade
  >(initialValues);
  const { id } = useParams();

  useEffect(() => {
    if (id !== 'novo') {
      setLoading(true);
      TermoResponsabilidadeService.findOne(id!)
        .then(response => {
          setTermoResponsabilidade(response.data);
        })
        .catch(error => {
          Alert.error(
            { title: 'Erro ao buscar o termo de responsabilidade.' },
            error
          );
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  const onSave = (values: TermoResponsabilidade) => {
    setLoading(true);
    TermoResponsabilidadeService.save(values, id!)
      .then(() => {
        setLoading(false);
        showNotification({
          level: 'success',
          message: `Termo de responsabilidade salvo com sucesso`
        });

        history.push('/termo-responsabilidade');
      })
      .catch((error: any) => {
        setLoading(false);
        Alert.error(
          { title: `Não foi possível salvar o termo de responsabilidade.` },
          error
        );
      });
  };

  return (
    <Container title="Termo de Responsabilidade" icon="gavel">
      <Loading loading={loading} />
      <Formik
        enableReinitialize
        initialValues={termoResponsabilidade}
        onSubmit={onSave}
        validationSchema={() => validationSchema}
        render={(formProps: FormikProps<TermoResponsabilidade>) => (
          <>
            <Row>
              <FormikInputDate
                name="dataInicioValidade"
                label="Data de Início de Validade"
                size={2}
              />
            </Row>
            <Row>
              <FormikTextArea
                name="conteudo"
                label="Conteúdo"
                size={12}
                rows={12}
              />
            </Row>
            <div className="btn-save">
              <FAB
                icon="check"
                iconColor="white"
                title="Salvar"
                onClick={formProps.submitForm}
              />
            </div>
          </>
        )}
      />
    </Container>
  );
};

export default TermoResponsabilidadeFormPage;
