import {
  ActionButton,
  ActionsGroup,
  Col,
  FormattedDate,
  TableReport
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Roles } from '../../roles/Roles';

const CursorDefault = styled.div`
  && {
    cursor: default;
  }
`;
const renderRow = (pedido, onTransferir, infoRow, hasRole) => (
  <TableReport.Row
    key={pedido.id}
    nowrap
    warning={infoRow === 1}
    error={infoRow === 2}
  >
    <TableReport.Col md={4} header="Data">
      <FormattedDate value={pedido.dataLancamento} timeZone={'UTC'} />
    </TableReport.Col>
    <TableReport.Col md={5} header="Número do Pedido">
      {pedido.numero}
    </TableReport.Col>
    <TableReport.Col md={3} header="Tipo">
      {pedido.tipo}
    </TableReport.Col>
    {hasRole(Roles.itbi_transferir_manual.name) && (
      <ActionsGroup>
        <ActionButton
          customClass="circle"
          key="transfer-button"
          icon="exchange-alt"
          label="Transferir"
          onClick={() => onTransferir(pedido)}
        />
      </ActionsGroup>
    )}
  </TableReport.Row>
);

const PedidosTransferenciaTable = ({
  loading,
  error,
  values,
  total,
  onTransferir,
  onInfoRow,
  hasRole
}) => {
  return (
    <React.Fragment>
      <TableReport.Title
        icon="th-list"
        title="Pedidos"
        subtitle="Aguardando transferência"
      />
      <TableReport
        loading={loading}
        error={error}
        height="40vh"
        empty={values.length === 0}
      >
        <TableReport.Summary size={4}>
          <CursorDefault className="block-dash report">
            <div className="number">{total}</div>
            <div className="title">Pendentes</div>
          </CursorDefault>
        </TableReport.Summary>
        <TableReport.Header>
          <Col md={4}>Data</Col>
          <Col md={5}>Número do Pedido</Col>
          <Col md={3}>Tipo</Col>
        </TableReport.Header>
        <TableReport.Rows>
          {values.map(pedido =>
            renderRow(
              pedido,
              onTransferir,
              onInfoRow(pedido.diasCorridos),
              hasRole
            )
          )}
        </TableReport.Rows>
      </TableReport>
    </React.Fragment>
  );
};

PedidosTransferenciaTable.propTypes = {
  values: PropTypes.array,
  loading: PropTypes.bool,
  onTransferir: PropTypes.func,
  onInfoRow: PropTypes.func.isRequired,
  hasRole: PropTypes.func.isRequired
};

PedidosTransferenciaTable.defaultProps = {
  loading: false,
  values: [],
  onTransferir: () => {}
};

export default PedidosTransferenciaTable;
