import {
  ActionsGroup,
  Col,
  FormattedDate,
  TableReport,
  useAuth
} from '@elotech/components';
import { PedidoActionButton } from 'itbi-common/components';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { renderActionButtons } from '../declaracao-itbi/DeclacacaoActions';

const CursorDefault = styled.div`
  && {
    cursor: default;
  }
`;

const renderViewButton = (pedido, { onView }) => {
  return (
    <PedidoActionButton
      key="see-button"
      icon="eye"
      title="Ver pedido"
      onClick={() => onView(pedido)}
    />
  );
};

const renderEditButton = (pedido, { onEdit }) => {
  return (
    <PedidoActionButton
      key="edit-button"
      icon="pencil-alt"
      title="Analisar pedido"
      onClick={() => onEdit(pedido)}
    />
  );
};

const renderTransferirResponsavelButton = (
  pedido,
  { onTransferirResponsavel }
) => (
  <PedidoActionButton
    key="transfer-button"
    icon="user-edit"
    title="Transferir Responsável"
    onClick={() => onTransferirResponsavel(pedido)}
  />
);

const renderIndeferirButton = (pedido, { onIndeferirLaudo }) => (
  <PedidoActionButton
    key="favorite-button"
    icon="times"
    title="Indeferir"
    onClick={() => onIndeferirLaudo(pedido)}
  />
);

const actionButtonsLaudo = {
  ANALISANDO: [
    renderViewButton,
    renderEditButton,
    renderTransferirResponsavelButton,
    renderIndeferirButton
  ]
};

const renderButtons = (pedido, actions, additionalOptions, hasRole) => {
  if (pedido.tipo === 'ITBI') {
    return renderActionButtons(pedido, actions, additionalOptions, { hasRole });
  } else if (pedido.tipo === 'LAUDO') {
    return (
      <ActionsGroup>
        {(actionButtonsLaudo[pedido.situacao] || []).map(renderFn =>
          renderFn(pedido, actions, additionalOptions, { hasRole })
        )}
      </ActionsGroup>
    );
  } else {
    console.warn(`Tipo de Pedido "${pedido.tipo}" desconhecido.`);
    return null;
  }
};

const renderRow = (pedido, actions, infoRow, additionalOptions, hasRole) => (
  <TableReport.Row
    key={pedido.id}
    nowrap
    warning={infoRow === 1}
    error={infoRow === 2}
  >
    <TableReport.Col md={2} header="Data Lançamento">
      <FormattedDate value={pedido.dataLancamento} timeZone={'UTC'} />
    </TableReport.Col>
    <TableReport.Col md={2} header="Número">
      {pedido.numero}
    </TableReport.Col>
    <TableReport.Col md={1} header="Tipo">
      {pedido.tipo}
    </TableReport.Col>
    <TableReport.Col md={4} header="Requerente">
      {pedido.requerente.nome}
    </TableReport.Col>
    <TableReport.Col md={3} header="Status">
      {pedido.situacaoDescricao}
    </TableReport.Col>
    {renderButtons(pedido, actions, additionalOptions, hasRole)}
  </TableReport.Row>
);

const MeusPedidosTable = ({
  loading,
  error,
  values,
  total,
  onView,
  onNotify,
  onReverse,
  onGenerateDebito,
  onGenerateBoleto,
  onEdit,
  onPrintBoleto,
  onTransferirResponsavel,
  onInfoRow,
  currentUser,
  onIndeferirLaudo,
  onAnalizeContestacao
}) => {
  const { hasRole } = useAuth();
  const actions = {
    onView,
    onNotify,
    onReverse,
    onGenerateDebito,
    onGenerateBoleto,
    onEdit,
    onPrintBoleto,
    onTransferirResponsavel,
    onInfoRow,
    onIndeferirLaudo,
    onAnalizeContestacao
  };

  const additionalOptions = {
    currentUser
  };

  return (
    <div className="list-dash">
      <TableReport.Title icon="th-list" title="Meus pedidos" />
      <TableReport
        loading={loading}
        height="35vh"
        empty={values.length === 0}
        error={error}
      >
        <TableReport.Summary>
          <CursorDefault className="block-dash report">
            <div className="number">{total}</div>
            <div className="title">Em análise</div>
          </CursorDefault>
        </TableReport.Summary>
        <TableReport.Header>
          <Col md={2}>Data Lançamento</Col>
          <Col md={2}>Número do Pedido</Col>
          <Col md={1}>Tipo</Col>
          <Col md={4}>Requerente</Col>
          <Col md={3}>Status</Col>
        </TableReport.Header>
        <TableReport.Rows>
          {values.map(pedido =>
            renderRow(
              pedido,
              actions,
              onInfoRow(pedido.diasCorridos),
              additionalOptions,
              hasRole
            )
          )}
        </TableReport.Rows>
      </TableReport>
    </div>
  );
};

MeusPedidosTable.propTypes = {
  values: PropTypes.array,
  loading: PropTypes.bool,
  onView: PropTypes.func.isRequired,
  onNotify: PropTypes.func.isRequired,
  onReverse: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onTransferirResponsavel: PropTypes.func.isRequired,
  onGenerateDebito: PropTypes.func.isRequired,
  onGenerateBoleto: PropTypes.func.isRequired,
  onInfoRow: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  onIndeferirLaudo: PropTypes.func.isRequired,
  onAnalizeContestacao: PropTypes.func.isRequired
};

MeusPedidosTable.renderButtons = renderButtons;

MeusPedidosTable.defaultProps = {
  loading: false,
  values: []
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser
});

const ConnectedComponent = connect(mapStateToProps, null)(MeusPedidosTable);

export { ConnectedComponent as default, MeusPedidosTable };
