import { Alert, Panel } from '@elotech/components';
import { Col, Icon, Row, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

import { Roles } from '../../../roles';
import RowSegmentoSection from './RowSegmentoSection';

class SegmentoSection extends React.Component {
  static propTypes = {
    segmentos: PropTypes.array.isRequired,
    errors: PropTypes.object,
    errorMessages: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    saveSegmento: PropTypes.func,
    deleteSegmento: PropTypes.func,
    restaurarSegmentos: PropTypes.func,
    hasRole: PropTypes.func.isRequired,
    hasContestacao: PropTypes.bool
  };

  renderSegmentos = (segmento, canEdit, canDelete, hasContestacao) => {
    const { saveSegmento, deleteSegmento } = this.props;
    return (
      <RowSegmentoSection
        key={segmento.id}
        segmento={segmento}
        saveSegmento={saveSegmento}
        deleteSegmento={deleteSegmento}
        canEdit={!hasContestacao && canEdit}
        canDelete={!hasContestacao && canDelete}
      />
    );
  };

  onRestaurar = () => {
    Alert.question({
      title: 'Deseja realmente restaurar as construções?'
    }).then(result => {
      if (result.value) {
        this.props.restaurarSegmentos();
      }
    });
  };

  canEditConstrucao = hasRole => {
    return hasRole(Roles.itbi_editar_construcao.name);
  };

  canDeleteConstrucao = hasRole => {
    return hasRole(Roles.itbi_remover_construcao.name);
  };

  render() {
    const { hasRole, segmentos, hasContestacao } = this.props;
    const canEdit = this.canEditConstrucao(hasRole);
    const canDelete = this.canDeleteConstrucao(hasRole);
    return (
      <div>
        <SectionTitle hasButton>
          Construções
          {!hasContestacao && hasRole(Roles.itbi_restaurar_construcao.name) && (
            <button onClick={this.onRestaurar} title="Restaurar construções">
              <Icon icon="undo" darkPrimary />
            </button>
          )}
        </SectionTitle>
        {segmentos.length > 0 && (
          <Panel isTable>
            <div className="table-editable panel-table-outer">
              <div className="table-editable-header">
                <Row>
                  <Col sm={2}>Sequência</Col>
                  <Col sm={3}>Tipo</Col>
                  <Col sm={3}>Área construída</Col>
                  <Col sm={3}>Área ITBI</Col>
                  <Col sm={1} />
                </Row>
              </div>
              <div className="table-editable-content">
                {segmentos.map(segmento =>
                  this.renderSegmentos(
                    segmento,
                    canEdit,
                    canDelete,
                    hasContestacao
                  )
                )}
              </div>
            </div>
          </Panel>
        )}
        {(!segmentos || segmentos.length === 0) && <div>Sem construções</div>}
      </div>
    );
  }
}

export default SegmentoSection;
