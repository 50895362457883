const getFieldName = (unifiedPath, fieldNames) => {
  if (fieldNames[unifiedPath]) {
    return fieldNames[unifiedPath];
  }

  const lastPath = unifiedPath
    .split('_')
    .splice(-1)
    .pop();
  if (fieldNames[lastPath]) {
    return fieldNames[lastPath];
  }

  if (!isNaN(parseInt(lastPath, 10))) {
    return '';
  }

  return unifiedPath;
};

export default { getFieldName };
