import {
  Container,
  FAB,
  Loading,
  NotificationActions,
  withAuth
} from '@elotech/components';
import { DocumentoItbiService, withService } from 'itbi-common/service';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Roles } from '../../roles';
import DocumentoItbiQuickView from './DocumentoItbiQuickView';
import DocumentosItbiList from './DocumentosItbiList';

class DocumentosItbiListPage extends React.Component {
  static propTypes = {
    showNotification: PropTypes.func.isRequired,
    documentoItbiService: PropTypes.object.isRequired,
    auth: PropTypes.shape({
      hasRole: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    documentos: [],
    pagination: undefined,
    viewDocumento: undefined,
    loadingDocumento: false
  };

  onSearchSuccess = response => {
    const {
      content,
      number,
      totalPages,
      first,
      last,
      numberOfElements,
      size
    } = response.data;
    if (content && content.length > 0) {
      return this.setState({
        documentos: content,
        pagination: {
          number,
          totalPages,
          first,
          last,
          numberOfElements,
          size
        }
      });
    } else {
      this.props.showNotification({
        level: 'warning',
        message:
          'Não foi possível encontrar nenhum registro com o filtro informado.'
      });
      return this.setState({
        documentos: []
      });
    }
  };

  onSearchError = error => {
    this.setState({ loadingDocumento: false });
  };

  onSearch = (searchParam = this.state.searchText, page) => {
    this.setState({ loadingDocumento: true });
    this.setState({ searchText: searchParam }, () => {
      this.props.documentoItbiService
        .search(searchParam, page)
        .then(this.onSearchSuccess)
        .then(() => {
          this.setState({ loadingDocumento: false });
        })
        .catch(this.onSearchError);
    });
  };

  onCreate = () => {
    this.props.history.push('/documentos/novo');
  };

  onEdit = documento => {
    this.props.history.push(`/documentos/${documento.id}`);
  };

  onViewAlteracoes = documento => {
    this.props.history.push(`/documentos/${documento.id}/history`);
  };

  onDeleteSuccess = success => {
    this.props.showNotification({
      level: 'success',
      message: 'Documento deletado com sucesso'
    });

    this.onSearch();
  };

  onDeleteErrror = error => {
    if (error.response.data.status === 400) {
      this.props.showNotification({
        level: 'warning',
        message: error.response.data.message
      });
    } else {
      this.props.showNotification({
        level: 'warning',
        message: 'Não foi possível excluir o documento.'
      });
    }
  };

  onDelete = documento => {
    this.props.documentoItbiService
      .deleteDocument(documento.id)
      .then(this.onDeleteSuccess)
      .catch(this.onDeleteErrror);
  };

  onView = documento => {
    this.setState({ viewDocumento: documento });
  };

  onCloseQuickView = () => {
    this.setState({ viewDocumento: undefined });
  };

  render() {
    const {
      documentos,
      pagination,
      viewDocumento,
      loadingDocumento
    } = this.state;
    const { auth } = this.props;
    return (
      <Container title="Documentos ITBI" icon="file-alt">
        <Loading loading={loadingDocumento} />
        <DocumentosItbiList
          onEdit={this.onEdit}
          onSearch={this.onSearch}
          onDelete={this.onDelete}
          documentos={documentos}
          pagination={pagination}
          onView={this.onView}
          onViewAlteracoes={this.onViewAlteracoes}
        />

        {auth.hasRole(Roles.cadastro_documento_itbi_adicionar.name) && (
          <div className="btn-save">
            <FAB
              icon="plus"
              iconColor="white"
              title="Novo Documento"
              onClick={this.onCreate}
            />
          </div>
        )}

        <DocumentoItbiQuickView
          documento={viewDocumento}
          onClose={this.onCloseQuickView}
        />
      </Container>
    );
  }
}

const ComponentWithAuth = withAuth(DocumentosItbiListPage);
const ComponentWithService = withService({
  documentoItbiService: DocumentoItbiService
})(ComponentWithAuth);

const ConnectedComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(ComponentWithService);

export { ConnectedComponent as default, DocumentosItbiListPage };
