import {
  Alert,
  Container,
  Loading,
  NotificationActions
} from '@elotech/components';
import {
  LaudoService,
  PrintService,
  UserService,
  withService
} from 'itbi-common/service';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import LoteLaudoUtils from '../../utils/LoteLaudoUtils';
import LoteLaudoList from './LoteLaudoList';
import { getFields } from './SearchFilterLoteLaudo';

const fields = getFields(UserService);

class LoteLaudoListPage extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object,
    showNotification: PropTypes.func.isRequired,
    laudoService: PropTypes.shape({
      searchLaudosServidor: PropTypes.func.isRequired,
      togglePrioritario: PropTypes.func.isRequired,
      estornar: PropTypes.func.isRequired,
      indeferir: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    laudosItbiPage: {},
    laudos: [],
    pagination: undefined,
    searchText: '',
    submittingLaudo: false,
    imprimindo: false,
    loteLaudoQuickView: undefined
  };

  onSearch = (searchParam = this.state.searchText, page, sort) => {
    this.setState({ submittingLaudo: true, searchText: searchParam }, () => {
      this.props.laudoService
        .searchLaudosServidor(searchParam, page, sort)
        .then(response => {
          const {
            content,
            number,
            totalPages,
            first,
            last,
            numberOfElements,
            size,
            totalElements
          } = response.data;

          return this.setState({
            laudos: content,
            pagination: {
              number,
              totalPages,
              first,
              last,
              numberOfElements,
              size,
              totalElements
            }
          });
        })
        .catch(error => {
          Alert.error(
            { title: 'Não foi possível localizar os laudos.' },
            error
          );
        })
        .finally(() => this.setState({ submittingLaudo: false }));
    });
  };

  onViewLaudo = laudo => {
    this.props.history.push(`/laudos/${laudo.id}/resumo`);
  };

  onEditLaudo = laudo => {
    LoteLaudoUtils.onEditLaudo(laudo.id, this.props.history);
  };

  onViewAnexos = laudo => {
    LoteLaudoUtils.onViewAnexos(laudo.id, this.props.history);
  };

  onFavoriteLaudo = laudo => {
    const { showNotification, laudoService } = this.props;

    LoteLaudoUtils.onFavoriteLaudo(
      laudo,
      laudoService,
      state => this.setState(state),
      showNotification,
      this.onSearch,
      this.motivoPrioritarioValidator
    );
  };

  onPrintBoletoDefault = urlBoleto => window.open(urlBoleto, '_blank');

  onPrintBoleto = async laudo => {
    const { laudoService } = this.props;

    if (!laudo.idDebito) {
      await LoteLaudoUtils.onGerarDebitos(
        laudo,
        laudoService,
        state => this.setState(state),
        this.onSearch
      ).then(result => {
        laudo = result;
      });
    }

    if (!laudo.urlBoletoServidor) {
      LoteLaudoUtils.onGerarBoleto(
        laudo,
        laudoService,
        state => this.setState(state),
        this.onSearch
      ).then(result => {
        this.onPrintBoletoDefault(result.data.urlBoletoServidor);
      });
      return;
    }

    this.onPrintBoletoDefault(laudo.urlBoletoServidor);
  };

  onIndeferirLaudo = laudo => {
    const { showNotification, laudoService } = this.props;

    LoteLaudoUtils.onIndeferirLaudo(
      laudoService,
      laudo.id,
      showNotification,
      state => this.setState(state),
      this.onSearch
    );
  };

  onViewFavoriteLaudo = laudo => {
    LoteLaudoUtils.onViewFavoriteLaudo(laudo.motivoPrioritario);
  };

  onTransferir = laudo => {
    LoteLaudoUtils.onTransferirResponsavel(laudo.id, this.props.history);
  };

  onEstornar = laudo => {
    const { showNotification, laudoService } = this.props;

    LoteLaudoUtils.onEstornar(
      laudo.id,
      laudoService,
      state => this.setState(state),
      showNotification,
      this.onSearch
    );
  };

  imprimir = options => {
    const { searchText } = this.state;
    this.setState({ imprimindo: true });
    PrintService.print('/laudos/report', searchText, {
      ...options,
      sumFields: 'qtdImoveis,valorTotal',
      sort: LaudoService.defaultSort.sort
    })
      .catch(error => {
        Alert.error({ title: 'Falha ao imprimir os laudos!' }, error);
      })
      .finally(() => {
        this.setState({ imprimindo: false });
      });
  };

  render() {
    const { submittingLaudo, imprimindo, laudos, pagination } = this.state;
    const { currentUser } = this.props;
    return (
      <Container title="Laudos" icon="paperclip">
        <Loading loading={submittingLaudo || imprimindo} />
        <LoteLaudoList
          loteLaudos={laudos}
          pagination={pagination}
          onSearch={this.onSearch}
          onCreate={this.onCreate}
          onViewLaudo={this.onViewLaudo}
          onEditLaudo={this.onEditLaudo}
          onFavoriteLaudo={this.onFavoriteLaudo}
          onViewFavoriteLaudo={this.onViewFavoriteLaudo}
          onTransferir={this.onTransferir}
          onEstornar={this.onEstornar}
          onIndeferirLaudo={this.onIndeferirLaudo}
          onPrint={this.imprimir}
          onPrintBoleto={this.onPrintBoleto}
          currentUser={currentUser}
          getFields={fields}
          onViewAnexos={this.onViewAnexos}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser
  };
};

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ComponentWithService = withService({
  laudoService: LaudoService
})(LoteLaudoListPage);

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithService);

export { ConnectedComponent as default, LoteLaudoListPage };
