import {
  Alert,
  Col,
  FormattedDate,
  Icon,
  TableReport
} from '@elotech/components';
import {
  DeclaracaoService,
  LaudoService,
  withService
} from 'itbi-common/service';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Roles } from '../../roles';
import WarnningDuplicidadePedido from './WarnningDuplicidadePedido';

const CursorDefault = styled.div`
  && {
    cursor: default;
  }
`;

export const renderRow = (pedido, infoRow, validaPedidoDuplicado) => (
  <TableReport.Row
    key={pedido.id}
    nowrap
    warning={infoRow === 1}
    error={infoRow === 2}
  >
    <TableReport.Col md={4} header="Data">
      <FormattedDate value={pedido.dataLancamento} timeZone={'UTC'} />
    </TableReport.Col>
    <TableReport.Col
      md={validaPedidoDuplicado === true ? 3 : 5}
      header="Número"
    >
      {pedido.numero}
    </TableReport.Col>
    <TableReport.Col md={validaPedidoDuplicado === true ? 2 : 3} header="Tipo">
      {pedido.tipo}
    </TableReport.Col>
    {validaPedidoDuplicado && (
      <TableReport.Col md={3} header="Duplicado">
        {pedido.duplicado === true ? 'Sim' : 'Não'}
      </TableReport.Col>
    )}
  </TableReport.Row>
);

class PedidosPendentesTable extends React.Component {
  state = {
    submitting: false,
    duplicidades: [],
    warnningDuplicidade: false
  };

  alertError = (pedido, error) => {
    const { refreshPedidosPendentes } = this.props;
    const httpStatusUnprocessableEntity = 422;

    if (error.response?.status === httpStatusUnprocessableEntity) {
      refreshPedidosPendentes();
      Alert.info({
        title: `Não foi possível assumir ${pedido.tipo} ${pedido.numero}`,
        text:
          'Este pedido já está sendo analisado, a lista de pedidos pendentes está sendo atualizada.'
      });
    } else {
      Alert.error(
        {
          title: `Erro ao assumir ${pedido.tipo} ${pedido.numero}`
        },
        error
      );
    }
  };

  verificaDuplicidade = pedido => {
    this.props.declaracaoService
      .verificaDuplicidade(pedido.id)
      .then(response => {
        const duplicidades = response.data;
        if (duplicidades?.length > 0) {
          this.setState({
            duplicidades: response.data,
            warnningDuplicidade: true
          });
        } else {
          this.assumirResponsabilidadeDeclaracao(pedido);
        }
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao verificar possíveis duplicidades da Declaração'
          },
          error
        );
      })
      .finally(() => this.setState({ submitting: false }));
  };

  assumirResponsabilidadeDeclaracao = pedido => {
    const { declaracaoService } = this.props;

    declaracaoService
      .assumirResponsabilidade(pedido.id)
      .then(response =>
        this.props.history.push(`/declaracoes-itbi/${response.data.id}`)
      )
      .catch(error => {
        this.setState({ submitting: false });
        this.alertError(pedido, error);
      });
  };

  assumirResponsabilidadeLaudo = pedido => {
    const { laudoService } = this.props;

    laudoService
      .assumirResponsabilidade(pedido.id)
      .then(response => this.props.history.push(`/laudos/${response.data.id}`))
      .catch(error => {
        this.setState({ submitting: false });
        this.alertError(pedido, error);
      });
  };

  analisar = () => {
    const { values, validaPedidoDuplicado } = this.props;
    const primeiroPedido = values[0];

    this.setState({ submitting: true });

    if (primeiroPedido.tipo === 'ITBI') {
      if (validaPedidoDuplicado === true) {
        this.verificaDuplicidade(primeiroPedido);
      } else {
        this.assumirResponsabilidadeDeclaracao(primeiroPedido);
      }
    } else {
      this.assumirResponsabilidadeLaudo(primeiroPedido);
    }
  };

  renderButtonAnalisar = () => {
    const { hasRole, values } = this.props;
    const { submitting } = this.state;
    if (
      !!values.length &&
      (hasRole(Roles.itbi_assumir_pedido.name) ||
        hasRole(Roles.laudo_assumir_pedido.name))
    ) {
      return (
        <button
          className="btn module-color"
          onClick={this.analisar}
          disabled={submitting}
        >
          Analisar
          {submitting && <Icon icon="spinner" darkPrimary spin={submitting} />}
        </button>
      );
    }
    return null;
  };

  render() {
    const {
      values,
      total,
      loading,
      error,
      onInfoRow,
      validaPedidoDuplicado
    } = this.props;
    const { duplicidades, warnningDuplicidade } = this.state;

    return (
      <React.Fragment>
        <TableReport.Title
          icon="th-list"
          title="Pedidos"
          subtitle="Aguardando análise"
        />
        <TableReport
          loading={loading}
          error={error}
          height="40vh"
          empty={values.length === 0}
        >
          <TableReport.Summary size={validaPedidoDuplicado === true ? 3 : 4}>
            <CursorDefault className="block-dash report">
              <div className="number">{total}</div>
              <div className="title">Aguardando</div>
              {this.renderButtonAnalisar()}
            </CursorDefault>
          </TableReport.Summary>
          <TableReport.Header>
            <Col md={4}>Data</Col>
            <Col md={validaPedidoDuplicado === true ? 3 : 5}>
              Número do Pedido
            </Col>
            <Col md={validaPedidoDuplicado === true ? 2 : 3}>Tipo</Col>
            {validaPedidoDuplicado && <Col md={3}>Duplicado</Col>}
          </TableReport.Header>
          <TableReport.Rows>
            {values.map(pedido =>
              renderRow(
                pedido,
                onInfoRow(pedido.diasCorridos),
                validaPedidoDuplicado
              )
            )}
          </TableReport.Rows>
        </TableReport>
        {
          <WarnningDuplicidadePedido
            duplicidades={duplicidades}
            warnningDuplicidade={warnningDuplicidade}
            pedido={values[0]}
            onClose={() => {
              this.setState({ warnningDuplicidade: false, submitting: true });
              this.assumirResponsabilidadeDeclaracao(values[0]);
            }}
          />
        }
      </React.Fragment>
    );
  }
}

PedidosPendentesTable.propTypes = {
  values: PropTypes.array,
  loading: PropTypes.bool,
  onInfoRow: PropTypes.func.isRequired,
  hasRole: PropTypes.func.isRequired,
  declaracaoService: PropTypes.shape({
    assumirResponsabilidade: PropTypes.func.isRequired
  }).isRequired,
  laudoService: PropTypes.shape({
    assumirResponsabilidade: PropTypes.func.isRequired
  }).isRequired,
  refreshPedidosPendentes: PropTypes.func.isRequired,
  validaPedidoDuplicado: PropTypes.bool
};

PedidosPendentesTable.defaultProps = {
  loading: false,
  values: [],
  validaPedidoDuplicado: false
};

const componentWithService = withRouter(
  withService({
    declaracaoService: DeclaracaoService,
    laudoService: LaudoService
  })(PedidosPendentesTable)
);

export { componentWithService as default, PedidosPendentesTable };
