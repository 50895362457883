import { HistoryTable, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

import LoteLaudoHistoryRow from './LoteLaudoHistoryRow';

const LoteLaudoHistory = ({ revisions }) => {
  return (
    <React.Fragment>
      <SectionTitle>Histórico de alterações</SectionTitle>
      <HistoryTable>
        {revisions.map(revision => {
          return revision.diff ? (
            <LoteLaudoHistoryRow
              key={revision.revision.revisionNumber}
              revision={revision}
            />
          ) : null;
        })}
      </HistoryTable>
    </React.Fragment>
  );
};

LoteLaudoHistory.propTypes = {
  revisions: PropTypes.array.isRequired
};

export default LoteLaudoHistory;
