import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { ForbiddenPage, useAuth } from '../';

export type ProtectedRouteProps = {
  role: string;
  forbiddenPage?: React.ReactNode;
} & RouteProps;

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  role,
  path,
  exact,
  component,
  forbiddenPage = <ForbiddenPage />,
  ...props
}) => {
  const { hasRole } = useAuth();

  if (hasRole(role)) {
    return <Route path={path} component={component} exact={exact} {...props} />;
  }

  return <>{forbiddenPage}</>;
};

export { ProtectedRoute };
