import axios, { AxiosPromise } from 'axios';

export const getTipoItbi = (id: string): AxiosPromise<any> =>
  axios.get(`/api/tipos-itbi/${id}`);

export const getTiposItbi = (): AxiosPromise<any> =>
  axios.get('/api/tipos-itbi/');

export const getTipoItbiAise = (): AxiosPromise<any> =>
  axios.get('/api/tipos-itbi/aise');

export const getVisiveis = (): AxiosPromise<any> =>
  axios.get('/api/tipos-itbi/visiveis');

export const updateVisivel = (tipoItbi: any): AxiosPromise<any> =>
  axios.put(`/api/tipos-itbi/${tipoItbi.id}/visivel`);

export const getHistoricoAlteracoes = (id: string): AxiosPromise<any> =>
  axios.get(`/api/tipos-itbi/${id}/history`);

export const save = (tipoItbi: object): AxiosPromise<any> =>
  axios.post('/api/tipos-itbi', tipoItbi);

export const remove = (id: string): AxiosPromise<any> =>
  axios.delete(`/api/tipos-itbi/${id}`);
