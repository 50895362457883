import axios, { AxiosPromise } from 'axios';

export const getMeusPedidos = (): AxiosPromise<any> =>
  axios.get('/api/pedidos/meus');

export const getPedidosPendentes = (): AxiosPromise<any> =>
  axios.get('/api/pedidos/pendentes');

export const getPedidosTransferencia = (): AxiosPromise<any> =>
  axios.get('/api/pedidos/liberacao');
