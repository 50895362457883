import { DisplayDataItem, FormattedCurrency, Panel } from '@elotech/components';
import React from 'react';

import LaudoAnexosArquivosSection from './LaudoAnexosArquivosSection';
import LaudoFormInformacao from './LaudoFormInformacao';

type Props = {
  laudo: any;
};

const LaudoAnexosForm: React.FC<Props> = props => {
  const renderComponent = (laudo: any) => {
    return (
      <DisplayDataItem md={2} title="Valor Avaliado">
        {<FormattedCurrency value={laudo.valorAvaliado} />}
      </DisplayDataItem>
    );
  };

  return (
    <React.Fragment key={props.laudo.id}>
      <Panel style={{ margin: 10, border: '1px solid #DDDDDD' }}>
        <LaudoFormInformacao
          laudo={props.laudo}
          renderComponent={renderComponent}
          desconsideraAreaConstruida={props.laudo.desconsideraAreaConstruida}
        />
        <Panel insidePanel style={{ marginTop: 3 }}>
          <b className="dd-title">Arquivo</b>
          <LaudoAnexosArquivosSection laudo={props.laudo} />
        </Panel>
      </Panel>
    </React.Fragment>
  );
};

export default LaudoAnexosForm;
