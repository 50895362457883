import { ReactComponent as Logo } from '@elotech/arc/src/assets/img/modules/oxy-arrecadacao.svg';
import { ApplicationContext, Header } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

export class AppHeader extends React.Component {
  static contextType = ApplicationContext;

  onMyAccount = () => {
    this.props.history.push('/minha-conta');
  };

  onView = notification => {
    if (notification.metadata) {
      const { tipoPedido, idPedido } = notification.metadata;

      if (tipoPedido === 'ITBI') {
        this.props.history.replace(`/declaracoes-itbi/${idPedido}/resumo`);
      } else if (tipoPedido === 'LAUDO') {
        this.props.history.replace(`/laudos/${idPedido}/resumo`);
      }
    }
  };

  onShowView = notification => {
    if (notification.metadata) {
      const { tipoPedido } = notification.metadata;
      return tipoPedido === 'ITBI' || tipoPedido === 'LAUDO';
    }
    return false;
  };
  openNovidadesVersao = () => {
    window.open('https://elotech.movidesk.com/kb/category/oxy-itbi', '_blank');
  };

  render() {
    const { showMenu, idCidadeGateway } = this.props;
    const { entidade } = this.context;
    return (
      <Header
        title={entidade?.nome ?? 'ITBI Servidor'}
        showToggleButton={showMenu}
        logoModulo={Logo}
        idCidadeGateway={idCidadeGateway}
        brasao={entidade?.brasao}
        permiteTrocarEntidade={false}
      >
        <Header.Features>
          <Header.Features.AccessibilityButton />
          <Header.Features.NotificationButton />
          <Header.Features.AjustesButton />
          <Header.Features.ModulosButton />
        </Header.Features>
        <Header.FeaturesContent>
          <Header.FeaturesContent.AccessibilityContent />
          <Header.FeaturesContent.EloNotificationContent />
          <Header.FeaturesContent.AjustesContent
            onMyAccount={this.onMyAccount}
            showNovidadesVersao
            onClickNovidadesVersao={this.openNovidadesVersao}
          />
          <Header.FeaturesContent.ModulosContent />
        </Header.FeaturesContent>
      </Header>
    );
  }
}

AppHeader.propTypes = {
  showMenu: PropTypes.bool
};

const mapStateToProps = state => ({
  idCidadeGateway: state.cidade.idCidadeGateway
});

const connectedComponent = connect(mapStateToProps)(AppHeader);

export default withRouter(connectedComponent);
