import {
  Alert,
  Container,
  FabSpeedDial,
  Info,
  Loading,
  NotificationActions,
  withAuth
} from '@elotech/components';
import { LotePropriedadeQuickView } from 'itbi-common/components';
import { LaudoService, withService } from 'itbi-common/service';
import { DiffUtils } from 'itbi-common/utils';
import LoteLaudoViewForm from 'itbi-servidor/src/pages/laudos/LoteLaudoViewForm';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Roles } from '../../roles';
import LoteLaudoUtils from '../../utils/LoteLaudoUtils';
import LoteLaudoHistory from './history/LoteLaudoHistory';
import { renderFabButtons } from './LoteLaudoActions';
import LoteLaudoViewInformacao from './LoteLaudoViewInformacao';

const labelsChildren = ['propriedades'];

class LoteLaudoViewPage extends React.Component {
  static propTypes = {
    showNotification: PropTypes.func.isRequired,
    laudoService: PropTypes.shape({
      getLaudo: PropTypes.func.isRequired,
      getHistory: PropTypes.func.isRequired
    }).isRequired,
    auth: PropTypes.shape({
      hasRole: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    loteLaudo: undefined,
    submittingLaudo: false,
    loteLaudoViewQuickPage: undefined,
    situacaoLaudo: undefined,
    history: undefined
  };

  componentDidMount() {
    const {
      laudoService,
      match: { params }
    } = this.props;

    this.setState({ submittingLaudo: true });
    laudoService
      .getLaudo(params.id)
      .then(response => {
        laudoService
          .getHistory(params.id)
          .then(historyResponse => {
            this.setState({
              loteLaudo: response.data,
              history: this.parseDiff(historyResponse.data)
            });
          })
          .catch(error => {
            Alert.error(
              { title: 'Não foi possível encontrar o histórico do laudo.' },
              error
            );
          })
          .finally(() => {
            this.setState({ submittingLaudo: false });
          });
      })
      .catch(error => {
        this.setState({ submittingLaudo: false });
        Alert.error({ title: 'Não foi possível encontrar o laudo.' }, error);
      });
  }

  onViewLaudo = laudo => () => {
    this.setState({ loteLaudoViewQuickPage: laudo });
  };

  onPrintDocumento = laudo => {
    window.open(laudo.urlDocumento, '_blank');
  };

  onEditLaudo = laudo => {
    LoteLaudoUtils.onEditLaudo(laudo.id, this.props.history);
  };

  onCloseQuickView = () => {
    this.setState({ loteLaudoViewQuickPage: undefined });
  };

  onViewAnexos = laudo => {
    LoteLaudoUtils.onViewAnexos(laudo.id, this.props.history);
  };

  onApagarGuiasPagamento = laudo => {
    Alert.question({
      title: 'Deseja realmente apagar a guia de pagamento?'
    }).then(result => {
      if (result.value) {
        const { laudoService } = this.props;
        this.setState({ submittingLaudo: true });
        laudoService
          .apagarGuiaPagamento(laudo.id)
          .then(response => {
            Alert.info({
              title: 'A guia de pagamento foi excluída com sucesso.'
            });
          })
          .catch(error => {
            Alert.error(
              { title: 'Não foi possível apagar a guia de pagamento.' },
              error
            );
          })
          .finally(() => {
            this.setState({ submittingLaudo: false });
          });
      }
    });
  };

  onPrintBoletoDefault = urlBoleto => {
    window.open(urlBoleto, '_blank');
  };

  onPrintBoleto = async laudo => {
    const { laudoService } = this.props;

    if (!laudo?.idDebito) {
      await LoteLaudoUtils.onGerarDebitos(
        laudo,
        laudoService,
        state => this.setState(state),
        undefined
      ).then(result => {
        this.setState(prevState => {
          return {
            loteLaudo: {
              ...prevState.loteLaudo,
              idDebito: result.idDebito,
              urlBoleto: result.urlBoleto,
              urlBoletoServidor: result.urlBoletoServidor
            }
          };
        });
        laudo = result;
      });
    }

    if (!laudo?.urlBoletoServidor) {
      LoteLaudoUtils.onGerarBoleto(
        laudo,
        laudoService,
        state => this.setState(state),
        undefined
      ).then(result => {
        this.setState(
          prevState => {
            return {
              loteLaudo: {
                ...prevState.loteLaudo,
                urlBoleto: result.data.urlBoleto,
                urlBoletoServidor: result.data.urlBoletoServidor
              }
            };
          },
          () => {
            this.onPrintBoletoDefault(this.state.loteLaudo.urlBoletoServidor);
          }
        );
      });
      return;
    }

    this.onPrintBoletoDefault(laudo.urlBoletoServidor);
  };

  onIndeferirLaudo = () => {
    const { id } = this.props.match.params;
    const { showNotification, laudoService } = this.props;

    LoteLaudoUtils.onIndeferirLaudo(
      laudoService,
      id,
      showNotification,
      state => this.setState(state),
      undefined
    ).then(result => {
      this.setState(prevState => {
        return {
          loteLaudo: {
            ...prevState.loteLaudo,
            situacaoLaudo: result.data.situacaoLaudo,
            motivoIndeferimento: result.data.motivoIndeferimento
          }
        };
      });
    });
  };

  onViewFavoriteLaudo = laudo => {
    LoteLaudoUtils.onViewFavoriteLaudo(laudo.motivoPrioritario);
  };

  onFavoriteLaudo = laudo => {
    const { showNotification, laudoService } = this.props;

    LoteLaudoUtils.onFavoriteLaudo(
      laudo,
      laudoService,
      state => this.setState(state),
      showNotification
    ).then(result => {
      this.setState({ loteLaudo: result.data });
    });
  };

  onTransferir = laudo => {
    LoteLaudoUtils.onTransferirResponsavel(laudo.id, this.props.history);
  };

  onEstornar = laudo => {
    const { showNotification, laudoService } = this.props;

    LoteLaudoUtils.onEstornar(
      laudo.id,
      laudoService,
      state => this.setState(state),
      showNotification
    ).then(result => {
      this.setState(prevState => {
        return {
          loteLaudo: {
            ...prevState.loteLaudo,
            situacaoLaudo: result.data.situacao
          }
        };
      });
    });
  };

  identity = (a, b) => (!a || !b ? false : a.id === b.id);
  ignorePaths = (path, key) => labelsChildren.includes(key);

  parseDiff = history => {
    let historyDiff = DiffUtils.parseDiff(history, {}, this.ignorePaths);
    return DiffUtils.parseDiffChildren(
      historyDiff,
      labelsChildren,
      this.identity
    );
  };

  render() {
    const { auth } = this.props;
    const hasPermission = auth.hasRole(Roles.acesso_prioritario.name);
    const {
      loteLaudo,
      submittingLaudo,
      loteLaudoViewQuickPage,
      history
    } = this.state;

    if (!loteLaudo) {
      return <Loading loading={submittingLaudo} />;
    }

    return (
      <Container title="Resumo do Laudo" icon="paperclip">
        <Loading loading={submittingLaudo} />
        {hasPermission === true && loteLaudo.prioritario === true ? (
          <Info classes="warning mb-xs">
            {`Motivo da Priorização: ${loteLaudo.motivoPrioritario}`}
          </Info>
        ) : null}
        {loteLaudo.situacaoLaudo.nome === 'INDEFERIDO' ? (
          <Info classes="warning mb-xs">
            {`Laudo indeferido, motivo: ${loteLaudo.motivoIndeferimento}`}
          </Info>
        ) : null}
        <LoteLaudoViewInformacao loteLaudo={loteLaudo} />
        <LoteLaudoViewForm
          loteLaudo={loteLaudo.propriedades}
          onViewLaudo={this.onViewLaudo}
          situacaoLaudo={loteLaudo.situacaoLaudo.nome}
          onPrintDocumento={this.onPrintDocumento}
          auth={auth}
        />
        {history && <LoteLaudoHistory revisions={history} />}
        <LotePropriedadeQuickView
          laudo={loteLaudoViewQuickPage}
          onClose={this.onCloseQuickView}
        />
        <FabSpeedDial icon="ellipsis-v" title="Abrir ações deste laudo">
          {renderFabButtons(
            loteLaudo,
            {
              onEditLaudo: this.onEditLaudo,
              onViewLaudo: this.onViewLaudo,
              onFavoriteLaudo: this.onFavoriteLaudo,
              onViewFavoriteLaudo: this.onViewFavoriteLaudo,
              onTransferir: this.onTransferir,
              onEstornar: this.onEstornar,
              onIndeferirLaudo: this.onIndeferirLaudo,
              onCopy: this.onCopiar,
              onPrint: this.onPrintDocumento,
              onPrintBoleto: this.onPrintBoleto,
              onViewAnexos: this.onViewAnexos,
              onApagarGuiasPagamento: this.onApagarGuiasPagamento
            },
            { resumo: true },
            auth
          )}
        </FabSpeedDial>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ComponentWithService = withService({
  laudoService: LaudoService
})(LoteLaudoViewPage);

const ComponentWithAuth = withAuth(ComponentWithService);

const ConnectedComponent = connect(null, mapDispatchToProps)(ComponentWithAuth);

export { ConnectedComponent as default, LoteLaudoViewPage };
