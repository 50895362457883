import axios, { AxiosPromise } from 'axios';
import ServiceUtils from '../utils/ServiceUtils';
import { FilterUtils } from '@elotech/components';

export const loadTiposLogradouro = (searchParams: string): AxiosPromise<any> =>
  axios.get(`/api/tipos-logradouro-aise`, {
    params: { search: FilterUtils.buildDefaultFilter()(searchParams) }
  });

export const loadLogradouros = (searchParams: string): AxiosPromise<any> =>
  axios.get(
    `/api/logradouros-aise?${ServiceUtils.buildSearchParams(searchParams)}`
  );
