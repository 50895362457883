import {
  ActionButton,
  ActionsGroup,
  FormattedCurrency,
  Table
} from '@elotech/components';
import { LaudoUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';

import { Roles } from '../../roles';

const ActionButtons = ({
  situacaoLaudo,
  loteLaudo,
  onPrintDocumento,
  onViewLaudo,
  auth
}) => {
  const canPrint =
    situacaoLaudo === 'LIBERADO' &&
    loteLaudo.urlDocumento &&
    auth.hasRole(Roles.laudo_imprimir.name);

  return (
    <ActionsGroup>
      {canPrint ? (
        <ActionButton
          customClass="circle"
          key="print-button"
          icon="file-download"
          label="Imprimir"
          onClick={() => onPrintDocumento(loteLaudo)}
        />
      ) : null}
      <ActionButton
        customClass="circle"
        key="see-button"
        icon="eye"
        label="Ver propriedades"
        onClick={onViewLaudo(loteLaudo)}
      />
    </ActionsGroup>
  );
};

const LoteLaudoViewForm = props => {
  const {
    loteLaudo,
    onViewLaudo,
    onPrintDocumento,
    situacaoLaudo,
    auth
  } = props;

  return (
    <div className="panel table table-responsive">
      <div className="panel-body">
        <Table values={loteLaudo}>
          <Table.Column header="Cadastro" value={item => item.cadastro} />
          <Table.Column
            header="Endereço"
            value={item => LaudoUtils.buildEnderecoCadastro(item)}
          />
          <Table.Column
            header="Valor Estimado"
            value={item => <FormattedCurrency value={item.valorEstimado} />}
          />
          <Table.Column
            header="Valor Avaliado"
            value={item => <FormattedCurrency value={item.valorAvaliado} />}
          />
          <Table.Column
            header=""
            value={item => (
              <ActionButtons
                situacaoLaudo={situacaoLaudo}
                loteLaudo={item}
                onPrintDocumento={onPrintDocumento}
                onViewLaudo={onViewLaudo}
                auth={auth}
              />
            )}
          />
        </Table>
      </div>
    </div>
  );
};

LoteLaudoViewForm.propTypes = {
  loteLaudo: PropTypes.array.isRequired,
  onViewLaudo: PropTypes.func.isRequired,
  onPrintDocumento: PropTypes.func.isRequired,
  situacaoLaudo: PropTypes.string,
  auth: PropTypes.shape({
    hasRole: PropTypes.func.isRequired
  }).isRequired
};

export default LoteLaudoViewForm;
