import { NotificationActions } from '@elotech/components';
import { UserService } from 'itbi-common/service';
import { UserOperations } from 'itbi-common/state/user';
import { ObjectUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import MyAccountForm from './MyAccountForm';

const defaultUser = {
  cpfCnpj: '',
  nome: '',
  email: '',
  procurador: false,
  termoResponsabilidade: true,
  tipoPessoa: 'FISICA'
};

class MyAccountPage extends React.Component {
  static propTypes = {
    userData: PropTypes.object,
    getUserData: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired
  };

  state = {
    user: defaultUser,
    error: {
      cpfCnpj: false,
      nome: false,
      email: ''
    },
    loading: false
  };

  componentDidMount() {
    const { userData: user } = this.props;
    if (user !== undefined && !ObjectUtils.isEmptyObject(user)) {
      this.setState({ user });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userData !== this.props.userData) {
      this.setState({ user: this.props.userData });
    }
  }

  onChangeField = event => {
    const { name, value, checked, type } = event.target;
    this.setState(state => {
      const newValue = type === 'checkbox' ? checked : value;
      const { user, error } = state;
      const newError = error[name] ? !newValue : error[name];
      return {
        user: { ...user, [name]: newValue },
        error: { ...error, [name]: newError }
      };
    });
  };

  onChangeFieldMask = event => {
    const { name, value = '' } = event.target;
    this.setState(state => {
      const newValue = value.replace(/\D/g, '');
      const { user, error } = state;
      const newError = error[name] ? !newValue : error[name];
      return {
        user: { ...user, [name]: newValue },
        error: { ...error, [name]: newError }
      };
    });
  };

  onSave = () => {
    const { user, error } = this.state;

    const newError = {};

    if (!user.cpfCnpj) {
      newError.cpfCnpj = true;
    }

    if (!user.nome) {
      newError.nome = true;
    }

    if (!user.email) {
      newError.email = true;
    }
    if (!ObjectUtils.isEmptyObject(newError)) {
      return this.setState({ error: { ...error, ...newError } });
    }

    const { showNotification, getUserData } = this.props;
    return new Promise(resolve => {
      this.setState({ loading: true }, () => {
        UserService.patchUser(user)
          .then(() => {
            showNotification({
              level: 'success',
              title: 'Sucesso',
              message: 'Seus dados foram atualizados com sucesso.'
            });
            getUserData();
          })
          .catch(e => {
            showNotification({
              level: 'warning',
              message: e.response.data.message
            });
          })
          .finally(() => {
            this.setState({ loading: false });
            resolve();
          });
      });
    });
  };

  onResetPassword = () => {
    return UserService.resetPassword()
      .then(() => {
        this.props.showNotification({
          level: 'success',
          message:
            'Foi enviado um email para o seu endereço cadastrado com um link que irá permitir a' +
            ' alteração da sua senha.'
        });
      })
      .catch(() => {
        this.props.showNotification({
          level: 'warning',
          message:
            'Não foi possível redefinir a senha, verifique o email cadastrado.'
        });
      });
  };

  render() {
    const { user, error, loading } = this.state;
    if (!user || ObjectUtils.isEmptyObject(user)) {
      return null;
    }
    return (
      <MyAccountForm
        error={error}
        user={user}
        loading={loading}
        onResetPassword={this.onResetPassword}
        onChangeField={this.onChangeField}
        onChangeFieldMask={this.onChangeFieldMask}
        onSave={this.onSave}
      />
    );
  }
}

const mapStateToProps = state => ({
  userData: state.user.currentUser
});

const ConnectedComponent = connect(mapStateToProps, {
  showNotification: NotificationActions.showNotification,
  getUserData: () => UserOperations.getUserData(true)
})(MyAccountPage);

export { ConnectedComponent as default, MyAccountPage };
