import { Col, DisplayData, Row, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';

const rightAlignStyle = {
  justifyContent: 'space-between'
};

const LoteLaudoTransferenciaDetail = ({ loteLaudo }) => {
  return (
    <React.Fragment>
      <SectionTitle> Dados do Laudo</SectionTitle>
      <div key={loteLaudo.id} className="display-data border small mb-xs">
        <Row>
          <Col sm={6}>
            <DisplayData title="Requerente">
              {loteLaudo.requerente?.nome ?? loteLaudo.requerenteNome}
            </DisplayData>
          </Col>
          <Col sm={6}>
            <DisplayData title="Responsável">
              {(loteLaudo.responsavel && loteLaudo.responsavel.nome) || ''}
            </DisplayData>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <DisplayData title="Número">{loteLaudo.numero}</DisplayData>
          </Col>
          <Col sm={4}>
            <DisplayData title="Data de lançamento">
              <FormattedDate
                value={loteLaudo.dataLancamento}
                timeZone={'UTC'}
              />
            </DisplayData>
          </Col>
          <Col sm={4}>
            <DisplayData title="Situação">
              {loteLaudo.situacaoLaudo.descricao}
            </DisplayData>
          </Col>
        </Row>
        <Row>
          <Col sm={4} style={rightAlignStyle}>
            <DisplayData title="Quantidade de Laudos">
              {loteLaudo.qtdImoveis}
            </DisplayData>
          </Col>
          <Col sm={4} style={rightAlignStyle}>
            <DisplayData title="Valor unitário">
              <FormattedNumber
                value={loteLaudo.valorUnitario}
                style="currency" // eslint-disable-line
                currency="BRL"
                currencyDisplay="symbol"
              />
            </DisplayData>
          </Col>
          <Col sm={4} style={rightAlignStyle}>
            <DisplayData title="Valor total">
              <FormattedNumber
                value={loteLaudo.valorTotal}
                style="currency" // eslint-disable-line
                currency="BRL"
                currencyDisplay="symbol"
              />
            </DisplayData>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

LoteLaudoTransferenciaDetail.propTypes = {
  loteLaudo: PropTypes.object.isRequired
};

export default LoteLaudoTransferenciaDetail;
