import {
  Alert,
  Button,
  Col,
  Container,
  Info,
  Loading,
  Panel,
  Row,
  SearchPanel
} from '@elotech/components';
import { LaudoService } from 'itbi-common/service';
import { TIPO_IMOVEL_RURAL } from 'itbi-common/type';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import LaudoAnexosForm from './analise/LaudoAnexosForm';
import LoteLaudoViewInformacao from './LoteLaudoViewInformacao';

type Props = {};

const LoteLaudoAnexoViewPage: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loteLaudo, setLoteLaudo] = useState<any>();
  const [laudos, setLaudos] = useState<any>();
  const [filtros, setFiltro] = useState('');

  useEffect(() => {
    setLoading(true);
    LaudoService.getLaudo(id)
      .then(response => {
        setLoteLaudo(response.data);
        setLaudos(response.data.propriedades);
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Não foi possível encontrar o laudo.'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  }, [id]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setFiltro(name);
  };

  const filtrar = () => {
    let list = loteLaudo.propriedades;
    if (filtros) {
      list = list.filter((laudo: any) => {
        const cadastroLaudo =
          laudo?.tipoImovel === TIPO_IMOVEL_RURAL
            ? laudo.cadastroRural
            : laudo.cadastroImobiliario;
        return String(cadastroLaudo?.cadastro).startsWith(filtros);
      });
    }

    setLaudos(list);
  };

  const renderLaudo = (laudo: any) => <LaudoAnexosForm laudo={laudo} />;

  return (
    <Container title="Resumo do Laudo" icon="paperclip">
      <Loading loading={loading} />
      {loteLaudo && (
        <>
          {loteLaudo.situacaoLaudo.nome === 'INDEFERIDO' ? (
            <Info classes="warning mb-xs">
              {`Laudo indeferido, motivo: ${loteLaudo.motivoIndeferimento}`}
            </Info>
          ) : null}
          <LoteLaudoViewInformacao loteLaudo={loteLaudo} />

          <Panel isTable>
            <SearchPanel>
              <Row className="flex" style={{ justifyContent: 'flex-end' }}>
                <Col sm={12}>
                  <input
                    name={'cadastro'}
                    value={filtros}
                    onChange={handleChange}
                    type={'number'}
                    placeholder={'Filtrar por número do cadastro municipal'}
                  />
                  <Button
                    data-test-id="buttonFiltrar"
                    className="btn filter"
                    onClick={filtrar}
                  >
                    Filtrar
                  </Button>
                </Col>
              </Row>
            </SearchPanel>
            <div className="panel-table-outer">
              {laudos && laudos.map(renderLaudo)}
            </div>
          </Panel>
        </>
      )}
    </Container>
  );
};

export default LoteLaudoAnexoViewPage;
