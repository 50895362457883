import React from 'react';

import { useAuth } from '../hooks';

export type ProtectedComponentProps = {
  role: string;
};

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({
  role,
  children
}) => {
  const { hasRole } = useAuth();

  if (hasRole(role)) {
    return <>{children}</>;
  }

  return null;
};

export { ProtectedComponent };
