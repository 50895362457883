import { Col, DisplayData, FormattedDate, Row } from '@elotech/components';
import { FunctionUtils } from 'itbi-common/utils';
import React from 'react';
type Props = {
  loteLaudo: any;
};

const LoteLaudoViewInformacao: React.FC<Props> = props => {
  return (
    <>
      <div className="display-data border small mb-xs">
        <Row>
          <Col sm={3}>
            <DisplayData title={'Data Lançamento'}>
              {
                <FormattedDate
                  value={props.loteLaudo.dataLancamento}
                  timeZone={'UTC'}
                />
              }
            </DisplayData>
          </Col>
          <Col sm={3}>
            <DisplayData title={'Número do Pedido'}>
              {props.loteLaudo.numero}
            </DisplayData>
          </Col>
          <Col sm={3}>
            <DisplayData title={'Situação'}>
              {props.loteLaudo.situacaoLaudo.descricao}
            </DisplayData>
          </Col>
          {props.loteLaudo.situacaoLaudo.nome !== 'INDEFERIDO' ||
          (props.loteLaudo.situacaoLaudo.nome === 'INDEFERIDO' &&
            !!props.loteLaudo.pago) ? (
            <Col sm={3}>
              <DisplayData title={'Pagamento'}>
                {FunctionUtils.getLabelPagamentoLaudo(props.loteLaudo)}
              </DisplayData>
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col sm={6}>
            <DisplayData title={'Requerente'}>
              {props.loteLaudo.requerente?.nome ??
                props.loteLaudo.requerenteNome}
            </DisplayData>
          </Col>
          {props.loteLaudo.requerente?.procurador && (
            <Col sm={4}>
              <DisplayData title={'Tipo de Procurador'}>
                {FunctionUtils.getLabelTipoProcurador(
                  props.loteLaudo.requerente?.tipoProcurador
                )}
              </DisplayData>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default LoteLaudoViewInformacao;
