import PropTypes from 'prop-types';
import React from 'react';

import IdentificacaoForm from './IdentificacaoForm';

const IdentificacaoStep = ({
  compradores,
  vendedores,
  anuentes,
  proprietarios,
  onEdit,
  hasAnuentes,
  onDelete,
  vinculos,
  onViewPessoa,
  onSetPrincipal,
  validaRgComprador,
  origemServidor
}) => {
  return (
    <React.Fragment>
      <IdentificacaoForm
        title="Comprador"
        list={compradores}
        type="COMPRADOR"
        onEdit={onEdit('compradores')}
        onDelete={onDelete('compradores')}
        vendedores={vendedores}
        compradores={compradores}
        vinculos={vinculos}
        onViewPessoa={onViewPessoa}
        validaRgComprador={validaRgComprador}
        origemServidor={origemServidor}
      />
      <IdentificacaoForm
        title="Vendedor"
        list={vendedores}
        type="VENDEDOR"
        onEdit={onEdit('vendedores')}
        onDelete={onDelete('vendedores')}
        compradores={compradores}
        vendedores={vendedores}
        vinculos={vinculos}
        onViewPessoa={onViewPessoa}
        origemServidor={origemServidor}
      />
      {hasAnuentes && (
        <IdentificacaoForm
          title="Anuente/Cedente"
          list={anuentes}
          type="ANUENTE"
          onEdit={onEdit('anuentes')}
          onDelete={onDelete('anuentes')}
          vendedores={vendedores}
          compradores={compradores}
          vinculos={vinculos}
          onViewPessoa={onViewPessoa}
          origemServidor={origemServidor}
        />
      )}
      <IdentificacaoForm
        title="Proprietário"
        list={proprietarios}
        type="PROPRIETARIO"
        onEdit={onEdit('proprietarios')}
        onDelete={onDelete('proprietarios')}
        vinculos={vinculos}
        onViewPessoa={onViewPessoa}
        onSetPrincipal={onSetPrincipal}
      />
    </React.Fragment>
  );
};

IdentificacaoStep.propTypes = {
  compradores: PropTypes.array.isRequired,
  vendedores: PropTypes.array.isRequired,
  anuentes: PropTypes.array.isRequired,
  proprietarios: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  hasAnuentes: PropTypes.bool,
  onSetPrincipal: PropTypes.func,
  validaRgComprador: PropTypes.bool,
  origemServidor: PropTypes.bool
};

IdentificacaoStep.defaultProps = {
  hasAnuentes: false,
  validaRgComprador: true,
  onSetPrincipal: () => {},
  origemServidor: false
};

export default IdentificacaoStep;
