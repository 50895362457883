import {
  Alert,
  Container,
  FAB,
  FormattedCurrency,
  FormattedDate,
  Loading,
  NotificationActions,
  Panel,
  SearchFilter,
  SearchPagination,
  Table,
  withAuth
} from '@elotech/components';
import {
  DeclaracaoService,
  PrintService,
  UserService,
  withService
} from 'itbi-common/service';
import * as ParametroService from 'itbi-common/service/ParametroService';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Roles } from '../../roles';
import DeclaracaoUtils from '../../utils/DeclaracaoUtils';
import { renderActionButtons } from './DeclacacaoActions';
import { getFields } from './SearchFieldsDeclaracao';

class DeclaracaoItbiListPage extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object,
    declaracaoService: PropTypes.shape({
      loadDeclaracoesItbi: PropTypes.func.isRequired,
      notificarCidadao: PropTypes.func.isRequired,
      transferir: PropTypes.func.isRequired,
      cancelar: PropTypes.func.isRequired,
      togglePrioritario: PropTypes.func.isRequired
    }).isRequired,
    auth: PropTypes.shape({
      hasRole: PropTypes.func.isRequired
    }).isRequired
  };
  state = {
    declaracoesItbiPage: undefined,
    searchParams: '',
    loadingDeclaracoes: false,
    loading: false
  };

  constructor(props) {
    super(props);
    this.fields = getFields(UserService);
  }

  componentDidMount() {
    ParametroService.loadTiposItbi()
      .then(response => response.data)
      .then(tiposItbi =>
        tiposItbi.map(tipoItbi => ({
          name: tipoItbi.id,
          descricao: tipoItbi.descricao
        }))
      )
      .then(options => {
        this.fields = getFields(UserService, options);
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao carregar os Tipos de ITBI' }, error);
      });
  }

  loadDeclaracoesItbi = (searchParams, page, sort) => {
    this.setState({ loadingDeclaracoes: true }, () => {
      this.props.declaracaoService
        .loadDeclaracoesItbi(searchParams, page, sort)
        .then(response => {
          this.setState({
            declaracoesItbiPage: response.data,
            searchParams
          });
        })
        .catch(error => {
          Alert.error({ title: 'Erro ao carregar declarações de ITBI' }, error);
        })
        .finally(() => {
          this.setState({ loadingDeclaracoes: false });
        });
    });
  };

  searchWithPage = page => {
    this.loadDeclaracoesItbi(this.state.searchParams, page);
  };

  sortSearch = sort => {
    this.loadDeclaracoesItbi(
      this.state.searchParams,
      this.state.pagination,
      sort
    );
  };

  onView = declaracao => {
    this.props.history.push(`/declaracoes-itbi/${declaracao.id}/resumo`);
  };

  onEdit = declaracao => {
    this.props.history.push(`/declaracoes-itbi/${declaracao.id}`);
  };

  onGenerateDebito = declaracao => {
    this.props.history.push(
      `/declaracoes-itbi/${declaracao.id}/confirmacao-gerar-debito`
    );
  };

  onGenerateBoleto = declaracao => {
    const id = declaracao.id;
    this.setState({ loadingDeclaracoes: true });

    this.props.declaracaoService
      .gerarBoleto(id)
      .then(response => {
        window.open(response.data.urlBoleto, '_blank');
        this.setState({ loadingDeclaracoes: false });
      })
      .catch(error => {
        Alert.error({ title: 'Ocorreu um erro ao gerar o boleto ' }, error);
        this.setState({ loadingDeclaracoes: false });
      });
  };

  onTransferir = declaracao => {
    DeclaracaoUtils.transferir(
      () => this.props.declaracaoService.transferir(declaracao.id),
      state => this.setState(state)
    ).then(() => {
      const { size, number, totalPages } = this.state.declaracoesItbiPage;
      this.searchWithPage({
        size: size,
        page: number,
        totalPages: totalPages
      });
    });
  };

  onTransferirSemPagamento = declaracao => {
    DeclaracaoUtils.transferir(
      () => this.props.declaracaoService.transferirSemPagamento(declaracao.id),
      state => this.setState(state)
    ).then(() => {
      const { size, number, totalPages } = this.state.declaracoesItbiPage;
      this.searchWithPage({
        size: size,
        page: number,
        totalPages: totalPages
      });
    });
  };

  onReverse = declaracao => {
    DeclaracaoUtils.estornar(
      motivo => this.props.declaracaoService.cancelar(declaracao.id, motivo),
      state => this.setState(state)
    ).then(() => this.props.history.push(`/declaracoes-itbi/${declaracao.id}`));
  };

  motivoCancelamentoValidator = value => {
    return new Promise(resolve => {
      if (value) {
        resolve();
      } else {
        resolve('Descreva o motivo do Cancelamento!');
      }
    });
  };

  onCancelar = declaracao => {
    DeclaracaoUtils.cancelar(
      motivo => this.props.declaracaoService.cancelar(declaracao.id, motivo),
      state => this.setState(state),
      this.motivoCancelamentoValidator
    ).then(result => {
      if (result && result.value) {
        this.props.declaracaoService
          .indeferir(declaracao.id, { motivo: result.value })
          .then(() => {
            const { size, number, totalPages } = this.state.declaracoesItbiPage;
            this.searchWithPage({
              size: size,
              page: number,
              totalPages: totalPages
            });
          });
      }
    });
  };

  onNotify = declaracao => {
    DeclaracaoUtils.notificar(
      () => this.props.declaracaoService.notificarCidadao(declaracao.id),
      state => this.setState(state)
    ).then(() => {
      const { size, number, totalPages } = this.state.declaracoesItbiPage;
      this.searchWithPage({
        size: size,
        page: number,
        totalPages: totalPages
      });
    });
  };

  onPrintCertidaoIsencao = declaracao => {
    return DeclaracaoUtils.printCertidaoIsencao(
      DeclaracaoService.imprimirDocumentoIsencao,
      declaracao,
      state => this.setState(state)
    );
  };

  motivoPrioritarioValidator = value => {
    return new Promise(resolve => {
      if (value) {
        resolve();
      } else {
        resolve('Descreva o motivo da priorização!');
      }
    });
  };

  AlertQuestion = prioritario => {
    var result = undefined;
    if (prioritario && prioritario === true) {
      result = Alert.question({
        title: 'Deseja cancelar esta priorização?',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        allowOutsideClick: true
      });
    } else {
      result = Alert.question({
        title: 'Qual o motivo da priorização?',
        input: 'textarea',
        inputPlaceholder: 'Descreva o motivo da priorização',
        inputValidator: this.motivoPrioritarioValidator,
        confirmButtonText: 'Priorizar',
        cancelButtonText: 'Cancelar'
      });
    }

    return result;
  };

  onFavorite = async declaracao => {
    const { showNotification, declaracaoService } = this.props;

    const result = await this.AlertQuestion(declaracao.prioritario);

    if (result.value) {
      this.setState({ loadingDeclaracoes: true });

      if (result.value === true) {
        result.value = undefined;
      }

      declaracaoService
        .togglePrioritario(declaracao.id, { motivo: result.value })
        .then(async () => {
          this.setState({ loadingDeclaracoes: false });
          await showNotification({
            level: 'success',
            message: 'Prioridade da declaração alterada com sucesso.'
          });
          const { size, number, totalPages } = this.state.declaracoesItbiPage;
          this.searchWithPage({
            size: size,
            page: number,
            totalPages: totalPages
          });
        })
        .catch(error => {
          this.setState({ loadingDeclaracoes: false });
          Alert.error({ title: 'Falha ao priorizar declaração!' }, error);
        });
    }
  };

  onViewFavorite = declaracao => {
    Alert.info({
      title: 'Motivo da Priorização',
      text: declaracao.motivoPrioritario
    });
  };

  onTransferirResponsavel = declaracao => {
    this.props.history.push(`/declaracoes-itbi/${declaracao.id}/transferencia`);
  };

  onNew = () => {
    this.props.history.push('/declaracoes-itbi/novo');
  };

  onEditRascunho = declaracao => {
    this.props.history.push(`/declaracoes-itbi/${declaracao.id}/edit`);
  };

  onRemoveRascunho = declaracao => {
    Alert.question({
      title: 'Deseja remover este rascunho?',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: true
    }).then(result => {
      if (result.value) {
        const { declaracaoService, showNotification } = this.props;

        this.setState({ loadingDeclaracoes: true });

        declaracaoService
          .remove(declaracao.id)
          .then(() => {
            showNotification({
              level: 'success',
              message: 'Rascunho removido com sucesso.'
            });
            const { size, number, totalPages } = this.state.declaracoesItbiPage;
            this.searchWithPage({
              size: size,
              page: number,
              totalPages: totalPages
            });
          })
          .catch(error => {
            Alert.error({ title: 'Falha ao remover o rascunho!' }, error);
          })
          .finally(() => {
            this.setState({ loadingDeclaracoes: false });
          });
      }
    });
  };

  handleFieldsToPrint = options => {
    const fieldsByName = [
      ['data', 'dataLancamento'],
      ['situacaoItbi', 'situacaoITBI'],
      ['responsavel', 'responsavel.nome']
    ];
    for (const [key, value] of fieldsByName) {
      options.fields = options.fields.replace(key, value);
    }
    return options;
  };

  imprimir = options => {
    const { searchParams } = this.state;

    this.setState({ loading: true });
    PrintService.print('/declaracoes-itbi/report', searchParams, {
      ...this.handleFieldsToPrint(options),
      sumFields:
        'valorTransacao,valorFinanciado,valorTerritorialCalculado,valorTransacaoAvaliacao,valorFinanciadoAvaliacao,valorAvaliadoTotal,valorTransacaoItbi,valorFinanciadoItbi,valorImpostoTotal',
      sort: DeclaracaoService.defaultSort.sort
    })
      .catch(error => {
        Alert.error({ title: 'Falha ao imprimir as declarações!' }, error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onAnalizeContestacao = declaracao => {
    this.props.history.push(
      `/declaracoes-itbi/${declaracao.id}/analisar-contestacao`
    );
  };

  render() {
    const { declaracoesItbiPage, loadingDeclaracoes, loading } = this.state;
    const { auth } = this.props;

    const actions = {
      onView: this.onView,
      onEdit: this.onEdit,
      onGenerateDebito: this.onGenerateDebito,
      onGenerateBoleto: this.onGenerateBoleto,
      onTransferir: this.onTransferir,
      onTransferirSemPagamento: this.onTransferirSemPagamento,
      onReverse: this.onReverse,
      onCancelar: this.onCancelar,
      onNotify: this.onNotify,
      onFavorite: this.onFavorite,
      onViewFavorite: this.onViewFavorite,
      onTransferirResponsavel: this.onTransferirResponsavel,
      onEditRascunho: this.onEditRascunho,
      onRemoveRascunho: this.onRemoveRascunho,
      onPrintCertidaoIsencao: this.onPrintCertidaoIsencao,
      onAnalizeContestacao: this.onAnalizeContestacao
    };

    return (
      <Container title="Declarações ITBI" icon="file-signature">
        <Loading loading={loadingDeclaracoes || loading} />
        <Panel isTable scrollHorizontal>
          <SearchFilter
            fields={this.fields}
            search={this.loadDeclaracoesItbi}
          />
          <Table
            values={declaracoesItbiPage ? declaracoesItbiPage.content : []}
            highlight={item =>
              item.prioritario && auth.hasRole(Roles.acesso_prioritario.name)
            }
            columnSelector
            onPrint={this.imprimir}
            scrollHorizontal
            sortOnHeaderColumnClick={this.sortSearch}
          >
            <Table.Column
              header="Tipo ITBI"
              name="tipoItbi.descricao"
              sortable
              value={item => item.tipoItbi.descricao}
            />
            <Table.Column
              header="Data Lançamento"
              name="data"
              sortable
              value={item => (
                <FormattedDate value={item.dataLancamento} timeZone={'UTC'} />
              )}
            />
            <Table.Column
              header="Número do Pedido"
              name="numero"
              sortable
              align="right"
              value={item => item.numero || 'Não gerado'}
            />
            <Table.Column
              header="Código / Ano ITBI"
              name="codigoItbi,exercicio"
              sortable
              align="center"
              value={item => `${
                item.codigoItbi ? item.codigoItbi : 'Não informado'
              } /
              ${item.exercicio ? item.exercicio : 'Não informado'}`}
            />
            <Table.Column
              header="Requerente"
              name="requerenteNome"
              sortable
              value={item =>
                item.requerenteNome || (item.requerente && item.requerente.nome)
              }
            />
            <Table.Column
              header="Responsável"
              name="responsavel"
              sortable
              value={item => item.responsavel && item.responsavel.nome}
            />
            <Table.Column
              header="Situação"
              name="situacaoItbi"
              sortable
              value={item => item.situacaoITBI && item.situacaoITBI.descricao}
            />
            <Table.Column
              header="Valor Declarado Financiado"
              name="valorFinanciado"
              sortable
              defaultHidden
              align="right"
              value={item => <FormattedCurrency value={item.valorFinanciado} />}
            />
            <Table.Column
              header="Valor Declarado"
              name="valorTransacao"
              sortable
              align="right"
              value={item => <FormattedCurrency value={item.valorTransacao} />}
            />
            <Table.Column
              header="Valor da Planta"
              name="valorTerritorialCalculado"
              sortable
              align="right"
              value={item => (
                <FormattedCurrency value={item.valorTerritorialCalculado} />
              )}
            />

            <Table.Column
              header="Valor Avaliado Normal"
              name="valorTransacaoAvaliacao"
              sortable
              defaultHidden
              align="right"
              value={item => (
                <FormattedCurrency value={item.valorTransacaoAvaliacao} />
              )}
            />
            <Table.Column
              header="Valor Avaliado Financiado"
              name="valorFinanciadoAvaliacao"
              sortable
              defaultHidden
              align="right"
              value={item => (
                <FormattedCurrency value={item.valorFinanciadoAvaliacao} />
              )}
            />
            <Table.Column
              header="Valor Avaliado"
              name="valorAvaliadoTotal"
              align="right"
              value={item => (
                <FormattedCurrency value={item.valorAvaliadoTotal} />
              )}
            />
            <Table.Column
              header="Alíquota"
              name="aliquotaTransacao"
              sortable
              defaultHidden
              align="right"
              value={item => (
                <FormattedCurrency value={item.aliquotaTransacao} />
              )}
            />
            <Table.Column
              header="Alíquota Financiado"
              name="aliquotaFinanciado"
              sortable
              defaultHidden
              align="right"
              value={item => (
                <FormattedCurrency value={item.aliquotaFinanciado} />
              )}
            />
            <Table.Column
              header="Valor Imposto Normal"
              name="valorTransacaoItbi"
              sortable
              defaultHidden
              align="right"
              value={item => (
                <FormattedCurrency value={item.valorTransacaoItbi} />
              )}
            />
            <Table.Column
              header="Valor Imposto sobre Financiado"
              name="valorFinanciadoItbi"
              sortable
              defaultHidden
              align="right"
              value={item => (
                <FormattedCurrency value={item.valorFinanciadoItbi} />
              )}
            />
            <Table.Column
              header="Valor Imposto"
              name="valorImpostoTotal"
              align="right"
              value={item => (
                <FormattedCurrency value={item.valorImpostoTotal} />
              )}
            />
            <Table.Column
              header="Prioritário"
              name="prioritario"
              sortable
              defaultHidden
              value={item => (item.prioritario ? 'Sim' : 'Não')}
            />
            <Table.Column
              header="Origem"
              name="origemServidor"
              sortable
              defaultHidden
              value={item => (item.origemServidor ? 'Prefeitura' : 'On-line')}
            />
            <Table.Column
              header=""
              className="has-btn-actions"
              value={item =>
                renderActionButtons(
                  item,
                  actions,
                  { currentUser: auth.userProfile },
                  auth
                )
              }
            />
          </Table>
          {declaracoesItbiPage && (
            <SearchPagination
              page={declaracoesItbiPage}
              searchWithPage={this.searchWithPage}
              showTotalRegistro={true}
            />
          )}
        </Panel>
        <div className="btn-save">
          <FAB icon="plus" title="Novo" onClick={this.onNew} />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUser
});

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ComponentWithService = withService({
  declaracaoService: DeclaracaoService
})(DeclaracaoItbiListPage);
const ComponentWithAuth = withAuth(ComponentWithService);

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithAuth);

export { ConnectedComponent as default, DeclaracaoItbiListPage };
