import { Checkbox, Col, Row, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const DadosTransferenciaSection = ({
  tipoItbi,
  situacao,
  automatica,
  onChange
}) => {
  return (
    <div>
      <SectionTitle>Dados Transferência</SectionTitle>
      <Row>
        <Col md={3}>
          <Checkbox
            className="form-group"
            name="automatica"
            id="automatica"
            checked={automatica}
            onChange={onChange}
            label="Transferência Automática"
            disabled={false}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <label htmlFor="tipoItbiSelect">Tipo ITBI</label>
          <input
            id="tipoItbiSelect"
            name="tipoItbi"
            value={tipoItbi.descricao}
            disabled
          />
        </Col>
        <Col md={4}>
          <label htmlFor="situacaoItbiSelect">Situação</label>
          <input
            id="situacaoItbiSelect"
            name="situacao"
            value={situacao.descricao}
            disabled
          />
        </Col>
      </Row>
    </div>
  );
};

DadosTransferenciaSection.propTypes = {
  tipoItbi: PropTypes.object.isRequired,
  situacao: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DadosTransferenciaSection;
