import {
  ActionButton,
  ActionsGroup,
  Alert,
  Col,
  ErrorText,
  FormattedDate,
  Loading,
  Panel,
  Row,
  SearchPagination,
  Table,
  showNotification,
  usePagedQuery
} from '@elotech/components';
import { LaudoService, UploadService } from 'itbi-common/service';
import { LaudoAnexo } from 'itbi-common/type';
import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  laudo: LaudoAnexo;
};

type Arquivo = {
  arquivo: any;
  name: string;
  erro?: string;
};

const LaudoAnexosArquivosSection: React.FC<Props> = props => {
  const [loadingActions, setLoadingActions] = useState<boolean>(false);
  const [arquivoErrorMessage, setArquivoErrorMessage] = useState<string>();
  const [arquivo, setArquivo] = useState<Arquivo>();

  const find = useCallback(
    (search, page) => LaudoService.findArquivosLaudoAnexo(props.laudo.id, page),
    [props.laudo]
  );

  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<LaudoAnexo>({
    search: find,
    onError: useCallback(error => {
      Alert.error({ title: 'Erro ao buscar arquivos do laudo.' }, error);
    }, [])
  });

  useEffect(() => {
    doSearch();
  }, [doSearch]);

  const onAdd = () => {
    const invalidFile =
      !arquivo || arquivo.arquivo === undefined || arquivo.erro !== undefined;

    if (invalidFile) {
      setArquivoErrorMessage('O Arquivo é obrigatório.');
      return showNotification({
        level: 'warning',
        message: 'Verifique os erros antes de adicionar o arquivo.'
      });
    }

    if (arquivo) {
      setLoadingActions(true);
      UploadService.getUrlUploadArquivoLaudo(props.laudo.id, arquivo.name)
        .then(urlResult => {
          return UploadService.uploadFileS3(
            urlResult.data.url,
            arquivo.arquivo
          );
        })
        .then(uploadResponse => {
          const url = uploadResponse.config.url?.split('?')[0];
          const nameFile = arquivo.name;
          const fileInformatio = { nameFile, url };
          return LaudoService.addAnexoLaudo(props.laudo.id, fileInformatio);
        })
        .then(() => {
          setArquivo({ arquivo: undefined, name: '', erro: undefined });
          doSearch();
        })
        .catch(error => {
          Alert.error(
            {
              title: 'Erro ao fazer upload do arquivo'
            },
            error
          );
        })
        .finally(() => setLoadingActions(false));
    }
  };

  const onDownload = (arquivo: string) => {
    setLoadingActions(true);
    return UploadService.getUrlDownloadArquivoLaudo(props.laudo.id, arquivo)
      .then(urlResult => UploadService.downloadFileS3(urlResult.data.url))
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao fazer download do arquivo'
          },
          error
        );
      })
      .finally(() => setLoadingActions(false));
  };

  const onDelete = (idLaudoAnexo: string) => {
    setLoadingActions(true);
    return LaudoService.removeArquivoLaudoAnexo(props.laudo.id, idLaudoAnexo)
      .then(() => {
        doSearch();
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao remover arquivo'
          },
          error
        );
      })
      .finally(() => setLoadingActions(false));
  };

  const onUploadFile = (event: any) => {
    const { files } = event.target;

    const MAX_FILE_SIZE = 50_000_000;
    const isValid = !(files && files[0] && files[0].size > MAX_FILE_SIZE);

    const nameFile = files && files.length > 0 ? files[0].name : '';

    const mensagem = isValid ? undefined : 'Arquivo maior que 50Mb';

    setArquivoErrorMessage(mensagem);
    setArquivo({
      arquivo: files[0],
      name: nameFile,
      erro: mensagem
    });
  };

  return (
    <>
      <Loading loading={loading || loadingActions} />
      <Row>
        <Col sm={6}>
          <div className="file-uploader">
            <input
              id="upload-file-input"
              type="file"
              className={`file-uploader-input${
                arquivoErrorMessage ? ' error' : ''
              }`}
              title="Clique ou arraste para anexar"
              onChange={onUploadFile}
            />

            <label
              htmlFor="upload-file-input"
              className="input"
              data-title={
                arquivo && arquivo.arquivo !== undefined
                  ? arquivo.name
                  : 'Clique ou arraste para anexar'
              }
            />

            <label htmlFor="upload-file-input" className="file-uploader-icon" />

            {arquivoErrorMessage && (
              <ErrorText>{arquivoErrorMessage}</ErrorText>
            )}
          </div>
        </Col>
        <Col sm={1}>
          <button
            data-test-id="addButton"
            type="button"
            className="btn module-color"
            onClick={onAdd}
          >
            Adicionar
          </button>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Panel isTable insidePanel>
            <Table
              values={values ?? []}
              keyExtractor={(item: LaudoAnexo) => `${item.id}`}
            >
              <Table.Column<LaudoAnexo>
                header="Código"
                value={item => item.nomeArquivo}
              ></Table.Column>
              <Table.Column<LaudoAnexo>
                header="Data"
                value={item => <FormattedDate value={item.data} />}
              ></Table.Column>
              <Table.Column<LaudoAnexo>
                header="Usuário"
                value={item => item.usuarioNome}
              ></Table.Column>
              <Table.Column<LaudoAnexo>
                header=""
                name="actions-buttons"
                value={item => (
                  <ActionsGroup>
                    <ActionButton
                      key="download"
                      icon="file-download"
                      label="Download"
                      onClick={() => onDownload(item.nomeArquivo)}
                    />
                    <ActionButton
                      key="remove"
                      icon="trash-alt"
                      label="Remover"
                      onClick={() => onDelete(item.id)}
                    />
                  </ActionsGroup>
                )}
              ></Table.Column>
            </Table>
            {pagination && (
              <SearchPagination
                page={pagination}
                searchWithPage={doPagedSearch}
              />
            )}
          </Panel>
        </Col>
      </Row>
    </>
  );
};

export default LaudoAnexosArquivosSection;
