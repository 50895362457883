import {
  CollapseRow,
  DiffFunctions,
  DiffRow,
  DiffTable
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

import { fieldNames, ignoreFields, nestedFields } from './Fields';

const dictionary = entity => path => {
  const unifiedPath = path.join('_').replace(/(.*?)_\d*?_(.*?)/, '$1_$2');

  if (ignoreFields.includes(unifiedPath)) {
    return undefined;
  }

  const index = path.find(pathSlice => typeof pathSlice === 'number');

  const prefix =
    path[0] === 'propriedades' && typeof path[1] === 'number'
      ? `Imóvel ${entity.propriedades[index].cadastro} - `
      : '';

  return `${prefix}${DiffFunctions.getFieldName(unifiedPath, fieldNames)}`;
};

const objectParser = (value, path) => {
  switch (path.join('_')) {
    case 'responsavel':
    case 'requerente':
      return value.nome;
    case 'situacaoLaudo':
      return value.descricao;
    default:
      return JSON.stringify(value);
  }
};

const revisionTypes = {
  ADD: 'Adicionado',
  MOD: 'Modificado',
  DEL: 'Removido'
};

const RowHeader = ({ revision, revisionType }) => {
  return (
    <React.Fragment>
      <td data-header="Data">
        <FormattedDate value={revision.revisionDate} />{' '}
        <FormattedTime value={revision.revisionDate} />
      </td>
      <td data-header="Nome do Responsável">{revision.userName}</td>
      <td>{revisionTypes[revisionType]}</td>
    </React.Fragment>
  );
};

const LoteLaudoHistoryRow = ({ revision }) => {
  if (revision.diff.length === 0) {
    return null;
  }

  const isNested = path => nestedFields.includes(path.join('_'));

  return (
    <CollapseRow
      className="reduced"
      buttonLabel="Visualizar Modificações"
      renderHeader={() => (
        <RowHeader
          revision={revision.revision}
          revisionType={revision.revisionType}
        />
      )}
    >
      <DiffTable>
        {revision.diff.map((diff, index) => (
          <DiffRow
            key={index}
            diff={diff}
            nameDictionary={dictionary(revision.entity)}
            objectParser={objectParser}
            isNested={isNested}
          />
        ))}
      </DiffTable>
    </CollapseRow>
  );
};

LoteLaudoHistoryRow.propTypes = {
  revision: PropTypes.object.isRequired
};

export default LoteLaudoHistoryRow;
