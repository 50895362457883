import { PagedResponse } from '@elotech/components';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { ParamParcelamento } from 'itbi-servidor/src/types';

import { ServiceUtils } from '../utils';

const defaultSort = { sort: 'id' };
const defaultFields = {
  fields: 'id,descricao,tipo,disponivelCidadao,ativo,termoConfissaoDivida'
};

export const findByRsql = (
  searchParams: string,
  page: object
): AxiosPromise<PagedResponse<ParamParcelamento>> =>
  axios.get(
    `/api/paramparcelamento?${ServiceUtils.buildSearchParams(searchParams)}`,
    {
      params: { ...defaultSort, ...defaultFields, ...page }
    }
  );

export const findById = (id: string): AxiosPromise<ParamParcelamento> =>
  axios.get(`/api/paramparcelamento/${id}`, {
    params: { ...defaultFields }
  });

export const alterar = (data: object) =>
  axios.put('/api/paramparcelamento', data);
