import React from 'react';

import { IFrame } from './IFrame';

type Props = {};

const NotificacoesFrame: React.FC<Props> = () => {
  return (
    <>
      <IFrame path={`/app-notifica/notifications`} />
    </>
  );
};

export default NotificacoesFrame;
