import {
  Alert,
  Col,
  Container,
  Icon,
  Loading,
  NotificationActions,
  Row,
  withAuth
} from '@elotech/components';
import {
  DeclaracaoService,
  LaudoService,
  ParametroService,
  PedidoService,
  withService
} from 'itbi-common/service';
import { Constantes } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import DeclaracaoUtils from '../../utils/DeclaracaoUtils';
import LoteLaudoUtils from '../../utils/LoteLaudoUtils';
import MeusPedidosTable from './MeusPedidosTable';
import PedidosPendentesTable from './PedidosPendentesTable';
import PedidosTransferenciaTable from './PedidosTransferenciaTable';

class Home extends React.Component {
  componentDidMount() {
    this.getData();
  }

  state = {
    meusPedidos: {
      content: [],
      total: 0
    },
    pedidosPendentes: {
      content: [],
      total: 0
    },
    pedidosTransferencia: {
      content: [],
      total: 0
    },
    loading: {
      submittingLaudo: false,
      pedidosPendentes: false,
      pedidosTransferencia: false
    },
    error: {
      meusPedidos: undefined,
      pedidosPendentes: undefined,
      pedidosTransferencia: undefined,
      validaPedidoDuplicado: undefined
    },
    submitting: false,
    validaPedidoDuplicado: false
  };

  getData = () => {
    this.getValidaPedidoDuplicado();
    this.getMeusPedidos();
    this.getPedidosPendentes();
    this.getPedidosTransferencia();
  };

  getValidaPedidoDuplicado = () => {
    this.props.parametroService
      .loadAllParametros()
      .then(response =>
        this.setState(_ => ({
          validaPedidoDuplicado: response.data.validaPedidoDuplicado
        }))
      )
      .catch(error =>
        this.setState(state => ({
          error: {
            ...state.error,
            validaPedidoDuplicado: {
              cause: error,
              message:
                'Não foi possível carregar o parâmetro de validação de pedido duplicado'
            }
          }
        }))
      );
  };

  getMeusPedidosSuccess = response => {
    this.setState(state => ({
      meusPedidos: {
        content: response.data.content,
        total: response.data.totalElements
      },
      loading: { ...state.loading, submittingLaudo: false }
    }));
  };

  getMeusPedidos = () => {
    this.setState(
      state => ({
        loading: { ...state.loading, submittingLaudo: true },
        error: {
          ...state.error,
          meusPedidos: undefined
        }
      }),
      () => {
        this.props.pedidoService
          .getMeusPedidos()
          .then(this.getMeusPedidosSuccess)
          .catch(error =>
            this.setState(state => ({
              meusPedidos: {
                content: [],
                total: 0
              },
              loading: { ...state.loading, submittingLaudo: false },
              error: {
                ...state.error,
                meusPedidos: {
                  cause: error,
                  message: 'Não foi possível carregar os pedidos...'
                }
              }
            }))
          );
      }
    );
  };

  getPedidosPendentesSuccess = response => {
    this.setState(state => ({
      pedidosPendentes: {
        content: response.data.content,
        total: response.data.totalElements
      },
      loading: { ...state.loading, pedidosPendentes: false }
    }));
  };

  getPedidosPendentes = () => {
    this.setState(
      state => ({
        loading: { ...state.loading, pedidosPendentes: true },
        error: {
          ...state.error,
          pedidosPendentes: undefined
        }
      }),
      () => {
        this.props.pedidoService
          .getPedidosPendentes()
          .then(this.getPedidosPendentesSuccess)
          .catch(error =>
            this.setState(state => ({
              pedidosPendentes: {
                content: [],
                total: 0
              },
              loading: { ...state.loading, pedidosPendentes: false },
              error: {
                ...state.error,
                pedidosPendentes: {
                  cause: error,
                  message: 'Não foi possível carregar os pedidos...'
                }
              }
            }))
          );
      }
    );
  };

  getPedidosTransferenciaSuccess = response => {
    this.setState(state => ({
      pedidosTransferencia: {
        content: response.data.content,
        total: response.data.totalElements
      },
      loading: { ...state.loading, pedidosTransferencia: false }
    }));
  };

  getPedidosTransferencia = () => {
    this.setState(state => ({
      loading: { ...state.loading, pedidosTransferencia: true },
      error: {
        ...state.error,
        pedidosTransferencia: undefined
      }
    }));

    this.props.pedidoService
      .getPedidosTransferencia()
      .then(this.getPedidosTransferenciaSuccess)
      .catch(error =>
        this.setState(state => ({
          pedidosTransferencia: {
            content: [],
            total: 0
          },
          loading: { ...state.loading, pedidosTransferencia: false },
          error: {
            ...state.error,
            pedidosTransferencia: {
              cause: error,
              message: 'Não foi possível carregar os pedidos...'
            }
          }
        }))
      );
  };

  onViewPedido = pedido => {
    if (pedido.tipo === 'ITBI') {
      this.props.history.push(`/declaracoes-itbi/${pedido.id}/resumo`);
    } else {
      this.props.history.push(`/laudos/${pedido.id}/resumo`);
    }
  };

  onGenerateDebito = pedido => {
    if (pedido.tipo === 'ITBI') {
      this.props.history.push(
        `/declaracoes-itbi/${pedido.id}/confirmacao-gerar-debito`
      );
    }
  };

  onGenerateBoleto = pedido => {
    if (pedido.tipo === 'ITBI') {
      this.setState(state => ({
        loading: { ...state.loading, submittingLaudo: true }
      }));

      DeclaracaoService.gerarBoleto(pedido.id)
        .then(response => {
          window.open(response.data.urlBoleto, '_blank');
        })
        .catch(error => {
          Alert.error({ title: 'Ocorreu um erro ao gerar o boleto ' }, error);
        })
        .finally(() => {
          this.setState(state => ({
            loading: { ...state.loading, submittingLaudo: false }
          }));
        });
    }
  };

  onNotifyPedido = pedido => {
    DeclaracaoUtils.notificar(
      () => this.props.declaracaoService.notificarCidadao(pedido.id),
      state => this.setState(state)
    ).then(() => this.getData());
  };

  onEditPedido = pedido => {
    if (pedido.tipo === 'ITBI') {
      this.props.history.push(`/declaracoes-itbi/${pedido.id}`);
    } else {
      this.props.history.push(`/laudos/${pedido.id}`);
    }
  };

  onTransferirPedido = pedido => {
    if (pedido.tipo === 'ITBI') {
      DeclaracaoUtils.transferir(
        () => this.props.declaracaoService.transferir(pedido.id),
        state => this.setState(state)
      ).then(() => this.getPedidosTransferencia());
    }
  };

  onTransferirResponsavel = pedido => {
    if (pedido.tipo === 'ITBI') {
      this.props.history.push(`/declaracoes-itbi/${pedido.id}/transferencia`);
    } else {
      this.props.history.push(`/laudos/${pedido.id}/transferencia`);
    }
  };

  onReversePedido = pedido => {
    if (pedido.tipo === 'ITBI') {
      DeclaracaoUtils.estornar(
        () => this.props.declaracaoService.cancelar(pedido.id),
        state => this.setState(state)
      ).then(() => this.props.history.push(`/declaracoes-itbi/${pedido.id}`));
    }
  };

  onPrintBoleto = pedido => {
    window.open(pedido.urlBoleto, '_blank');
  };

  onInfoRow = diasCorridos => {
    var infoRow = 0;
    if (diasCorridos > Constantes.prazoPedido.notificacao) {
      infoRow = diasCorridos > Constantes.prazoPedido.padrao ? 2 : 1;
    }
    return infoRow;
  };

  onIndeferirLaudo = pedido => {
    const { showNotification, laudoService } = this.props;

    LoteLaudoUtils.onIndeferirLaudo(
      laudoService,
      pedido.id,
      showNotification,
      state => this.setState(state),
      this.getMeusPedidos
    );
  };

  onAnalizeContestacao = pedido => {
    if (pedido.tipo === 'ITBI') {
      this.props.history.push(
        `/declaracoes-itbi/${pedido.id}/analisar-contestacao`
      );
    }
  };

  render() {
    const {
      meusPedidos,
      loading,
      error,
      pedidosPendentes,
      pedidosTransferencia,
      submitting,
      validaPedidoDuplicado
    } = this.state;

    const { auth } = this.props;

    const isLoading =
      loading.submittingLaudo ||
      loading.pedidosPendentes ||
      loading.pedidosTransferencia;

    return (
      <Container
        title="Início"
        icon="home"
        titleRightComponent={
          <button onClick={this.getData}>
            <Icon icon="redo" darkPrimary spin={isLoading} size="lg" />
          </button>
        }
      >
        <Loading loading={submitting} />
        <MeusPedidosTable
          values={meusPedidos.content}
          total={meusPedidos.total}
          loading={loading.submittingLaudo}
          error={error.meusPedidos}
          onGenerateDebito={this.onGenerateDebito}
          onGenerateBoleto={this.onGenerateBoleto}
          onNotify={this.onNotifyPedido}
          onReverse={this.onReversePedido}
          onView={this.onViewPedido}
          onEdit={this.onEditPedido}
          onTransferir={this.onTransferirPedido}
          onPrintBoleto={this.onPrintBoleto}
          onTransferirResponsavel={this.onTransferirResponsavel}
          onInfoRow={this.onInfoRow}
          onIndeferirLaudo={this.onIndeferirLaudo}
          onAnalizeContestacao={this.onAnalizeContestacao}
          hasRole={auth.hasRole}
        />
        <Row>
          <Col md={6}>
            <PedidosPendentesTable
              values={pedidosPendentes.content}
              total={pedidosPendentes.total}
              loading={loading.pedidosPendentes}
              error={error.pedidosPendentes}
              onInfoRow={this.onInfoRow}
              hasRole={auth.hasRole}
              refreshPedidosPendentes={this.getPedidosPendentes}
              validaPedidoDuplicado={validaPedidoDuplicado}
            />
          </Col>
          <Col md={6}>
            <PedidosTransferenciaTable
              values={pedidosTransferencia.content}
              total={pedidosTransferencia.total}
              loading={loading.pedidosTransferencia}
              error={error.pedidosTransferencia}
              onTransferir={this.onTransferirPedido}
              onInfoRow={this.onInfoRow}
              hasRole={auth.hasRole}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

Home.propTypes = {
  declaracaoService: PropTypes.shape({
    notificarCidadao: PropTypes.func.isRequired,
    transferir: PropTypes.func.isRequired,
    cancelar: PropTypes.func.isRequired
  }).isRequired,
  pedidoService: PropTypes.shape({
    getMeusPedidos: PropTypes.func.isRequired,
    getPedidosPendentes: PropTypes.func.isRequired,
    getPedidosTransferencia: PropTypes.func.isRequired
  }).isRequired,
  auth: PropTypes.shape({
    hasRole: PropTypes.func.isRequired
  }).isRequired,
  laudoService: PropTypes.shape({
    indeferir: PropTypes.func.isRequired
  }).isRequired,
  parametroService: PropTypes.shape({
    indeferir: PropTypes.func.isRequired
  }).isRequired
};

const componentWithService = withService({
  pedidoService: PedidoService,
  declaracaoService: DeclaracaoService,
  laudoService: LaudoService,
  parametroService: ParametroService
})(Home);

const ComponentWithAuth = withAuth(componentWithService);

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ConnectedComponent = connect(null, mapDispatchToProps)(ComponentWithAuth);

export { ConnectedComponent as default, Home };
