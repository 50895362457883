import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  FAB,
  Field,
  FormattedDate,
  FormattedDateTime,
  Panel,
  SearchFilter,
  SearchPagination,
  Table,
  useAuth,
  usePagedQuery
} from '@elotech/components';
import { TermoResponsabilidadeService } from 'itbi-common/service';
import React, { useState } from 'react';

import { Roles } from '../../roles';
import { TermoResponsabilidade } from '../../types';

type Props = {};

const fields: Field[] = [
  {
    label: 'Data de Criação',
    name: 'dataCriacao',
    type: 'DATETIME'
  },
  {
    label: 'Data de Início de Validade',
    name: 'dataInicioValidade',
    type: 'DATE'
  },
  {
    label: 'Usuário',
    name: 'usuario',
    type: 'STRING'
  },
  {
    label: 'Conteúdo',
    name: 'conteudo',
    type: 'STRING'
  }
];

const TermoResponsabilidadeListPage: React.FC<Props> = () => {
  const { hasRole } = useAuth();

  const hasCreateAndEditAndDeletePermissions = hasRole(
    Roles.cadastro_termo_responsabilidade.name
  );

  const [loadingDelete, setLoadingDelete] = useState(false);

  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<TermoResponsabilidade>({
    search: TermoResponsabilidadeService.searchTermos,
    onError: error => {
      Alert.error(
        {
          title: `Não foi possível consultar os termos de responsabilidade.`
        },
        error
      );
    }
  });

  const onRemove = (item: TermoResponsabilidade) => {
    Alert.question({
      title: `Deseja realmente remover o termo de responsabilidade?`
    }).then(response => {
      if (response.value) {
        setLoadingDelete(true);
        TermoResponsabilidadeService.remove(item.id!)
          .then(
            () =>
              doPagedSearch({
                size: pagination!.size,
                page: pagination!.number
              }),
            error => {
              Alert.error(
                {
                  title: `Não foi possível remover o termo de responsabilidade`
                },
                error
              );
            }
          )
          .finally(() => setLoadingDelete(false));
      }
    });
  };

  return (
    <Container title="Termos de Responsabilidade" icon="gavel">
      <Panel isTable>
        <SearchFilter fields={fields} search={doSearch} />
        <Table loading={loading || loadingDelete} values={values}>
          <Table.Column<TermoResponsabilidade>
            header="Data de Criação"
            value={item => <FormattedDateTime value={item.dataCriacao} />}
          />
          <Table.Column<TermoResponsabilidade>
            header="Data de Início de Validade"
            value={item => <FormattedDate value={item.dataInicioValidade} />}
          />
          <Table.Column<TermoResponsabilidade>
            header="Usuário"
            value={item => item.usuario}
          />
          <Table.Column<TermoResponsabilidade>
            header="Conteúdo"
            value={item => item.conteudo}
          />
          <Table.Column<TermoResponsabilidade>
            header=""
            value={item =>
              hasCreateAndEditAndDeletePermissions && (
                <ActionsGroup>
                  <ActionButton
                    data-testid="edit-button"
                    key="edit-button"
                    icon="pencil-alt"
                    label="Editar"
                    path={`/termo-responsabilidade/${item.id}`}
                  />
                  <ActionButton
                    key={`delete-${item.id}`}
                    icon="trash-alt"
                    label="Remover"
                    onClick={() => {
                      onRemove(item);
                    }}
                  />
                </ActionsGroup>
              )
            }
          />
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
      {hasCreateAndEditAndDeletePermissions && (
        <div className="btn-save">
          <FAB
            icon="plus"
            title={`Novo Termo`}
            path="/termo-responsabilidade/novo"
          />
        </div>
      )}
    </Container>
  );
};

export default TermoResponsabilidadeListPage;
