import {
  CollapseRow,
  DiffFunctions,
  DiffRow,
  DiffTable
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

import { fieldNames, ignoreFields, nestedFields } from './Fields';

const getPrefix = (entity, path) => {
  const index = path.find(pathSlice => typeof pathSlice === 'number');

  if (typeof path[1] === 'number') {
    switch (path[0]) {
      case 'compradores':
        return `Comprador ${entity.compradores[index].nome} - `;
      case 'vendedores':
        return `Vendedor ${entity.vendedores[index].nome} - `;
      case 'anuentes':
        return `Anuente ${entity.anuentes[index].nome} - `;
      case 'segmentos':
        return `Construção ${entity.segmentos[index].sequencia} - `;
      default:
        return '';
    }
  }

  return '';
};

const dictionary = entity => path => {
  const unifiedPath = path.join('_').replace(/(.*?)_\d*?_(.*?)/, '$1_$2');

  if (ignoreFields.includes(unifiedPath)) {
    return undefined;
  }

  return `${getPrefix(entity, path)}${DiffFunctions.getFieldName(
    unifiedPath,
    fieldNames
  )}`;
};

const objectParser = (value, path) => {
  switch (path.join('_')) {
    case 'responsavel':
    case 'requerente':
      return value.nome;
    case 'anuentes_vinculo':
    case 'compradores_vinculo':
    case 'proprietarios_vinculo':
    case 'situacaoItbi':
    case 'situacaoItbi_situacaoCidadao':
    case 'tipoItbi':
    case 'vendedores_vinculo':
    case 'segmentos_tipoSegmento':
      return value.descricao;
    case 'cadastroImobiliario':
      return value.cadastro;
    case 'cadastroRural':
      return value.cadastroRural;
    case 'boleto':
      return value.url;
    default:
      return JSON.stringify(value);
  }
};

const revisionTypes = {
  ADD: 'Adicionado',
  MOD: 'Modificado',
  DEL: 'Removido'
};

const RowHeader = ({ revision, revisionType }) => {
  return (
    <React.Fragment>
      <td data-header="Data">
        <b className="td-label">Data</b>
        <FormattedDate value={revision.revisionDate} />{' '}
        <FormattedTime value={revision.revisionDate} />
      </td>
      <td data-header="Nome do Responsável">
        <b className="td-label">Nome do Responsável</b>
        {revision.userName}
      </td>
      <td>
        <b className="td-label">Tipo de Alteração</b>
        {revisionTypes[revisionType]}
      </td>
    </React.Fragment>
  );
};

const DeclaracaoItbiHistoryRow = ({ revision }) => {
  if (revision.diff.length === 0) {
    return null;
  }

  const isNested = path => nestedFields.includes(path.join('_'));

  return (
    <CollapseRow
      className="reduced"
      buttonLabel="Visualizar Modificações"
      renderHeader={() => (
        <RowHeader
          revision={revision.revision}
          revisionType={revision.revisionType}
        />
      )}
    >
      <DiffTable>
        {revision.diff.map((diff, index) => (
          <DiffRow
            key={index}
            diff={diff}
            nameDictionary={dictionary(revision.entity)}
            objectParser={objectParser}
            isNested={isNested}
          />
        ))}
      </DiffTable>
    </CollapseRow>
  );
};

DeclaracaoItbiHistoryRow.propTypes = {
  revision: PropTypes.object.isRequired
};

export default DeclaracaoItbiHistoryRow;
