export const rolesOutros = {
  notificar_cidadao: {
    name: 'notificar_cidadao',
    description: 'Permite enviar notificação do pedido de ITBI ou Laudo'
  },
  acessar_sincronizacao_dados: {
    name: 'acessar_sincronizacao_dados',
    description: 'Permite acessar a tela de sincronização de dados (Menu)'
  },
  sincronizar_dados: {
    name: 'sincronizar_dados',
    description: 'Permite sincronizar os dados de integração'
  },
  transferir_responsavel: {
    name: 'transferir_responsavel',
    description:
      'Permite transferir o responsável que está analisando a declaração de ITBI ou Laudo de Avaliação'
  },
  termo_responsabilidade_visualizar: {
    name: 'termo_responsabilidade_visualizar',
    description: 'Permite visualizar os Termos de Responsabilidade'
  },
  cadastro_termo_responsabilidade: {
    name: 'cadastro_termo_responsabilidade',
    description: 'Permite criar, editar e remover um termo de responsabilidade'
  },
  apagar_guias_pagamento_declaracoes_laudos: {
    name: 'apagar_guias_pagamento_declaracoes_laudos',
    description: 'Permite Apagar Guias de Pagamento de Declarações e Laudos'
  },
  analisar_contestacao: {
    name: 'analisar_contestacao',
    description: 'Permite analisar uma declaração de ITBI contestada'
  }
};
