import {
  Container,
  FAB,
  Loading,
  NotificationActions,
  Table,
  withAuth
} from '@elotech/components';
import { TipoItbiService, withService } from 'itbi-common/service';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Roles } from '../../roles';
import TipoItbiActionsButtons from './TipoItbiActionsButtons';

export class TiposItbiListPage extends React.Component {
  state = {
    loadingTiposItbi: false,
    itbi: []
  };

  static propTypes = {
    auth: PropTypes.shape({
      hasRole: PropTypes.func.isRequired
    }).isRequired,
    tipoItbiService: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.getTiposItbi();
  }

  getTiposItbiSuccess = response => {
    this.setState({ itbi: response.data, loadingTiposItbi: false });
  };

  getTiposItbiError = () => {
    this.setState({ loadingTiposItbi: false });
    this.props.showNotification({
      level: 'warning',
      message: 'Não foi possível pesquisar os tipos de ITBI'
    });
  };

  getTiposItbi = () => {
    this.setState({ loadingTiposItbi: true });
    this.props.tipoItbiService
      .getTiposItbi()
      .then(this.getTiposItbiSuccess)
      .catch(this.getTiposItbiError);
  };

  onDelete = tipoItbi => {
    this.setState({ loadingTiposItbi: true });
    this.props.tipoItbiService
      .remove(tipoItbi.id)
      .then(() => {
        this.setState({ loadingTiposItbi: false });
        this.props.showNotification({
          level: 'success',
          message: 'Tipo ITBI removido com sucesso'
        });
        this.getTiposItbi();
      })
      .catch(error => {
        this.setState({ loadingTiposItbi: false });
        this.props.showNotification({
          level: 'error',
          message: error.response.data.message
        });
      });
  };

  onEdit = tipoItbi => {
    this.props.history.push(`/tipos-itbi/${tipoItbi.id}`);
  };

  onViewAlteracoes = itbi => {
    this.props.history.push(`/tipos-itbi/${itbi.id}/historico`);
  };

  onCreate = () => {
    this.props.history.push(`/tipos-itbi/novo`);
  };

  render() {
    const { loadingTiposItbi, itbi } = this.state;
    const { auth } = this.props;
    const actions = {
      onEdit: this.onEdit,
      onDelete: this.onDelete,
      onViewAlteracoes: this.onViewAlteracoes
    };
    return (
      <Container title="Tipos de ITBI" icon="building">
        <Loading loading={loadingTiposItbi} />
        <div className="panel table table-responsive">
          <div className="panel-body">
            <Table values={itbi}>
              <Table.Column
                header="Código"
                value={item => item.codigoItbiAise}
              />
              <Table.Column header="Descrição" value={item => item.descricao} />
              <Table.Column
                header="Visível"
                value={item => (item.visivel ? 'Sim' : 'Não')}
              />
              <Table.Column
                header="Tipo ITBI Aise"
                value={item => item.tipoItbiAise && item.tipoItbiAise.descricao}
              />
              <Table.Column
                header="Parâmetro de ITBI"
                value={item =>
                  item.parametroItbi
                    ? `${item.parametroItbi.id} - ${item.parametroItbi.descricao}`
                    : 'Parâmetro de ITBI não configurado'
                }
              />
              <Table.Column
                header="Totalmente Financiado"
                value={item =>
                  item.permiteTotalmenteFinanciado ? 'Sim' : 'Não'
                }
              />
              <Table.Column
                header=""
                value={item => (
                  <TipoItbiActionsButtons
                    tipoItbi={item}
                    actions={actions}
                    hasRole={auth.hasRole}
                  />
                )}
              />
            </Table>
          </div>
        </div>
        <div className="btn-save">
          {auth.hasRole(Roles.cadastro_tipos_itbi_adicionar.name) && (
            <FAB
              icon="plus"
              iconColor="white"
              title="Novo Tipo de ITBI"
              onClick={this.onCreate}
            />
          )}
        </div>
      </Container>
    );
  }
}

const ComponentWithAuth = withAuth(TiposItbiListPage);
const ComponentWithService = withService({
  tipoItbiService: TipoItbiService
})(ComponentWithAuth);

const ConnectComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(ComponentWithService);

export default ConnectComponent;
