import axios from 'axios';

import ServiceUtils from '../utils/ServiceUtils';

export type PrintOptions = {
  fields: string;
  sumFields?: string;
  maximoRegistros?: Number;
  sort?: object;
};

export const print = (
  resource: string,
  searchParams: string,
  options: PrintOptions
) =>
  axios
    .get(`/api${resource}?${ServiceUtils.buildSearchParams(searchParams)}`, {
      params: { ...options }
    })
    .then(response =>
      URL.createObjectURL(new Blob([response.data], { type: 'text/html' }))
    )
    .then(url => window.open(url, '_blank'));
