export const fieldNames = {
  // A
  automatica: 'Transferência Automática',
  aliquotaFinanciado: 'Alíquota Financiado',
  aliquotaTransacao: 'Alíquota Transação',
  alqueires: 'Quantidade de Alqueires',
  anuentes: 'Anuentes',
  anuencia: 'Anuência',
  areaConstruida: 'Área Construída',
  areaConstruidaAvaliacao: 'Área Construída Avaliação',
  areaTerreno: 'Área Terreno',
  areaTotal: 'Área Total',
  ativo: 'Ativo',
  // B
  bairro: 'Bairro',
  boleto: 'Boleto',
  // C
  cadastro: 'Cadastro',
  cadastroImobiliario: 'Imóvel',
  cadastroRural: 'Cadastro Rural',
  cadastroImobiliario_exclusaoLogica: 'Exclusão Lógica',
  campoDocumento: 'Campo Documento',
  campos: 'Campos',
  celular: 'Celular',
  cep: 'CEP',
  cidade: 'Cidade',
  codigo: 'Código',
  codigoItbi: 'Código ITBI',
  codigoItbiAise: 'Aise',
  codigoVinculo: 'Código Vínculo',
  complemento: 'Complemento',
  component: 'Componente',
  compradores: 'Compradores',
  coresponsavelPrincipal: 'Co-Responsável Principal',
  cpfCnpj: 'CPF/CNPJ',
  // D
  data: 'Data',
  descricao: 'Descrição',
  distrito: 'Distrito',
  documentoItbi: 'Documento ITBI',
  documentos: 'Documentos',
  documentosAvulsos: 'Documentos Avulsos',
  // E
  email: 'E-mail',
  endereco: 'Endereço',
  enderecoDescrito: 'Endereco Descrito',
  estado: 'Estado',
  exercicio: 'Exercício',
  // F
  financiado: 'Financiado',
  // G
  // H
  // I
  id: 'ID',
  idItbi: 'ID ITBI Aise',
  idDebito: 'ID Débito Aise',
  inscricaoCadastral: 'Inscrição Cadastral',
  instrucao: 'Instrução',
  // J
  // K
  // L
  label: 'Label',
  logradouro: 'Logradouro',
  lote: 'Lote',
  loteamento: 'Loteamento',
  // M
  motivoIndeferimento: 'Motivo do Indeferimento',
  motivoPrioritario: 'Motivo da Priorização',
  // N
  name: 'Nome',
  nome: 'Nome',
  normal: 'Normal',
  novoPercentual: 'Novo Percentual',
  numero: 'Número',
  // O
  obrigatorio: 'Obrigatório',
  observacaoCidadao: 'Observação Cidadão',
  observacaoServidor: 'Observação Servidor',
  // P
  percentual: 'Percentual',
  percentualVenda: 'Percentual de Venda',
  pessoa: 'Pessoa',
  principal: 'Principal',
  prioritario: 'Prioritário',
  proprietario: 'Proprietário',
  proprietarios: 'Proprietários',
  // Q
  quadra: 'Quadra',
  // R
  requerente: 'Requerente',
  requerente_procurador: 'Procurador',
  remanescente: 'Remanescente',
  responsavel: 'Responsável',
  responsavel_procurador: 'Procurador',
  // S
  segmentos: 'Construções',
  sequencia: 'Sequência',
  situacaoItbi: 'Situação',
  situacaoItbi_situacaoCidadao: 'Situação Cidadão',
  // T
  tipoCadastro: 'Tipo Cadastro',
  tipoCampo: 'Tipo Campo',
  tipoEndereco: 'Tipo Endereço',
  tipoItbi: 'Tipo Itbi',
  tipoItbiAise: 'Aise',
  tipoPessoa: 'Tipo Pessoa',
  tipoSegmento: 'Tipo Construção',
  tiposItbi: 'Tipos Itbi',
  tipoVinculo: 'Tipo do vínculo',
  type: 'Tipo',
  tipoImovel: 'Tipo do Imóvel',
  // U
  uf: 'UF',
  ultimaAlteracao: 'Última Alteração',
  unidade: 'Unidade',
  urlBoleto: 'Boleto',
  usuario: 'Usuário',
  // V
  valor: 'Valor',
  valorConstrucao: 'Valor Construção',
  valorFinanciado: 'Valor Financiado',
  valorFinanciadoAvaliacao: 'Valor Financiado Avaliação',
  valorFinanciadoItbi: 'Valor Financiado Itbi',
  valorTotalCalculado: 'Valor Total Calculado',
  valorTotalSegmentos: 'Valor Total Construções',
  valorTransacao: 'Valor Transação',
  valorTransacaoAvaliacao: 'Valor Transação Avaliação',
  valorTransacaoItbi: 'Valor Transação Itbi',
  valorTerritorialCalculado: 'Valor Territorial Calculado',
  vendedores: 'Vendedores',
  vinculo: 'Vínculo',
  visivel: 'Visível',
  visivelCidadao: 'Visível para o Cidadão',
  zona: 'Zona'
};

export const ignoreFields = [
  'id',
  'anuentes_id',
  'compradores_id',
  'compradores_ibge',
  'documentos_id',
  'documentosAvulsos_id',
  'documentosAvulsos_url',
  'endereco_ibge',
  'proprietarios_id',
  'responsavel_id',
  'requerente_id',
  'segmentos_id',
  'segmentos_tipoSegmento_id',
  'situacaoItbi_name',
  'situacaoItbi_mostrarDashboard',
  'situacaoItbi_editable',
  'situacaoItbi_situacaoCidadao_editable',
  'situacaoItbi_situacaoCidadao_mostrarDashboard',
  'situacaoItbi_situacaoCidadao_name',
  'tipoItbi_id',
  'tipoSegmento_id',
  'vendedores_id',
  'vinculo_id',
  'cadastroImobiliario_latitude',
  'cadastroImobiliario_longitude',
  'cadastroImobiliario_grauSul',
  'cadastroImobiliario_minutoSul',
  'cadastroImobiliario_segundoSul',
  'cadastroImobiliario_grauOeste',
  'cadastroImobiliario_minutoOeste',
  'cadastroImobiliario_segundoOeste',
  'cadastroImobiliario_codigoCidade',
  'cadastroImobiliario_codigoBairro',
  'cadastroImobiliario_codigoLogradouro',
  'tipoItbiAise_id',
  'tipoItbi_tipoItbiAise',
  'userNotFoundMessageTemplate',
  'documentoItbi_id',
  'cadastroRural_id'
];

export const nestedFields = [
  'responsavel',
  'requerente',
  'anuentes_vinculo',
  'compradores_vinculo',
  'proprietarios_vinculo',
  'situacaoItbi',
  'situacaoItbi_situacaoCidadao',
  'tipoItbi',
  'vendedores_vinculo',
  'segmentos_tipoSegmento',
  'cadastroImobiliario',
  'boleto'
];
