import {
  AutoComplete,
  ErrorText,
  Masks,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

const Label = styled.label`
  color: #8b8d9e;
`;

export default class EnderecoSelectSection extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    currentPerson: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    loadingCep: PropTypes.bool.isRequired,
    errorMessages: PropTypes.object.isRequired,
    loadBairrosAise: PropTypes.func.isRequired,
    loadEnderecosAise: PropTypes.func.isRequired,
    loadTiposLogradouroAise: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onChangeCepValue: PropTypes.func.isRequired,
    onChangeInputValue: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
  };

  static defaultProps = {
    readOnly: false
  };

  getFilterLogradouro = search => {
    const { currentPerson } = this.props;
    if (currentPerson.tipoLogradouroAise && currentPerson.cidadeAise) {
      return `descricao=='*${search}*' and tipoLogradouro.id==${currentPerson.tipoLogradouroAise.id} and cidade==${currentPerson.cidadeAise.id}`;
    }
  };

  getFilterBairro = search => {
    const { currentPerson } = this.props;
    if (currentPerson.cidadeAise) {
      return `descricao=='*${search}*' and cidade==${currentPerson.cidadeAise.id}`;
    }
  };

  onSearchLogradouroAise = search => {
    const { currentPerson, loadEnderecosAise } = this.props;
    if (currentPerson.tipoLogradouroAise) {
      return loadEnderecosAise(search, currentPerson);
    }
    return Promise.resolve({});
  };

  render() {
    const {
      currentPerson,
      errors,
      loadingCep,
      title,
      errorMessages,
      onSelect,
      onChangeCepValue,
      onChangeInputValue,
      readOnly,
      loadBairrosAise,
      loadTiposLogradouroAise
    } = this.props;

    return (
      <React.Fragment>
        <SectionTitle>Endereço do {title}</SectionTitle>
        <div className="info">
          <Label>Dados informados:</Label> Bairro:{' '}
          <strong>{currentPerson.bairro}</strong> Endereço:{' '}
          <strong>{currentPerson.logradouro}</strong>
        </div>
        <div className="row">
          <div className="form-group col-sm-2">
            <label className="label">CEP</label>
            <MaskedInput
              data-test-id="inputCep"
              type="text"
              onChange={onChangeCepValue}
              mask={Masks.MASK_CEP}
              value={currentPerson.cep || ''}
              selected={!loadingCep && currentPerson.logradouro}
              disabled={readOnly}
            />
            {errors.cep && <ErrorText>{errorMessages.cep}</ErrorText>}
          </div>
          <div className="form-group col-sm-5">
            <label className="label">Cidade</label>
            <input
              data-test-id="input-cidade"
              type="text"
              name="cidadeAise"
              className={errors.cidade ? 'error' : ''}
              value={
                currentPerson.cidadeAise
                  ? currentPerson.cidadeAise.descricao
                  : currentPerson.cidade
              }
              disabled
              autoComplete="off"
            />
            {errors.cidade && <ErrorText>{errorMessages.cidade}</ErrorText>}
          </div>
          <div className="form-group col-sm-1">
            <label className="label">Estado</label>
            <input
              data-test-id="input-uf"
              type="text"
              name="uf"
              className={errors.uf ? 'error' : ''}
              value={currentPerson.uf}
              disabled
              autoComplete="off"
            />
            {errors.uf && <ErrorText>{errorMessages.uf}</ErrorText>}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-4">
            <label className="label" htmlFor="bairroAise">
              Bairro
            </label>
            <AutoComplete
              data-test-id="autocomplete-bairro"
              name="bairroAise"
              value={currentPerson.bairroAise}
              onSearch={loadBairrosAise}
              onSelect={onSelect}
              filter={this.getFilterBairro}
              isDisabled={readOnly}
            />
            {errors.bairroAise && <ErrorText>{errorMessages.bairro}</ErrorText>}
          </div>
          <div className="form-group col-sm-3">
            <label className="label" htmlFor="tipoLogradouroAise">
              Tipo Endereço
            </label>
            <AutoComplete
              name="tipoLogradouroAise"
              value={currentPerson.tipoLogradouroAise || ''}
              onSearch={loadTiposLogradouroAise}
              onSelect={onSelect}
              isDisabled={readOnly}
            />
            {errors.tipoLogradouroAise && (
              <ErrorText>{errorMessages.logradouro}</ErrorText>
            )}
          </div>
          <div className="form-group col-sm-5">
            <label className="label" htmlFor="logradouroAise">
              Endereço
            </label>
            <AutoComplete
              style={{ backgroundColor: 'red' }}
              name="logradouroAise"
              value={currentPerson.logradouroAise || ''}
              onSearch={this.onSearchLogradouroAise}
              onSelect={onSelect}
              filter={this.getFilterLogradouro}
              isDisabled={!currentPerson.tipoLogradouroAise || readOnly}
            />
            {errors.logradouroAise && (
              <ErrorText>{errorMessages.logradouro}</ErrorText>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-2">
            <label className="label">Número</label>
            <input
              data-test-id="input-numero"
              type="text"
              name="numero"
              className={errors.numero || errors.numeroLength ? 'error' : ''}
              onChange={onChangeInputValue}
              value={currentPerson.numero}
              disabled={readOnly}
              autoComplete="off"
            />
            {errors.numero && <ErrorText>{errorMessages.numero}</ErrorText>}
            {errors.numeroLength && (
              <ErrorText>{errorMessages.numeroLength}</ErrorText>
            )}
          </div>
          <div className="form-group col-sm-5">
            <label className="label">Complemento</label>
            <input
              type="text"
              name="complemento"
              onChange={onChangeInputValue}
              value={currentPerson.complemento}
              disabled={readOnly}
              autoComplete="off"
            />
            {errors.complementoLength && (
              <ErrorText>{errorMessages.complementoLength}</ErrorText>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
