import {
  Checkbox,
  Col,
  ErrorText,
  Row,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const errorMessages = {
  nome: 'O nome é obrigatório.'
};

const DadosDocumentoSection = ({ onChange, documento, error }) => {
  return (
    <div>
      <SectionTitle>Dados Documento</SectionTitle>
      <Row>
        <Col md={4}>
          <label htmlFor="nomeDocumento" className="label">
            Nome Documento
          </label>
          <input
            id="nomeDocumento"
            required={true}
            type="text"
            name="nome"
            value={documento.nome || ''}
            onChange={onChange}
          />
          {error.nome && <ErrorText>{errorMessages.nome}</ErrorText>}
        </Col>
        <Col md={2}>
          <div className="form-group">
            <Checkbox
              id="required"
              name="obrigatorio"
              checked={documento.obrigatorio || false}
              onChange={onChange}
              label={'Obrigatório'}
              hintText="Documento será obrigatório em toda declaração itbi, de acordo com o tipo vinculado."
            />
          </div>
        </Col>
        <Col md={2}>
          <div className="form-group">
            <Checkbox
              id="procuracaoRequerente"
              name="procuracaoRequerente"
              checked={documento.procuracaoRequerente}
              onChange={onChange}
              label={'Procuração Requerente'}
              hintText="Este Documento será exigido quando o CPF ou CNPJ do requerente não fizer parte dos compradores de ITBI."
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <div className="form-group">
            <label htmlFor="instrucaoDocumento" className="label">
              Instrução do documento
            </label>
            <textarea
              id="instrucaoDocumento"
              name="instrucao"
              value={documento.instrucao || ''}
              onChange={onChange}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

DadosDocumentoSection.propTypes = {
  documento: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default DadosDocumentoSection;
