import { BreadCrumbRoute } from '@elotech/components';

import LoteLaudoAnalisePage from '../pages/laudos/analise/LoteLaudoAnalisePage';
import LoteLaudoAnexoViewPage from '../pages/laudos/LoteLaudoAnexoViewPage';
import LoteLaudoFormPage from '../pages/laudos/LoteLaudoFormPage';
import LoteLaudoListPage from '../pages/laudos/LoteLaudoListPage';
import LoteLaudoTransferenciaPage from '../pages/laudos/LoteLaudoTransferenciaPage';
import LoteLaudoViewPage from '../pages/laudos/LoteLaudoViewPage';
import { Roles } from '../roles';

const routesLaudo: BreadCrumbRoute[] = [
  {
    title: 'Laudos ITBI',
    icon: 'paperclip',
    path: '/laudos',
    component: LoteLaudoListPage,
    role: Roles.laudo_visualizar.name
  },
  {
    title: 'Laudos ITBI',
    icon: 'paperclip',
    parent: '/laudos',
    path: '/laudos/novo',
    component: LoteLaudoFormPage
  },
  {
    title: 'Laudos ITBI',
    icon: 'paperclip',
    parent: '/laudos',
    path: '/laudos/:id',
    component: LoteLaudoAnalisePage
  },
  {
    title: 'Laudos ITBI',
    icon: 'paperclip',
    parent: '/laudos',
    path: '/laudos/:id/resumo',
    component: LoteLaudoViewPage
  },
  {
    title: 'Laudos ITBI',
    icon: 'paperclip',
    parent: '/laudos',
    path: '/laudos/:id/transferencia',
    component: LoteLaudoTransferenciaPage,
    role: Roles.transferir_responsavel.name
  },
  {
    title: 'Laudos ITBI',
    icon: 'paperclip',
    parent: '/laudos',
    path: '/laudos/:id/anexos',
    component: LoteLaudoAnexoViewPage
  }
];

export default routesLaudo;
