import { Col, QuickView, Row, SectionTitle } from '@elotech/components';
import React from 'react';

const DocumentoItbiQuickView = ({ documento, onClose }) => {
  if (documento) {
    return (
      <QuickView onClose={onClose}>
        <div className="quick-header">
          <Row>
            <Col md={8}>
              <div className="form-group">{documento.nome}</div>
            </Col>
            <Col md={4}>
              <div className="form-group">
                {documento.obrigatorio ? 'Obrigatório' : 'Não obrigatório'}
              </div>
            </Col>

            <Col md={4}>
              <div className="form-group">
                {documento.procuracaoRequerente
                  ? 'Obrigatório'
                  : 'Não obrigatório'}
              </div>
            </Col>
          </Row>
        </div>
        {(documento.tiposItbi || []).length > 0 && (
          <React.Fragment>
            <SectionTitle>Tipos de ITBI Associados</SectionTitle>
            {documento.tiposItbi.map(tipo => (
              <Row key={tipo.id}>
                <Col md={12}>{tipo.descricao}</Col>
              </Row>
            ))}
          </React.Fragment>
        )}
        {(documento.campos || []).length > 0 && (
          <React.Fragment>
            <SectionTitle>Campos Associados</SectionTitle>
            <Row>
              <Col md={6}>
                <div className="form-group">
                  <label className="label">Nome</label>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="label">Tipo</label>
                </div>
              </Col>
            </Row>
            {documento.campos.map(campo => (
              <Row key={campo.id}>
                <Col md={6}>{campo.label}</Col>
                <Col md={6}>{campo.tipoCampo.descricao}</Col>
              </Row>
            ))}
          </React.Fragment>
        )}
      </QuickView>
    );
  }
  return null;
};

export default DocumentoItbiQuickView;
