import { Alert, Container, FAB, Info, Loading } from '@elotech/components';
import { PessoasQuickView } from 'itbi-common/components';
import { DeclaracaoService } from 'itbi-common/service';
import React from 'react';
import { connect } from 'react-redux';

import {
  DadosCadastroRural,
  DadosImovel,
  DadosTransferencia,
  PessoaList
} from './ResumoDeclaracao';

export class GeraDebito extends React.Component {
  state = {
    declaracaoItbi: undefined,
    submitting: false,
    pessoaQuickView: undefined
  };

  componentDidMount() {
    this.setState({ submitting: true });
    DeclaracaoService.findDeclaracaoItbiById(this.props.match.params.id)
      .then(response => {
        this.setState({ declaracaoItbi: response.data, submitting: false });
      })
      .catch(error => {
        this.setState({ submitting: false });
        Alert.error(
          {
            title: 'Erro ao carregar os dados da declaração.'
          },
          error
        );
      });
  }

  onGerar = () => {
    const { history } = this.props;
    const { declaracaoItbi } = this.state;
    this.setState({
      submitting: true
    });

    DeclaracaoService.gerarDebito(declaracaoItbi)
      .then(async response => {
        this.setState({
          submitting: false
        });

        if (response.data.isDeclaracaoItbiIsenta) {
          return await Alert.success({
            title: 'Boleto não gerado, declaração ITBI isenta!'
          });
        }

        if (!response.data.boleto) {
          return await Alert.error({ title: 'Falha ao gerar o boleto' });
        }

        await Alert.success({
          title: 'Débito gerado!',
          text: 'Clique no ok para ver o resumo da declaração ITBI.'
        });

        history.replace(`/declaracoes-itbi/${declaracaoItbi.id}/resumo`);
      })
      .catch(error => {
        this.setState({ error: error, submitting: false });
        Alert.error({ title: 'Falha ao gerar débito!' }, error);
      });
  };

  onViewPessoa = pessoa => {
    this.setState({
      pessoaQuickView: pessoa
    });
  };

  onCloseQuickViewPessoa = () => {
    this.setState({
      pessoaQuickView: undefined
    });
  };

  retificar = event => {
    event.preventDefault();
    const { history } = this.props;
    const { declaracaoItbi } = this.state;

    this.setState({
      submitting: true
    });

    DeclaracaoService.retificar(declaracaoItbi.id)
      .then(() => {
        this.setState({
          submitting: false
        });
        history.replace(`/declaracoes-itbi/${declaracaoItbi.id}`);
      })
      .catch(error => {
        this.setState({ error: error, submitting: false });
        Alert.error({ title: 'Falha ao retificar declaração!' }, error);
      });
  };

  render() {
    const { vinculos } = this.props;
    const { declaracaoItbi, submitting, pessoaQuickView } = this.state;

    if (!declaracaoItbi) {
      return <Loading loading={submitting} />;
    }

    return (
      <Container title="Gerar Débito" icon="dollar-sign">
        <Loading loading={submitting} />
        <Info classes="warning mb-xs">
          Analise as informações para gerar o débito. Caso algo esteja errado{' '}
          <a
            data-test-id="link-retificar"
            href="#retificar"
            onClick={this.retificar}
          >
            clique aqui
          </a>{' '}
          e faça a análise novamente.
        </Info>

        <PessoasQuickView
          pessoa={pessoaQuickView}
          onClose={this.onCloseQuickViewPessoa}
        />

        <DadosTransferencia dadosTransferencia={declaracaoItbi} />
        {declaracaoItbi.tipoImovel === 'URBANO' ? (
          <DadosImovel
            cadastroImobiliario={declaracaoItbi.cadastroImobiliario}
          />
        ) : (
          <DadosCadastroRural cadastro={declaracaoItbi.cadastroRural} />
        )}

        {declaracaoItbi.compradores.length > 0 && (
          <PessoaList
            title="Compradores"
            list={declaracaoItbi.compradores}
            vinculos={vinculos}
            onViewPessoa={this.onViewPessoa}
          />
        )}
        {declaracaoItbi.anuentes.length > 0 && (
          <PessoaList
            title="Anuentes"
            list={declaracaoItbi.anuentes}
            vinculos={vinculos}
            onViewPessoa={this.onViewPessoa}
          />
        )}
        {declaracaoItbi.vendedores.length > 0 && (
          <PessoaList
            title="Vendedores"
            list={declaracaoItbi.vendedores}
            vinculos={vinculos}
            onViewPessoa={this.onViewPessoa}
          />
        )}
        {declaracaoItbi.proprietarios.length > 0 && (
          <PessoaList
            title="Proprietários"
            list={declaracaoItbi.proprietarios}
            proprietario
            vinculos={vinculos}
            onViewPessoa={this.onViewPessoa}
          />
        )}
        <div className="btn-save">
          <FAB
            data-test-id="buttonSave"
            icon="dollar-sign"
            iconColor="white"
            title="Confirmar para Gerar Débito"
            onClick={this.onGerar}
            Loading={submitting}
          />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    vinculos: state.proprietario.vinculos
  };
};

export default connect(mapStateToProps)(GeraDebito);
