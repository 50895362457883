import {
  Button,
  Card,
  Chip,
  Col,
  DateUtils,
  FormattedDateTime,
  InputDate,
  ProgressBar,
  Row,
  SectionTitle,
  SimpleText,
  TextArea,
  useAuth
} from '@elotech/components';
import classnames from 'classnames';
import {
  IntegracaoScheduler,
  StatusIntegracaoScheduler,
  TipoIntegracaoScheduler
} from 'itbi-common/type';
import React, { useState } from 'react';

import { Roles } from '../../roles/Roles';

type Props = {
  integracaoScheduler: IntegracaoScheduler;
  titulo: string;
  size: number;
  onSincronizar(
    tipo: string,
    dataReferencia: string,
    sincronizarTodosEnderecos: boolean
  ): void;
};

const SincronizacaoScheduler: React.FC<Props> = props => {
  const { hasRole } = useAuth();
  const [dataReferencia, setDataReferencia] = useState<string>(
    DateUtils.getTodayDateDecDays(1)
  );
  const { integracaoScheduler, titulo, onSincronizar, size } = props;

  const hasIntegracao = !!integracaoScheduler.id;
  const hasPermission = hasRole(Roles.sincronizar_dados.name);
  const isTipoCadastro =
    integracaoScheduler.tipo === TipoIntegracaoScheduler.CADASTRO;
  const isTipoEndereco =
    integracaoScheduler.tipo === TipoIntegracaoScheduler.ENDERECO;
  const isStatusExecutando =
    integracaoScheduler.status === StatusIntegracaoScheduler.EXECUTANDO;

  const getStatusColor = () => {
    switch (integracaoScheduler.status) {
      case StatusIntegracaoScheduler.EXECUTANDO:
        return 'neutral';
      case StatusIntegracaoScheduler.SUCESSO:
        return 'positive';
      case StatusIntegracaoScheduler.ATRASADO:
        return 'warning';
      default:
        return 'negative';
    }
  };

  const iconeBotaoSincronizar = classnames('fa fa-sync', {
    'fa-spin': isStatusExecutando
  });

  const renderDataReferencia = () => (
    <>
      {hasPermission && isTipoCadastro && (
        <Col md={2}>
          <div className="form-group flex">
            <label className="label">Data de Referência</label>
            <InputDate
              name="dataReferencia"
              value={dataReferencia}
              onChange={(event: React.ChangeEvent<any>) =>
                setDataReferencia(event.target.value)
              }
              title="dataReferencia"
            />
          </div>
        </Col>
      )}
    </>
  );

  return (
    <Col md={size}>
      <Card marginTop={!isTipoCadastro}>
        <SectionTitle marginTop="0px" hasButton>
          {titulo}
          {hasPermission && (
            <Row style={{ display: 'flex' }}>
              {isTipoEndereco && (
                <Col md={7}>
                  <Button
                    disabled={hasIntegracao && isStatusExecutando}
                    onClick={() =>
                      onSincronizar(
                        integracaoScheduler.tipo,
                        dataReferencia,
                        true
                      )
                    }
                    iconPosition="left"
                  >
                    <i className={iconeBotaoSincronizar} />
                    Sincronizar Todos
                  </Button>
                </Col>
              )}
              <Col md={6}>
                <Button
                  disabled={hasIntegracao && isStatusExecutando}
                  onClick={() =>
                    onSincronizar(
                      integracaoScheduler.tipo,
                      dataReferencia,
                      false
                    )
                  }
                  className="mr-xs"
                  iconPosition="left"
                >
                  <i className={iconeBotaoSincronizar} />
                  Sincronizar
                </Button>
              </Col>
            </Row>
          )}
        </SectionTitle>
        {hasIntegracao ? (
          <>
            <Row className="mb-xs">
              <Col md={2}>
                <SimpleText
                  label="Data da última integração"
                  text={
                    <FormattedDateTime
                      value={integracaoScheduler.dataUltimaIntegracao}
                    />
                  }
                />
              </Col>
              <Col md={3}>
                <SimpleText
                  label="Data da última integração com sucesso"
                  text={
                    <FormattedDateTime
                      value={integracaoScheduler.dataUltimaIntegracaoComSucesso}
                    />
                  }
                />
              </Col>
              <Col md={2}>
                <SimpleText
                  label="Origem da execução"
                  text={integracaoScheduler.origemExecucao}
                />
              </Col>
              <Col md={1}>
                <SimpleText
                  label="Duração"
                  text={integracaoScheduler.duracao}
                />
              </Col>
              <Col md={2}>
                <div className="form-group">
                  <label className="label">Status</label>
                  <Chip
                    value={integracaoScheduler.status}
                    color={getStatusColor()}
                  />
                </div>
              </Col>
              {renderDataReferencia()}
            </Row>
            {integracaoScheduler.motivoErro && (
              <Row className="mb-xs">
                <Col md={12}>
                  <label className="label">Motivo de Erro</label>
                  <TextArea
                    resize="vertical"
                    name="motivoErro"
                    value={integracaoScheduler.motivoErro}
                    className="error"
                    disabled
                  />
                </Col>
              </Row>
            )}
            {isTipoCadastro && isStatusExecutando && (
              <Row>
                <Col md={12}>
                  <label className="label">Progresso</label>
                  <ProgressBar
                    percentualProgresso={Math.round(
                      (integracaoScheduler.totalProcessados * 100) /
                        integracaoScheduler.totalRegistros
                    )}
                    title={
                      integracaoScheduler.totalRegistros > 0
                        ? `${integracaoScheduler.totalProcessados} de ${integracaoScheduler.totalRegistros} cadastros sincronizados`
                        : ''
                    }
                  />
                </Col>
              </Row>
            )}
          </>
        ) : (
          <>
            <Row>
              <p className="text-xs-center">Sem Registros</p>
            </Row>
            <Row>{renderDataReferencia()}</Row>
          </>
        )}
      </Card>
    </Col>
  );
};

export default SincronizacaoScheduler;
