import { Roles } from '../types';

export const rolesComposite: Roles = {
  usuario: {
    name: 'usuario',
    description: 'Acesso básico do sistema',
    composite: true,
    composites: {
      client: {
        'itbi-backend': [
          'acesso_total_cadastros',
          'acesso_total_laudo',
          'acesso_total_menu_inicial',
          'acesso_total_declaracao_itbi',
          'itbi_visualizar',
          'laudo_visualizar',
          'documentos_itbi_visualizar',
          'tipo_itbi_visualizar',
          'termo_responsabilidade_visualizar',
          'acessar_sincronizacao_dados'
        ]
      }
    }
  },
  gerente: {
    name: 'gerente',
    description: 'Acesso de gerente ao sistema',
    composite: true,
    composites: {
      client: {
        'itbi-backend': [
          'sincronizar_dados',
          'itbi_editar_proprietario_remanescente',
          'laudo_estornar',
          'cadastro_termo_responsabilidade',
          'itbi_cancelar_pedido',
          'acesso_prioritario'
        ]
      }
    }
  }
};
