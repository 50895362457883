import { NotificationReducer } from '@elotech/components';
import CidadeReducer from 'itbi-common/state/cidade';
import ConfigReducer from 'itbi-common/state/config';
import ProprietarioReducer from 'itbi-common/state/proprietario';
import UserReducer from 'itbi-common/state/user';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

const reducer = combineReducers({
  user: UserReducer,
  notification: NotificationReducer,
  config: ConfigReducer,
  proprietario: ProprietarioReducer,
  cidade: CidadeReducer
});

export default initialState => {
  // eslint-disable-next-line no-undef, no-underscore-dangle
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancer = composeEnhancers(applyMiddleware(thunk));
  return createStore(reducer, initialState, enhancer);
};
