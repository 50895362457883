export const rolesITBI = {
  cadastro_documento_itbi_adicionar: {
    name: 'cadastro_documento_itbi_adicionar',
    description: 'Permite adicionar um novo documento de ITBI'
  },
  cadastro_documento_itbi_adicionar_campo: {
    name: 'cadastro_documento_itbi_adicionar_campo',
    description: 'Permite adicionar um novo campo ao documento de ITBI'
  },
  cadastro_documento_itbi_adicionar_vinculo: {
    name: 'cadastro_documento_itbi_adicionar_vinculo',
    description: 'Permite adicionar um vinculo do documento com o tipo de ITBI'
  },
  cadastro_documento_itbi_editar: {
    name: 'cadastro_documento_itbi_editar',
    description: 'Permite editar um documento de ITBI'
  },
  cadastro_documento_itbi_remover: {
    name: 'cadastro_documento_itbi_remover',
    description: 'Permite remover um documento de ITBI'
  },
  cadastro_documento_itbi_remover_campo: {
    name: 'cadastro_documento_itbi_remover_campo',
    description: 'Permite remover um campo do cadastro de documento de ITBI'
  },
  cadastro_documento_itbi_remover_vinculo: {
    name: 'cadastro_documento_itbi_remover_vinculo',
    description: 'Permite remover o vinculo do documento com o tipo de ITBI'
  },
  cadastro_tipos_itbi_adicionar: {
    name: 'cadastro_tipos_itbi_adicionar',
    description: 'Permite adicionar um novo tipo de ITBI'
  },
  cadastro_tipos_itbi_alterar_visibilidade: {
    name: 'cadastro_tipos_itbi_alterar_visibilidade',
    description: 'Permite alterar a visibilidade do tipo de ITBI'
  },
  cadastro_tipos_itbi_editar: {
    name: 'cadastro_tipos_itbi_editar',
    description: 'Permite editar o tipo de ITBI'
  },
  cadastro_tipos_itbi_remover: {
    name: 'cadastro_tipos_itbi_remover',
    description: 'Permite remover um tipo de ITBI'
  },
  itbi_assumir_pedido: {
    name: 'itbi_assumir_pedido',
    description: 'Permite assumir um novo pedido de ITBI'
  },
  itbi_cancelar_pedido: {
    name: 'itbi_cancelar_pedido',
    description:
      'Permite cancelar pedido de ITBI mesmo o contribuintes já ter sido notificado e com situação Aguardando Pagamento.'
  },
  itbi_consultar_ultimas_avaliacoes: {
    name: 'itbi_consultar_ultimas_avaliacoes',
    description:
      'Permite consulta as últimas avaliações do cadastro no pedido de ITBI'
  },
  itbi_editar_anuente: {
    name: 'itbi_editar_anuente',
    description: 'Permite editar anuente do pedido de ITBI'
  },
  itbi_editar_comprador: {
    name: 'itbi_editar_comprador',
    description: 'Permite editar o comprador do pedido de ITBI'
  },
  itbi_editar_construcao: {
    name: 'itbi_editar_construcao',
    description:
      'Permite editar as construções da propriedade no pedido de ITBI'
  },
  itbi_editar_pedido_terceiros: {
    name: 'itbi_editar_pedido_terceiros',
    description: 'Permite editar pedido de ITBI de outros usuários'
  },
  itbi_editar_proprietario_remanescente: {
    name: 'itbi_editar_proprietario_remanescente',
    description: 'Permite editar as informações dos proprietários remanescentes'
  },
  itbi_editar_vendedor: {
    name: 'itbi_editar_vendedor',
    description: 'Permite editar vendedor do pedido de ITBI'
  },
  itbi_estornar_pedido: {
    name: 'itbi_estornar_pedido',
    description: 'Permite estornar o pedido de ITBI'
  },
  itbi_finalizar_pedido: {
    name: 'itbi_finalizar_pedido',
    description: 'Permite deferir/indeferir o pedido de ITBI'
  },
  itbi_gerar_debito: {
    name: 'itbi_gerar_debito',
    description: 'Permite gerar o débito referente ao ITBI'
  },
  itbi_imprimir_boleto: {
    name: 'itbi_imprimir_boleto',
    description: 'Permite imprimir boleto ITBI'
  },
  itbi_remover_anuente: {
    name: 'itbi_remover_anuente',
    description: 'Permite remover o anuente do pedido de ITBI'
  },
  itbi_remover_comprador: {
    name: 'itbi_remover_comprador',
    description: 'Permite remover o comprador do pedido de ITBI'
  },
  itbi_remover_construcao: {
    name: 'itbi_remover_construcao',
    description:
      'Permite remover as construções da propriedade no pedido de ITBI'
  },
  itbi_remover_proprietario_remanescente: {
    name: 'itbi_remover_proprietario_remanescente',
    description: 'Permite remover proprietário remanescente do pedido de ITBI'
  },
  itbi_remover_vendedor: {
    name: 'itbi_remover_vendedor',
    description: 'Permite remover o vendedor do pedido de ITBI'
  },
  itbi_restaurar_construcao: {
    name: 'itbi_restaurar_construcao',
    description:
      'Permite restaurar as construções da propriedade no pedido de ITBI'
  },
  itbi_transferir_manual: {
    name: 'itbi_transferir_manual',
    description: '	Permite transferir o ITBI de forma manual'
  },
  itbi_transferir_manual_sem_pagamento: {
    name: 'itbi_transferir_manual_sem_pagamento',
    description:
      'Permite transferir o ITBI de forma manual antes de realizar o pagamento'
  },
  itbi_visualizar: {
    name: 'itbi_visualizar',
    description: 'Permite visualizar declaraçoes de ITBI'
  },
  tipo_itbi_visualizar: {
    name: 'tipo_itbi_visualizar',
    description: 'Permite visualizar tipos de ITBI'
  },
  documentos_itbi_visualizar: {
    name: 'documentos_itbi_visualizar',
    description: 'Permite visualizar documentos de ITBI'
  }
};
