import { ErrorText, Masks, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

export default class EnderecoSection extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    currentPerson: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    loadingCep: PropTypes.bool.isRequired,
    errorMessages: PropTypes.object.isRequired,
    onChangeCepValue: PropTypes.func.isRequired,
    onChangeInputValue: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
  };

  static defaultProps = {
    loadingCep: false,
    readOnly: false
  };

  render() {
    const {
      currentPerson,
      errors,
      loadingCep,
      title,
      errorMessages,
      onChangeCepValue,
      onChangeInputValue,
      readOnly
    } = this.props;

    return (
      <React.Fragment>
        <SectionTitle>Endereço do {title}</SectionTitle>
        <div className="row">
          <div className="form-group col-sm-2">
            <label className="label">CEP</label>
            <MaskedInput
              name={`cep-${title}`}
              data-test-id="inputCep"
              type="text"
              onChange={onChangeCepValue}
              mask={Masks.MASK_CEP}
              value={currentPerson.cep || ''}
              selected={!loadingCep && currentPerson.logradouro}
              disabled={readOnly}
              className={errors.cep ? 'error' : ''}
            />
            {errors.cep && <ErrorText>{errorMessages.cep}</ErrorText>}
          </div>
          <div className="form-group col-sm-5">
            <label className="label">Cidade</label>
            <input
              data-test-id="input-cidade"
              type="text"
              name="cidade"
              className={errors.cidade || errors.cidadeLength ? 'error' : ''}
              value={currentPerson.cidade}
              disabled
              autoComplete="off"
            />
            {errors.cidade && <ErrorText>{errorMessages.cidade}</ErrorText>}
            {errors.cidadeLength && (
              <ErrorText>{errorMessages.cidadeLength}</ErrorText>
            )}
          </div>
          <div className="form-group col-sm-1">
            <label className="label">Estado</label>
            <input
              data-test-id="input-uf"
              type="text"
              name="uf"
              className={errors.uf ? 'error' : ''}
              value={currentPerson.uf}
              disabled
              autoComplete="off"
            />
            {errors.uf && <ErrorText>{errorMessages.uf}</ErrorText>}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-3">
            <label className="label">Bairro</label>
            <input
              type="text"
              name="bairro"
              className={errors.bairro ? 'error' : ''}
              onChange={onChangeInputValue}
              value={currentPerson.bairro}
              disabled={readOnly}
              autoComplete="off"
            />
            {errors.bairro && <ErrorText>{errorMessages.bairro}</ErrorText>}
          </div>
          <div className="form-group col-sm-5">
            <label className="label">Endereço</label>
            <input
              data-test-id="input-logradouro"
              type="text"
              name="logradouro"
              className={
                errors.logradouro || errors.logradouroLength ? 'error' : ''
              }
              onChange={onChangeInputValue}
              value={currentPerson.logradouro}
              disabled={readOnly}
              autoComplete="off"
            />
            {errors.logradouro && (
              <ErrorText>{errorMessages.logradouro}</ErrorText>
            )}
            {errors.logradouroLength && (
              <ErrorText>{errorMessages.logradouroLength}</ErrorText>
            )}
          </div>
          <div className="form-group col-sm-1">
            <label className="label">Número</label>
            <input
              data-test-id="inputNumero"
              type="text"
              name="numero"
              className={errors.numero || errors.numeroLength ? 'error' : ''}
              onChange={onChangeInputValue}
              value={currentPerson.numero}
              disabled={readOnly}
              autoComplete="off"
            />
            {errors.numero && <ErrorText>{errorMessages.numero}</ErrorText>}
            {errors.numeroLength && (
              <ErrorText>{errorMessages.numeroLength}</ErrorText>
            )}
          </div>
          <div className="form-group col-sm-3">
            <label className="label">Complemento</label>
            <input
              type="text"
              name="complemento"
              className={errors.complementoLength ? 'error' : ''}
              onChange={onChangeInputValue}
              value={currentPerson.complemento}
              disabled={readOnly}
              autoComplete="off"
            />
            {errors.complementoLength && (
              <ErrorText>{errorMessages.complementoLength}</ErrorText>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
