import { Col } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

const DadosImovelUrbanoSection = ({ dadosImovel, onChange }) => {
  const normalizedOnChange = (mask, event) => {
    const { name } = event.target;
    onChange({
      target: {
        name: 'cadastroImobiliario',
        value: { ...dadosImovel, [name]: mask.floatValue }
      }
    });
  };
  return (
    <div>
      <div className="row">
        <Col md={3} xs={6}>
          <label htmlFor="cadastroInput">Cadastro Imobiliário</label>
          <input
            id="cadastroInput"
            type="text"
            name="cadastro"
            value={dadosImovel.cadastro}
            disabled
          />
        </Col>
        <Col md={3} xs={6}>
          <label htmlFor="inscricaoCadastralInput">Inscrição Cadastral</label>
          <input
            id="inscricaoCadastralInput"
            type="text"
            name="inscricaoCadastral"
            value={dadosImovel.inscricaoCadastral}
            disabled
          />
        </Col>
        <Col md={1} xs={6}>
          <label htmlFor="quadraInput">Quadra</label>
          <input
            id="quadraInput"
            type="text"
            name="quadra"
            value={dadosImovel.quadra}
            disabled
          />
        </Col>
        <Col md={1} xs={6}>
          <label htmlFor="loteInput">Data/Lote</label>
          <input
            id="loteInput"
            type="text"
            name="lote"
            value={dadosImovel.lote}
            disabled
          />
        </Col>
        <Col md={2} xs={6}>
          <label htmlFor="areaTerrenoInput">Área Terreno</label>
          <NumberFormat
            suffix=" m²"
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            allowNegative={false}
            value={dadosImovel.areaTerreno}
            name="areaTerreno"
            onValueChange={normalizedOnChange}
            id="areaTerrenoInput"
            disabled
          />
        </Col>
        <Col md={2} xs={6}>
          <label htmlFor="areaConstruidaInput">Área Construida</label>
          <NumberFormat
            suffix=" m²"
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            allowNegative={false}
            value={dadosImovel.areaConstruida}
            name="areaConstruida"
            id="areaConstruidaInput"
            onValueChange={normalizedOnChange}
            disabled
          />
        </Col>
      </div>
      <div className="row">
        <Col md={12}>
          <label htmlFor="complementoLoteInput">Complemento do Lote</label>
          <input
            id="complementoLoteInput"
            type="text"
            name="complementoLoteInput"
            value={dadosImovel.complementoLote}
            disabled
          />
        </Col>
      </div>
      <div className="row">
        <Col md={2}>
          <label htmlFor="cepInput">CEP</label>
          <input
            id="cepInput"
            type="text"
            name="cep"
            value={dadosImovel.cep}
            disabled
          />
        </Col>
        <Col md={6}>
          <label htmlFor="enderecoInput">Endereço</label>
          <input
            id="enderecoInput"
            type="text"
            name="endereco"
            value={dadosImovel.endereco}
            disabled
          />
        </Col>
        <Col md={2} xs={5}>
          <label htmlFor="numeroInput">Número</label>
          <input
            id="numeroInput"
            type="text"
            name="numero"
            value={dadosImovel.numero}
            disabled
          />
        </Col>
        <Col md={2} xs={7}>
          <label htmlFor="complementoInput">Complemento</label>
          <input
            id="complementoInput"
            type="text"
            name="complemento"
            value={dadosImovel.complemento}
            disabled
          />
        </Col>
      </div>
      <div className="row">
        <Col md={4}>
          <label htmlFor="bairroInput">Bairro</label>
          <input
            id="bairroInput"
            type="text"
            name="bairro"
            value={dadosImovel.bairro}
            disabled
          />
        </Col>
        <Col md={4}>
          <label htmlFor="cidadeInput">Cidade</label>
          <input
            id="cidadeInput"
            type="text"
            name="cidade"
            value={dadosImovel.cidade}
            disabled
          />
        </Col>
        <Col md={4}>
          <label htmlFor="estadoInput">Estado</label>
          <input
            id="estadoInput"
            type="text"
            name="estado"
            value={dadosImovel.estado}
            disabled
          />
        </Col>
      </div>
      <div className="row">
        <Col md={12}>
          <label htmlFor="condominioInput">Condomínio</label>
          <input
            id="condominioInput"
            type="text"
            name="condominio"
            value={
              dadosImovel.condominio
                ? `${dadosImovel.condominio} - ${dadosImovel.descricaoCondominio}`
                : ''
            }
            disabled
          />
        </Col>
      </div>
    </div>
  );
};

DadosImovelUrbanoSection.propTypes = {
  dadosImovel: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DadosImovelUrbanoSection;
