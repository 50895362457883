import axios, { AxiosPromise } from 'axios';

export const save = (documento: any): AxiosPromise<any> => {
  if (documento.id) {
    return axios.put(`/api/documentos/${documento.id}`, documento);
  }
  return axios.post('/api/documentos', documento);
};

export const search = (searchParams: string, page: string): AxiosPromise<any> =>
  axios.get(`/api/documentos`, {
    params: {
      page,
      param: searchParams || ''
    }
  });

export const getById = (id: string): AxiosPromise<any> =>
  axios.get(`/api/documentos/${id}`);

export const deleteDocument = (id: string): AxiosPromise<any> =>
  axios.delete(`/api/documentos/${id}`);
export const getHistory = (id: string): AxiosPromise<any> =>
  axios.get(`/api/documentos/${id}/history`);
