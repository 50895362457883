import { Info, Modal, Panel, SectionTitle } from '@elotech/components';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  duplicidades: any[];
  warnningDuplicidade: any;
  pedido: any;
  onClose: () => {};
};

const WarnningDuplicidadePedido: React.FC<Props> = ({
  duplicidades,
  warnningDuplicidade,
  pedido,
  onClose
}) => {
  return (
    warnningDuplicidade && (
      <Modal onClose={onClose}>
        <Panel
          title={`Atenção, existe(m) pedido(s) em andamento com as mesmas características do pedido ${pedido.numero}`}
        >
          <Info classes={'mb-xs warning'}>
            Para seguir para análise basta fechar esta Janela
          </Info>
          <SectionTitle>Pedido(s) Duplicado(s)</SectionTitle>
          {duplicidades.map(declaracao => {
            return (
              <p key={declaracao.numero}>
                {' '}
                Pedido{' '}
                <Link to={`/declaracoes-itbi/${declaracao.id}/resumo`}>
                  {declaracao.numero}
                </Link>
              </p>
            );
          })}
        </Panel>
      </Modal>
    )
  );
};

export default WarnningDuplicidadePedido;
