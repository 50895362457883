import { Col, Row, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const PointerInput = styled.input`
  cursor: pointer;
`;

const renderCampo = campo => {
  const InputType = campo.campoDocumento.tipoCampo.component;
  return (
    <Col className="form-group" md={3} key={campo.id}>
      <label htmlFor={campo.id}>{campo.campoDocumento.label}</label>
      <InputType
        id={campo.id}
        type={campo.campoDocumento.tipoCampo.type}
        name={campo.campoDocumento.label}
        value={campo.valor}
        disabled
      />
    </Col>
  );
};

const DocumentosImovelSection = ({ documentos, onDownload }) => {
  return (
    <div>
      <SectionTitle>Documentos do Imóvel</SectionTitle>
      {documentos.map(documento => (
        <Row key={documento.id}>
          <Col className="form-group" md={3}>
            <label htmlFor="">{documento.documentoItbi.nome}</label>
            <PointerInput
              data-id={documento.id}
              name=""
              placeholder={documento.valor}
              data-title={documento.valor}
              onClick={onDownload(documento, documento.valor)}
              readOnly
              disabled={!documento.valor}
            />
            {documento.valor && (
              <i
                aria-hidden="true"
                onClick={onDownload(documento, documento.valor)}
                className="fa fa-download input-icon"
              />
            )}
          </Col>
          {documento.campos.map(renderCampo)}
        </Row>
      ))}
    </div>
  );
};

DocumentosImovelSection.propTypes = {
  documentos: PropTypes.array.isRequired,
  onDownload: PropTypes.func.isRequired
};

export default DocumentosImovelSection;
