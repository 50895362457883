import {
  Alert,
  Container,
  FAB,
  Loading,
  NotificationActions
} from '@elotech/components';
import {
  DeclaracaoService,
  UserService,
  withService
} from 'itbi-common/service';
import { FunctionUtils } from 'itbi-common/utils';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { NewResponsavelSelector } from '../../components';
import { Roles } from '../../roles';
import { DadosTransferencia } from './ResumoDeclaracao';

class DeclaracaoTransferenciaPage extends Component {
  static propTypes = {};

  state = {
    loading: true,
    declaracaoItbi: undefined
  };

  componentDidMount() {
    const { params } = this.props.match;

    this.onGetDeclaracao(params.id);
  }

  onGetDeclaracaoSuccess = response => {
    this.setState({
      declaracaoItbi: response.data,
      loading: false
    });
  };

  onGetDeclaracao = id => {
    this.setState({ loading: true }, () => {
      this.props.declaracaoService
        .findDeclaracaoItbiById(id)
        .then(this.onGetDeclaracaoSuccess)
        .catch(error => {
          Alert.error(
            {
              title: 'Erro ao carregar os dados da declaração.'
            },
            error
          );
        });
    });
  };

  searchUser = searchParameter =>
    this.props.userService.searchUsuarioOnSameEntidade(
      searchParameter,
      Roles.itbi_assumir_pedido.name
    );

  onSelectUser = (_, newUser) => {
    this.setState({ currentUser: newUser });
  };

  onTransfer = () => {
    const { declaracaoItbi, currentUser } = this.state;
    const { showNotification, declaracaoService } = this.props;
    if (!currentUser) {
      return showNotification({
        level: 'warning',
        message: 'Selecione um usuário para realizar a transferência.'
      });
    }
    this.setState({ loading: true });
    declaracaoService
      .transferirResponsavel(declaracaoItbi.id, currentUser)
      .then(() => {
        showNotification({
          level: 'success',
          message: 'Declaração transferida com sucesso.'
        });
        this.props.history.goBack();
      })
      .catch(error => {
        return showNotification({
          level: 'warning',
          message: error.response.data.message
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, declaracaoItbi, currentUser } = this.state;
    if (loading) {
      return <Loading loading={true} />;
    }
    return (
      <Container
        title="Transferência de responsável da declaração ITBI"
        icon="file-signature"
      >
        <DadosTransferencia dadosTransferencia={declaracaoItbi} />
        <NewResponsavelSelector
          data-test-id="changeUser"
          loadOptions={this.searchUser}
          onChange={this.onSelectUser}
          selectedValue={currentUser}
        />
        <div className="btn-save">
          <FAB
            data-test-id="button-transferir"
            icon="check"
            iconColor="white"
            title="Transferir Responsável"
            onClick={this.onTransfer}
          />
        </div>
      </Container>
    );
  }
}

const enhancers = FunctionUtils.compose(
  withService({
    declaracaoService: DeclaracaoService,
    userService: UserService
  }),
  connect(null, {
    showNotification: NotificationActions.showNotification
  })
);

const EnhancedComponent = enhancers(DeclaracaoTransferenciaPage);

export { EnhancedComponent as default, DeclaracaoTransferenciaPage };
