import {
  FAB,
  Hint,
  Panel,
  SearchFilter,
  SearchPagination,
  Table,
  useAuth
} from '@elotech/components';
import { FunctionUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';

import { Roles } from '../../roles';
import { renderActionButtonsLaudo } from './LoteLaudoActions';

const LoteLaudoList = props => {
  const {
    onSearch,
    loteLaudos,
    pagination,
    onFavoriteLaudo,
    onViewFavoriteLaudo,
    onEditLaudo,
    onViewLaudo,
    onTransferir,
    onPrint,
    currentUser,
    onEstornar,
    onIndeferirLaudo,
    onPrintBoleto,
    getFields,
    onViewAnexos
  } = props;

  const actions = {
    onEditLaudo,
    onViewLaudo,
    onFavoriteLaudo,
    onViewFavoriteLaudo,
    onTransferir,
    onEstornar,
    onIndeferirLaudo,
    onPrintBoleto,
    onViewAnexos
  };

  const auth = useAuth();

  const hasFavoritePermission = auth.hasRole(Roles.acesso_prioritario.name);

  const paginationSearch = page => onSearch(undefined, page);

  const sortSearch = sort => onSearch(undefined, pagination, sort);

  return (
    <Panel isTable>
      <SearchFilter fields={getFields} search={onSearch} />
      <Table
        values={loteLaudos}
        highlight={item =>
          item.prioritario &&
          hasFavoritePermission &&
          item.situacaoLaudo.nome !== 'INDEFERIDO'
        }
        highlightError={item => item.situacaoLaudo.nome === 'INDEFERIDO'}
        columnSelector
        onPrint={onPrint}
        sortOnHeaderColumnClick={sortSearch}
      >
        <Table.Column
          header="Data Lançamento"
          name="dataLancamento"
          sortable
          value={item => (
            <FormattedDate value={item.dataLancamento} timeZone={'UTC'} />
          )}
        />
        <Table.Column
          header="Número do Pedido"
          name="numero"
          sortable
          value={item => item.numero || 'Não gerado'}
        />
        <Table.Column
          header="Requerente"
          name="requerente.nome"
          sortable
          value={item => item.requerente?.nome ?? item.requerenteNome}
        />
        <Table.Column
          header="Responsavel"
          name="responsavel.nome"
          sortable
          value={item => item.responsavel && item.responsavel.nome}
        />
        <Table.Column
          header="Situação"
          name="situacaoLaudo.descricao"
          sortable
          value={item =>
            item.situacaoLaudo && (
              <>
                {item.situacaoLaudo.descricao}
                {item.situacaoLaudo.nome === 'INDEFERIDO' && (
                  <Hint
                    classes={`inline clean module-color center-left fa-question-circle mobile sm`}
                  >
                    {item.motivoIndeferimento}
                  </Hint>
                )}
              </>
            )
          }
        />
        <Table.Column
          header="Pagamento"
          name="pago"
          sortable
          value={item => FunctionUtils.getLabelPagamentoLaudo(item)}
        />
        <Table.Column
          header="Valor Total"
          name="valorTotal"
          sortable
          value={item => (
            <FormattedNumber
              value={item.valorTotal}
              style="currency" // eslint-disable-line
              currency="BRL"
              currencyDisplay="symbol"
            />
          )}
        />
        <Table.Column
          header="Qtde Imóveis"
          name="qtdImoveis"
          defaultHidden
          value={item => item.qtdImoveis}
        />
        <Table.Column
          header="Prioritário"
          defaultHidden
          name="prioritario"
          sortable
          value={item => (item.prioritario ? 'Sim' : 'Não')}
        />
        <Table.Column
          header="Motivo Prioritário"
          defaultHidden
          name="motivoPrioritario"
          sortable
          value={item => item.motivoPrioritario}
        />
        <Table.Column
          header="Motivo Indeferimento"
          defaultHidden
          name="motivoIndeferimento"
          sortable
          value={item => item.motivoIndeferimento}
        />
        <Table.Column
          header="Origem"
          defaultHidden
          name="origemServidor"
          sortable
          value={item => (item.origemServidor ? 'Prefeitura' : 'On-line')}
        />
        <Table.Column
          header=""
          className="has-btn-actions"
          value={item =>
            renderActionButtonsLaudo(actions, { currentUser }, auth)(item)
          }
        />
      </Table>

      {pagination && (
        <SearchPagination
          page={pagination}
          searchWithPage={paginationSearch}
          showTotalRegistro={true}
        />
      )}
      <div className="btn-save">
        <FAB icon="plus" title="Novo Laudo" path={'/laudos/novo'} />
      </div>
    </Panel>
  );
};

LoteLaudoList.renderButtons = renderActionButtonsLaudo;

LoteLaudoList.propTypes = {
  currentUser: PropTypes.object,
  loteLaudos: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  onSearch: PropTypes.func.isRequired,
  onViewLaudo: PropTypes.func.isRequired,
  onEditLaudo: PropTypes.func.isRequired,
  onFavoriteLaudo: PropTypes.func.isRequired,
  onViewFavoriteLaudo: PropTypes.func.isRequired,
  onTransferir: PropTypes.func.isRequired,
  onEstornar: PropTypes.func.isRequired,
  onIndeferirLaudo: PropTypes.func.isRequired,
  onPrintBoleto: PropTypes.func.isRequired,
  getFields: PropTypes.array.isRequired,
  onViewAnexos: PropTypes.func.isRequired
};

export default LoteLaudoList;
