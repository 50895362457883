import {
  CollapseRow,
  DiffFunctions,
  DiffRow,
  DiffTable
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

const fieldNames = {
  tiposItbi: 'Tipos ITBI',
  propriedades: 'Imóveis',
  name: 'Nome',
  nome: 'Nome',
  campos: 'Campos',
  id: 'ID',
  campos_label: 'Campo',
  campos_tipoCampo: 'Tipo do Campo',
  instrucao: 'Instrução',
  obrigatorio: 'Obrigatório',
  procuracaoRequerente: 'Procuração Requerente',
  codigo: 'Código',
  codigoItbiAise: 'Código',
  descricao: 'Descrição',
  tipoCampo_component: 'Componente',
  tipoCampo_type: 'Tipo',
  tiposItbi_visivel: 'Visível',
  tiposItbi_normal: 'Normal',
  tiposItbi_anuencia: 'Anuencia',
  tiposItbi_financiado: 'Financiado'
};
const ignoreFields = [
  'situacaoLaudo_nome',
  'situacaoLaudo_mostrarDashboard',
  'id',
  'propriedades_id',
  'tiposItbi_id',
  'tipoItbiAise_id',
  'campos_id',
  'tiposItbi_tipoItbiAise'
];
const nestedFields = ['campos_tipoCampo'];

const dictionary = entity => path => {
  const unifiedPath = path.join('_').replace(/(.*?)_\d*?_(.*?)/, '$1_$2');

  if (ignoreFields.includes(unifiedPath)) {
    return undefined;
  }

  const index = path.find(pathSlice => typeof pathSlice === 'number');
  const prefix =
    path[0] === 'propriedades' && typeof path[1] === 'number'
      ? `Imóvel ${entity.propriedades[index].cadastro} - `
      : '';

  return `${prefix}${DiffFunctions.getFieldName(unifiedPath, fieldNames)}`;
};

const objectParser = (value, path) => {
  if (path.join('_') === 'campos_tipoCampo') {
    return value.descricao;
  }
  return JSON.stringify(value);
};

const isNested = path => nestedFields.includes(path.join('_'));

const revisionTypes = {
  ADD: 'Adicionado',
  MOD: 'Modificado',
  DEL: 'Removido'
};

const RowHeader = ({ revision, revisionType }) => {
  return (
    <React.Fragment>
      <td data-header="Data">
        <b className="td-label">Data</b>
        <FormattedDate value={revision.revisionDate} />{' '}
        <FormattedTime value={revision.revisionDate} />
      </td>
      <td data-header="Nome do Responsável">
        <b className="td-label">Nome do Responsável</b>
        {revision.userName}
      </td>
      <td>
        <b className="td-label">Tipo de Alteração</b>
        {revisionTypes[revisionType]}
      </td>
    </React.Fragment>
  );
};

const DocumentosItbiHistoryRow = ({ revision }) => {
  if (revision.diff.length === 0) {
    return null;
  }

  return (
    <CollapseRow
      className="reduced"
      buttonLabel="Visualizar Modificações"
      renderHeader={() => (
        <RowHeader
          revision={revision.revision}
          revisionType={revision.revisionType}
        />
      )}
    >
      <DiffTable>
        {revision.diff.map((diff, index) => (
          <DiffRow
            key={index}
            diff={diff}
            nameDictionary={dictionary(revision.entity)}
            objectParser={objectParser}
            isNested={isNested}
          />
        ))}
      </DiffTable>
    </CollapseRow>
  );
};

DocumentosItbiHistoryRow.propTypes = {
  revision: PropTypes.object.isRequired
};

export default DocumentosItbiHistoryRow;
