import {
  ActionType,
  Alert,
  Container,
  Loading,
  Row,
  useInterval,
  useShowNotification
} from '@elotech/components';
import { getErrorMessage } from '@elotech/components/src/utils/ErrorMessageUtils';
import { SincronizacaoDadosService } from 'itbi-common/service';
import {
  IntegracaoScheduler,
  StatusIntegracaoScheduler,
  TipoIntegracaoScheduler
} from 'itbi-common/type';
import React, { useEffect, useState } from 'react';
import { Notification } from 'react-notification-system';

import SincronizacaoScheduler from './SincronizacaoScheduler';

const titulosEMensagens = {
  SINCRONIZAR_CADASTRO: {
    title: 'Cadastros',
    successMessage: 'Sincronização de cadastros iniciada com sucesso.',
    errorMessage: 'Erro ao sincronizar cadastros.'
  },
  SINCRONIZAR_ENDERECO: {
    title: 'Endereços',
    successMessage: 'Sincronização de endereços iniciada com sucesso.',
    errorMessage: 'Erro ao sincronizar endereços.'
  },
  PAGAMENTOS_ITBI: {
    title: 'Pagamentos de ITBI',
    successMessage: 'Pagamentos de ITBI sincronizados com sucesso.',
    errorMessage: 'Erro ao sincronizar pagamentos de ITBI.'
  },
  PAGAMENTOS_LAUDO: {
    title: 'Pagamentos de laudo',
    successMessage: 'Pagamentos de laudo sincronizados com sucesso.',
    errorMessage: 'Erro ao sincronizar pagamentos de laudo.'
  },
  PEDIDOS: {
    title: 'Pedidos',
    successMessage: 'Pedidos sincronizados com sucesso.',
    errorMessage: 'Erro ao sincronizar pedidos.'
  }
};

const SincronizacaoDadosPage: React.FC = () => {
  const showNotification = useShowNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [integracaoScheduler, setIntegracaoScheduler] = useState<{
    [key: string]: IntegracaoScheduler;
  }>();
  const existsIntegracaoExecutando = integracaoScheduler
    ? Object.values(integracaoScheduler).some(
        integracao =>
          integracao.tipo === TipoIntegracaoScheduler.CADASTRO &&
          integracao.status === StatusIntegracaoScheduler.EXECUTANDO
      )
    : false;

  useEffect(() => {
    setLoading(true);
    getProgresso(showNotification);
  }, [showNotification]);

  useInterval(
    () => {
      getProgresso(showNotification);
    },
    existsIntegracaoExecutando ? 5000 : 10000
  );

  const getProgresso = (
    showNotification: (notification: Notification) => ActionType<Notification>
  ) => {
    SincronizacaoDadosService.getProgressoIntegracao()
      .then(response => {
        setIntegracaoScheduler(response.data);
      })
      .catch(async error => {
        showNotification({
          level: 'error',
          title: 'Erro ao buscar o progresso das sincronizações.',
          message: await getErrorMessage(error)
        });
      })
      .finally(() => setLoading(false));
  };

  const onConfirmSincronizar = (
    tipo: string,
    dataReferencia: string,
    sincronizarTodosEnderecos: boolean
  ) => {
    if (tipo === TipoIntegracaoScheduler.CADASTRO && !dataReferencia) {
      Alert.question({
        title:
          'Deseja sincronizar todos os cadastros existentes? Nenhuma data de referência foi informada e esta ação pode levar bastante tempo.'
      }).then(result => {
        if (result.value) {
          sincronizar(tipo, dataReferencia, sincronizarTodosEnderecos);
        }
      });
    } else {
      sincronizar(tipo, dataReferencia, sincronizarTodosEnderecos);
    }
  };

  const sincronizar = (
    tipo: string,
    dataReferencia: string,
    sincronizarTodosEnderecos: boolean
  ) => {
    sendRequest(tipo, dataReferencia, sincronizarTodosEnderecos)
      .then(() =>
        showNotification({
          level: 'success',
          message: titulosEMensagens[tipo].successMessage
        })
      )
      .catch(error => {
        Alert.error({ title: titulosEMensagens[tipo].errorMessage }, error);
      })
      .finally(() => {
        getProgresso(showNotification);
      });
  };

  const showInfoInicioSincronizacao = () => {
    showNotification({
      level: 'info',
      message: 'Sincronização iniciada.'
    });
  };

  const sendRequest = (
    tipo: string,
    dataReferencia: string,
    sincronizarTodosEnderecos: boolean
  ) => {
    switch (tipo) {
      case TipoIntegracaoScheduler.CADASTRO:
        return SincronizacaoDadosService.getPackage(dataReferencia);
      case TipoIntegracaoScheduler.ENDERECO:
        return SincronizacaoDadosService.getPackageEndereco(
          sincronizarTodosEnderecos
        );
      case TipoIntegracaoScheduler.PAGAMENTOS_ITBI:
        showInfoInicioSincronizacao();
        return SincronizacaoDadosService.verificarItbisPagosENotificar();
      case TipoIntegracaoScheduler.PAGAMENTOS_LAUDO:
        showInfoInicioSincronizacao();
        return SincronizacaoDadosService.verificarLaudosPagosENotificar();
      case TipoIntegracaoScheduler.PEDIDOS:
        showInfoInicioSincronizacao();
        return SincronizacaoDadosService.verificarPedidosENotificar();
      default:
        return Promise.resolve({});
    }
  };

  return (
    <Container title="Sincronização de Dados" icon="sync">
      <Loading loading={loading} />
      {integracaoScheduler && (
        <>
          <Row>
            {Object.values(TipoIntegracaoScheduler).map(tipo => (
              <SincronizacaoScheduler
                key={tipo}
                integracaoScheduler={integracaoScheduler[tipo]}
                titulo={titulosEMensagens[tipo].title}
                size={12}
                onSincronizar={onConfirmSincronizar}
              />
            ))}
          </Row>
        </>
      )}
    </Container>
  );
};

export default SincronizacaoDadosPage;
