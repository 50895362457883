import {
  ErrorText,
  InputDate,
  Masks,
  RadioButton,
  Row,
  SectionTitle,
  formatUtils,
  withAuth
} from '@elotech/components';
import {
  BairroAiseService,
  CepService,
  EnderecoAiseService,
  withService
} from 'itbi-common/service';
import { Constantes } from 'itbi-common/utils';
import { validateCpfCnpj } from 'itbi-common/utils/ValidationUtils';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

import { Roles } from '../../../roles';
import EnderecoSection from './EnderecoSection';
import EnderecoSelectSection from './EnderecoSelectSection';
import IdentificacaoRow from './IdentificacaoRow';

const StyledRadio = styled(RadioButton)`
  .radio-button-container {
    display: flex;
  }

  .radio-button-item-container {
    padding: 0 5px;
  }
`;

const emptyPerson = {
  tipoPessoa: 'FISICA',
  cpfCnpj: '',
  nome: '',
  email: '',
  celular: '',
  cep: '',
  logradouro: '',
  complemento: '',
  cidade: '',
  bairro: '',
  numero: '',
  uf: '',
  vinculo: '',
  percentual: '',
  percentualVenda: '',
  inseridoManual: true,
  rg: '',
  rgOrgaoEmissor: '',
  rgUf: '',
  rgDataEmissao: ''
};

const errorMessages = {
  cpfCnpj: 'O CPF/CNPJ está inválido.',
  cpfCnpjCadastrado: 'O CPF/CNPJ já foi adicionado na lista',
  nome: 'O nome é obrigatório.',
  nomeLength: 'Máximo 150 caracteres.',
  celular: 'O número do celular é obrigatório.',
  logradouro: 'O endereço é obrigatório.',
  logradouroLength: 'Máximo 100 caracteres.',
  cidade: 'A cidade é obrigatória.',
  cidadeLength: 'Máximo 100 caracteres.',
  bairro: 'O bairro é obrigatório',
  bairroLength: 'Máximo 100 caracteres.',
  cep: 'O CEP está inválido.',
  numero: 'Obrigatório.',
  numeroLength: 'Máximo 10 caracteres.',
  uf: 'O estado é obrigatório.',
  complementoLength: 'Máximo 100 caracteres.',
  vinculo: 'O vínculo é obrigatório.',
  percentual: 'O valor percentual deve estar entre 0 e 100',
  percentualObrigatorio: 'O percentual é obrigatório',
  percentualVendaObrigatorio: 'O percentual venda é obrigatório',
  percentualVenda: 'O valor percentual de venda deve estar entre 0 e 100',
  percentualVendaMaiorPercentual:
    'O valor percentual de venda não deve ser maior que o percentual ',
  percentualTotal: 'O valor total do percentual não deve exceder 100',
  compradorExisteListaVendedor: 'Proprietário já cadastrado como Vendedor',
  vendedorExisteListaComprador: 'Proprietário já cadastrado como Comprador',
  anuenteExisteListaVendedor: 'Anuente já cadastrado como Vendedor',
  anuenteExisteListaComprador: 'Anuente já cadastrado como Comprador',
  rg: 'O RG é obrigatório',
  rgOrgaoEmissor: 'O Órgão Emissor é obrigatório',
  rgUf: 'O Estado é obrigatório'
};

class IdentificacaoForm extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    list: PropTypes.array.isRequired,
    compradores: PropTypes.array,
    vendedores: PropTypes.array,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    cepService: PropTypes.shape({
      buscaCepAise: PropTypes.func.isRequired
    }).isRequired,
    type: PropTypes.oneOf(['COMPRADOR', 'VENDEDOR', 'ANUENTE', 'PROPRIETARIO'])
      .isRequired,
    vinculos: PropTypes.array.isRequired,
    onViewPessoa: PropTypes.func,
    auth: PropTypes.shape({
      hasRole: PropTypes.func.isRequired
    }).isRequired,
    bairroAiseService: PropTypes.shape({
      loadBairros: PropTypes.func.isRequired
    }).isRequired,
    enderecoAiseService: PropTypes.shape({
      loadLogradouros: PropTypes.func.isRequired,
      loadTiposLogradouro: PropTypes.func.isRequired
    }).isRequired,
    cidadeLogada: PropTypes.object.isRequired,
    canAdd: PropTypes.bool,
    validaRgComprador: PropTypes.bool,
    origemServidor: PropTypes.bool
  };

  static defaultProps = {
    canAdd: true,
    validaRgComprador: true,
    origemServidor: false
  };

  state = {
    currentPerson: undefined,
    loadingDoc: false,
    loadingCep: false,
    errors: {},
    position: undefined
  };

  // Essas validações retornam TRUE se o usuário estiver válido
  errorValidators = {
    cpfCnpj: person =>
      Boolean(person.cpfCnpj) && validateCpfCnpj(person.cpfCnpj),
    cpfCnpjCadastrado: (person, list) =>
      !list.some(p => p.cpfCnpj === person.cpfCnpj && p.id !== person.id),
    nome: person => Boolean(person.nome),
    nomeLength: person => person.nome && person.nome.length <= 150,
    celular: person =>
      !this.isComprador() ||
      this.props.origemServidor ||
      Boolean(person.celular),
    logradouro: person => Boolean(person.logradouro),
    logradouroLength: person =>
      person.logradouro && person.logradouro.length <= 100,
    logradouroAise: person => this.validaEnderecoAise(person.logradouroAise),
    tipoLogradouroAise: person =>
      this.validaEnderecoAise(person.tipoLogradouroAise),
    cidade: person => Boolean(person.cidade),
    cidadeLength: person => person.cidade && person.cidade.length <= 100,
    bairro: person => Boolean(person.bairro),
    bairroLength: person => person.bairro && person.bairro.length <= 100,
    bairroAise: person => this.validaEnderecoAise(person.bairroAise),
    cep: person => this.validaCep(person.cep),
    numero: person => Boolean(person.numero),
    numeroLength: person => person.numero && person.numero.length <= 10,
    complementoLength: person =>
      person.complemento ? person.complemento.length <= 100 : true,
    uf: person => Boolean(person.uf),
    vinculo: person => Boolean(person.vinculo),
    percentual: person => person.percentual > 0 && person.percentual <= 100,
    percentualVenda: person =>
      !this.isVendedor() ||
      (person.percentualVenda > 0 && person.percentualVenda <= 100),
    percentualVendaMaiorPercentual: person =>
      !this.isVendedor() ||
      (Boolean(person.percentualVenda) &&
        Boolean(person.percentual) &&
        person.percentual >= person.percentualVenda),
    percentualTotal: () => this.isProprietario() || this.validaPercentual(),
    percentualObrigatorio: person => Boolean(person.percentual),
    percentualVendaObrigatorio: person =>
      this.isVendedor() ? Boolean(person.percentualVenda) : true,
    compradorExisteListaVendedor: (person, list, compradores, vendedores) =>
      !this.isComprador() ||
      !this.validaProprietarioJaAdicionado(
        person.cpfCnpj,
        person.id,
        vendedores
      ),
    vendedorExisteListaComprador: (person, list, compradores) =>
      !this.isVendedor() ||
      !this.validaProprietarioJaAdicionado(
        person.cpfCnpj,
        person.id,
        compradores
      ),
    anuenteExisteListaVendedor: (person, list, compradores, vendedores) =>
      !this.isAnuente() ||
      !this.validaProprietarioJaAdicionado(
        person.cpfCnpj,
        person.id,
        vendedores
      ),
    anuenteExisteListaComprador: (person, list, compradores) =>
      !this.isAnuente() ||
      !this.validaProprietarioJaAdicionado(
        person.cpfCnpj,
        person.id,
        compradores
      ),
    rg: person => !this.validateRg(person) || person.rg,
    rgOrgaoEmissor: person => !this.validateRg(person) || person.rgOrgaoEmissor,
    rgUf: person => !this.validateRg(person) || person.rgUf
  };

  isVendedor = () => this.props.type === 'VENDEDOR';
  isComprador = () => this.props.type === 'COMPRADOR';
  isAnuente = () => this.props.type === 'ANUENTE';
  isProprietario = () => this.props.type === 'PROPRIETARIO';
  isProprietarioAise = () => {
    const { currentPerson } = this.state;
    return (
      this.isProprietario() && currentPerson && !currentPerson.inseridoManual
    );
  };

  validateRg = pessoa =>
    this.props.validaRgComprador && this.showFieldsRg(pessoa);

  showFieldsRg = pessoa =>
    this.props.type === 'COMPRADOR' && pessoa.tipoPessoa === 'FISICA';

  getPercentualLabel = () => {
    if (this.isComprador()) {
      return ' de Compra';
    }
    if (this.isVendedor()) {
      return ' de Propriedade';
    }
    return ' ';
  };

  existeErroCpfCnpj = () => {
    const { errors } = this.state;
    return [
      errors.cpfCnpj,
      errors.cpfCnpjCadastrado,
      errors.compradorExisteListaVendedor,
      errors.vendedorExisteListaComprador,
      errors.anuenteExisteListaVendedor,
      errors.anuenteExisteListaComprador
    ].some(Boolean);
  };

  erroCpfCnpj = () => {
    const { errors } = this.state;

    const firstError = [
      'cpfCnpj',
      'cpfCnpjCadastrado',
      'compradorExisteListaVendedor',
      'vendedorExisteListaComprador',
      'anuenteExisteListaVendedor',
      'anuenteExisteListaComprador'
    ].filter(field => Boolean(errors[field]))[0];
    return firstError ? errorMessages[firstError] : '';
  };

  validate = currentPerson => {
    const { list } = this.props;

    const fieldsToValidate = this.isProprietarioAise()
      ? ['percentual']
      : Object.keys(this.errorValidators);

    return fieldsToValidate
      .filter(field => {
        return (
          this.errorValidators[field] &&
          !this.errorValidators[field](currentPerson, list)
        );
      })
      .reduce((result, current) => {
        result[current] = true;
        return result;
      }, {});
  };

  validaCep = cep => {
    return Boolean(cep) && cep.length >= 8 && !this.state.errors.cep;
  };

  validaPercentual = () => {
    const { list } = this.props;
    const { currentPerson, position } = this.state;
    const percentual = list
      .filter((item, index) => index !== position)
      .reduce((acc, cur) => acc + cur.percentual, 0);

    return formatUtils.round(percentual + currentPerson.percentual, 2) <= 100;
  };

  validaEnderecoAise = campo => {
    const {
      currentPerson: { ibge }
    } = this.state;

    return this.sameCity(ibge) ? Boolean(campo) : true;
  };

  validaCpfCnpj = (cpfCnpj, currentPerson) => {
    const { list, vendedores, compradores } = this.props;

    const cpfCnpjErrors = {
      cpfCnpj: false,
      cpfCnpjCadastrado: false,
      compradorExisteListaVendedor: false,
      vendedorExisteListaComprador: false,
      anuenteExisteListaVendedor: false,
      anuenteExisteListaComprador: false
    };

    return Object.keys(cpfCnpjErrors)
      .filter(
        field =>
          this.errorValidators[field] &&
          !this.errorValidators[field](
            { cpfCnpj, id: currentPerson.id },
            list,
            compradores,
            vendedores
          )
      )
      .reduce((result, current) => {
        result[current] = true;
        return result;
      }, cpfCnpjErrors);
  };

  validaProprietarioJaAdicionado = (cpfCnpj, id, list) => {
    if (list === undefined) {
      return false;
    }
    return Object.values(list).some(
      item => item.cpfCnpj === cpfCnpj && item.id !== id
    );
  };

  adicionar = () => {
    const { onEdit } = this.props;
    const { currentPerson, position } = this.state;
    const currentPersonErrors = this.validate(currentPerson);

    if (!Object.values(currentPersonErrors).some(Boolean)) {
      onEdit(currentPerson, position).then(() => {
        this.setState({
          currentPerson: undefined,
          position: undefined
        });
      });
    } else {
      this.setState(prevState => ({
        errors: { ...prevState.errors, ...currentPersonErrors }
      }));
    }
  };

  cancelar = () => {
    this.setState({
      currentPerson: undefined,
      errors: {}
    });
  };

  onNew = () => {
    this.setState({
      currentPerson: { ...emptyPerson },
      position: undefined
    });
  };

  onEdit = position => () => {
    const { list } = this.props;
    this.setState({ currentPerson: list[position], position });
  };

  onDelete = position => () => {
    this.props.onDelete(position);
  };

  setPrincipal = currentPerson => {
    const principal = { ...currentPerson, principal: true };
    this.props.onSetPrincipal(principal);
  };

  onChangeInputValuePercentual = ({ floatValue }, event) => {
    const { name } = event.target;
    this.setState(state => {
      return {
        currentPerson: {
          ...state.currentPerson,
          [name]: floatValue
        },
        errors: {
          ...state.errors,
          [name]: false,
          percentualVendaMaiorPercentual: false,
          percentualTotal: false,
          percentualObrigatorio: false,
          percentualVendaObrigatorio: false
        }
      };
    });
  };

  onChangeInputValue = event => {
    const { name, value, dataset } = event.target;
    const newValue = ['cpfCnpj', 'cep', 'celular'].includes(name)
      ? value.replace(/\D/g, '')
      : dataset.percentage
      ? parseFloat(value.replace(',', '.').replace('%', ''))
      : value;

    this.setState(state => {
      const newName = name.includes('-')
        ? name.substring(0, name.indexOf('-'))
        : name;
      const newErrors =
        newName === 'cpfCnpj'
          ? this.validaCpfCnpj(newValue, state.currentPerson)
          : { [newName]: false };

      return {
        currentPerson: {
          ...state.currentPerson,
          [newName]: newValue
        },
        errors: {
          ...state.errors,
          ...newErrors,
          [`${newName}Length`]: false
        }
      };
    });
  };

  onChangeInputValueVinculo = event => {
    const { vinculos } = this.props;
    const { name, value } = event.target;

    const vinculoSelected = vinculos.find(vinculo => vinculo.id === value);

    this.setState(state => ({
      currentPerson: {
        ...state.currentPerson,
        [name]: vinculoSelected
      },
      errors: {
        ...state.errors,
        [name]: false
      }
    }));
  };

  onChangeCepValue = event => {
    const { name, value } = event.target;
    const newValue = value.replace(/\D/g, '');

    const newName = name.includes('-')
      ? name.substring(0, name.indexOf('-'))
      : name;

    this.setState(prevState => ({
      currentPerson: {
        ...prevState.currentPerson,
        [newName]: newValue
      },
      errors: {
        ...prevState.errors,
        [newName]: false,
        logradouro: false,
        bairro: false,
        cidade: false,
        uf: false
      }
    }));

    if (newValue.length >= 8) {
      this.setState({ loadingCep: true }, () => {
        this.props.cepService
          .buscaCepAise(newValue)
          .then(response =>
            this.setState(prevState => {
              let newState = {};
              if (response.data.cep) {
                newState = {
                  currentPerson: {
                    ...prevState.currentPerson,
                    cidadeAise: undefined,
                    bairroAise: undefined,
                    tipoLogradouroAise: undefined,
                    logradouroAise: undefined,
                    ...response.data
                  }
                };
              }
              return { ...newState, loadingCep: false };
            })
          )
          .catch(() => {
            this.setState(state => ({
              loadingCep: false,
              errors: { ...state.errors, cep: true }
            }));
          });
      });
    }
  };

  somaPercentual = list => {
    return formatUtils.round(
      list.map(item => item.percentual).reduce((acc, curr) => acc + curr, 0),
      2
    );
  };

  canEdit = () => {
    const { auth } = this.props;
    if (this.isComprador() && !auth.hasRole(Roles.itbi_editar_comprador.name))
      return false;
    if (this.isVendedor() && !auth.hasRole(Roles.itbi_editar_vendedor.name))
      return false;
    if (this.isAnuente() && !auth.hasRole(Roles.itbi_editar_anuente.name))
      return false;
    if (
      this.isProprietario() &&
      !auth.hasRole(Roles.itbi_editar_proprietario_remanescente.name)
    )
      return false;
    return true;
  };

  canDelete = () => {
    const { auth } = this.props;
    if (this.isComprador() && !auth.hasRole(Roles.itbi_remover_comprador.name))
      return false;
    if (this.isVendedor() && !auth.hasRole(Roles.itbi_remover_vendedor.name))
      return false;
    if (this.isAnuente() && !auth.hasRole(Roles.itbi_remover_anuente.name))
      return false;
    if (
      this.isProprietario() &&
      !auth.hasRole(Roles.itbi_remover_proprietario_remanescente.name)
    )
      return false;
    return true;
  };

  isBairroAise = name => {
    return name === 'bairroAise';
  };

  preencherBairro = (name, value) => {
    this.setState(state => {
      return {
        currentPerson: {
          ...state.currentPerson,
          [name]: value,
          bairro: value ? value.descricao : undefined
        },
        errors: {
          ...state.errors,
          [name]: false
        }
      };
    });
  };

  isLogradouroAise = name => {
    return name === 'logradouroAise';
  };

  preencherLogradouro = (name, value) => {
    this.setState(state => {
      return {
        currentPerson: {
          ...state.currentPerson,
          [name]: value,
          logradouro: value ? value.descricao : undefined
        },
        errors: {
          ...state.errors,
          [name]: false
        }
      };
    });
  };

  onSelect = (name, value) => {
    if (this.isBairroAise(name)) {
      this.preencherBairro(name, value);
    }
    if (this.isLogradouroAise(name)) {
      this.preencherLogradouro(name, value);
    }
    this.setState(state => {
      return {
        currentPerson: {
          ...state.currentPerson,
          [name]: value
        },
        errors: {
          ...state.errors,
          [name]: false
        }
      };
    });
  };

  sameCity = ibge => {
    return `${this.props.cidadeLogada.codigoIbge}` === ibge;
  };

  renderOptionsVinculo = () =>
    this.props.vinculos.map(vinculo => (
      <option key={vinculo.id} value={vinculo.id}>
        {vinculo.descricao}
      </option>
    ));

  renderHeaderSection = (showButton, title) => (
    <div>
      <SectionTitle hasButton={showButton}>
        {title}
        {showButton && (
          <button
            data-test-id="buttonNovo"
            className="btn module-color"
            onClick={this.onNew}
          >
            + Novo
          </button>
        )}
      </SectionTitle>
    </div>
  );

  render() {
    const {
      list,
      title,
      onViewPessoa,
      bairroAiseService,
      enderecoAiseService,
      canAdd
    } = this.props;
    const { currentPerson, errors, loadingCep } = this.state;

    return (
      <div>
        {this.renderHeaderSection(!currentPerson && canAdd, title)}
        {list.length > 0 && (
          <div className="display-data border small mt-xs has-btn-actions">
            {list.map((p, index) => (
              <IdentificacaoRow
                key={p.id}
                pessoa={p}
                vendedor={this.isVendedor()}
                proprietario={this.isProprietario()}
                onEdit={this.onEdit(index)}
                onDelete={this.onDelete(index)}
                onViewPessoa={onViewPessoa}
                showActionButtons
                onChangePrincipal={() => this.setPrincipal(p)}
                canEdit={this.canEdit()}
                canDelete={this.canDelete()}
              />
            ))}
            {this.isProprietario() && (
              <div className="row">
                <div className="col-sm-2 col-md-offset-8">
                  <b className="dd-title">Total</b>
                  <div className="dd-content right">
                    {this.somaPercentual(list)}%
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {currentPerson && (
          <div>
            <SectionTitle>Dados do {title}</SectionTitle>
            <StyledRadio
              id={`${title}`}
              label="Tipo"
              name={`tipoPessoa-${title}`}
              options={Constantes.optionsTipoPessoa}
              selectedValue={currentPerson.tipoPessoa}
              onChange={this.onChangeInputValue}
              disabled={this.isProprietarioAise()}
            />
            <div className="row">
              <div className="form-group col-sm-2">
                <label className="label">
                  {currentPerson.tipoPessoa === 'FISICA' ? 'CPF' : 'CNPJ'}
                </label>
                <MaskedInput
                  data-test-id="inputCpfCnpj"
                  type="text"
                  autoFocus
                  name="cpfCnpj"
                  mask={
                    currentPerson.tipoPessoa === 'FISICA'
                      ? Masks.MASK_CPF
                      : Masks.MASK_CNPJ
                  }
                  onChange={this.onChangeInputValue}
                  value={currentPerson.cpfCnpj || ''}
                  className={this.existeErroCpfCnpj() ? 'error' : ''}
                  maxLength={currentPerson.tipoPessoa === 'FISICA' ? 15 : 19}
                  disabled={this.isProprietarioAise()}
                />
                {this.existeErroCpfCnpj() && (
                  <ErrorText>{this.erroCpfCnpj()}</ErrorText>
                )}
              </div>
              <div className="form-group col-sm-4">
                <label className="label">Nome</label>
                <input
                  type="text"
                  name={`nome-${title}`}
                  className={errors.nome || errors.nomeLength ? 'error' : ''}
                  onChange={this.onChangeInputValue}
                  value={currentPerson.nome}
                  disabled={this.isProprietarioAise()}
                  autoComplete="off"
                />
                {errors.nome && <ErrorText>{errorMessages.nome}</ErrorText>}
                {errors.nomeLength && (
                  <ErrorText>{errorMessages.nomeLength}</ErrorText>
                )}
              </div>
              <div className="form-group col-sm-4">
                <label className="label">E-mail</label>
                <input
                  data-test-id="inputEmail"
                  type="email"
                  name={`email-${title}`}
                  className={errors.email ? 'error' : ''}
                  onChange={this.onChangeInputValue}
                  value={currentPerson.email}
                  disabled={this.isProprietarioAise()}
                  autoComplete="off"
                />
                {errors.email && <ErrorText>{errorMessages.email}</ErrorText>}
              </div>
              <div className="form-group col-sm-2">
                <label className="label">Celular</label>
                <NumberFormat
                  format="(##) #####-####"
                  name={`celular`}
                  className={errors.celular ? 'error' : ''}
                  onChange={this.onChangeInputValue}
                  value={currentPerson.celular}
                  disabled={this.isProprietarioAise()}
                />
                {errors.celular && (
                  <ErrorText>{errorMessages.celular}</ErrorText>
                )}
              </div>
            </div>
            {this.showFieldsRg(currentPerson) && (
              <Row>
                <div className="form-group col-sm-2">
                  <label className="label">RG</label>
                  <input
                    type="text"
                    name="rg"
                    className={errors.rg ? 'error' : ''}
                    onChange={this.onChangeInputValue}
                    value={currentPerson.rg}
                    autoComplete="off"
                  />
                  {errors.rg && <ErrorText>{errorMessages.rg}</ErrorText>}
                </div>
                <div className="form-group col-sm-2">
                  <label className="label">Órgão Emissor</label>
                  <input
                    type="text"
                    name="rgOrgaoEmissor"
                    className={errors.rgOrgaoEmissor ? 'error' : ''}
                    onChange={this.onChangeInputValue}
                    value={currentPerson.rgOrgaoEmissor}
                    autoComplete="off"
                  />
                  {errors.rgOrgaoEmissor && (
                    <ErrorText>{errorMessages.rgOrgaoEmissor}</ErrorText>
                  )}
                </div>
                <div className="form-group col-sm-2">
                  <label className="label">Estado</label>
                  <select
                    name="rgUf"
                    className={errors.rgUf ? 'error' : ''}
                    value={currentPerson.rgUf}
                    onChange={this.onChangeInputValue}
                  >
                    <option value="">Selecione</option>
                    {Constantes.optionsEstados.map(item => (
                      <option key={item.key} value={item.key}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  {errors.rgUf && <ErrorText>{errorMessages.rgUf}</ErrorText>}
                </div>
                <div className="form-group col-sm-2">
                  <label className="label">Data Emissão</label>
                  <InputDate
                    name="rgDataEmissao"
                    value={currentPerson.rgDataEmissao}
                    onChange={this.onChangeInputValue}
                  />
                </div>
              </Row>
            )}
            <div className="mt-xs" />
            {this.sameCity(currentPerson.ibge) ? (
              <EnderecoSelectSection
                title={title}
                currentPerson={currentPerson}
                errors={errors}
                loadingCep={loadingCep}
                errorMessages={errorMessages}
                onChangeCepValue={this.onChangeCepValue}
                onChangeInputValue={this.onChangeInputValue}
                loadBairrosAise={bairroAiseService.loadBairros}
                loadEnderecosAise={enderecoAiseService.loadLogradouros}
                loadTiposLogradouroAise={
                  enderecoAiseService.loadTiposLogradouro
                }
                onSelect={this.onSelect}
                readOnly={this.isProprietarioAise()}
              />
            ) : (
              <EnderecoSection
                title={title}
                currentPerson={currentPerson}
                errors={errors}
                loadingCep={loadingCep}
                errorMessages={errorMessages}
                onChangeCepValue={this.onChangeCepValue}
                onChangeInputValue={this.onChangeInputValue}
                readOnly={this.isProprietarioAise()}
              />
            )}
            <div className="mt-xs" />
            <SectionTitle>Vínculo do {title}</SectionTitle>
            <div className="row">
              <div className="form-group col-sm-4">
                <label className="label">Vínculo</label>
                <select
                  data-test-id="selectVinculo"
                  name="vinculo"
                  className={errors.vinculo ? 'error' : ''}
                  onChange={this.onChangeInputValueVinculo}
                  value={currentPerson.vinculo && currentPerson.vinculo.id}
                  disabled={this.isProprietarioAise()}
                >
                  <option value="">Selecione</option>
                  {this.renderOptionsVinculo()}
                </select>
                {errors.vinculo && (
                  <ErrorText>{errorMessages.vinculo}</ErrorText>
                )}
              </div>
              <div className="form-group col-sm-4">
                <label className="label">
                  Percentual{this.getPercentualLabel()}
                </label>
                <NumberFormat
                  data-test-id="inputPercentual"
                  name="percentual"
                  decimalSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                  data-percentage
                  onValueChange={this.onChangeInputValuePercentual}
                  className={
                    errors.percentual ||
                    errors.percentualTotal ||
                    errors.percentualObrigatorio
                      ? 'error'
                      : ''
                  }
                  value={currentPerson.percentual}
                />
                {!errors.percentual && errors.percentualTotal && (
                  <ErrorText>{errorMessages.percentualTotal}</ErrorText>
                )}
                {errors.percentual && (
                  <ErrorText>{errorMessages.percentual}</ErrorText>
                )}
                {errors.percentualObrigatorio && (
                  <ErrorText>{errorMessages.percentualObrigatorio}</ErrorText>
                )}
              </div>
              {this.isVendedor() && (
                <div className="form-group col-sm-4">
                  <label className="label">Percentual de Venda</label>
                  <NumberFormat
                    data-test-id="inputPercentualVenda"
                    name="percentualVenda"
                    decimalSeparator=","
                    decimalScale={2}
                    data-percentage
                    onValueChange={this.onChangeInputValuePercentual}
                    className={
                      errors.percentualVenda ||
                      errors.percentualVendaMaiorPercentual ||
                      errors.percentualVendaObrigatorio
                        ? 'error'
                        : ''
                    }
                    value={currentPerson.percentualVenda}
                  />
                  {errors.percentualVenda && (
                    <ErrorText>{errorMessages.percentualVenda}</ErrorText>
                  )}
                  {!errors.percentualVenda &&
                    errors.percentualVendaMaiorPercentual && (
                      <ErrorText>
                        {errorMessages.percentualVendaMaiorPercentual}
                      </ErrorText>
                    )}
                  {errors.percentualVendaObrigatorio && (
                    <ErrorText>
                      {errorMessages.percentualVendaObrigatorio}
                    </ErrorText>
                  )}
                </div>
              )}
            </div>
            <button
              data-test-id="buttonAdicionar"
              className="btn positive inline mt-xs"
              onClick={this.adicionar}
            >
              salvar
            </button>
            <button
              data-test-id="buttonCancelar"
              className="btn negative inline mt-xs"
              onClick={this.cancelar}
            >
              cancelar
            </button>
          </div>
        )}
      </div>
    );
  }
}

const ComponentWithAuth = withAuth(IdentificacaoForm);
const ComponentWithService = withService({
  cepService: CepService,
  bairroAiseService: BairroAiseService,
  enderecoAiseService: EnderecoAiseService
})(ComponentWithAuth);

const mapStateToProps = state => {
  return { cidadeLogada: state.cidade.cidadeLogada };
};

const ConnectedComponent = connect(mapStateToProps, null)(ComponentWithService);

export { ConnectedComponent as default, IdentificacaoForm };
