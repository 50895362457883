import {
  Alert,
  BasicInput,
  Container,
  FAB,
  FormikCheckBox,
  FormikInputInteger,
  FormikTextArea,
  Loading,
  NotificationActions,
  RadioButton,
  Row,
  Yup
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import { ParamParcelamentoService } from 'itbi-common/service';
import React from 'react';
import { Notification } from 'react-notification-system';
import { connect } from 'react-redux';
import { match } from 'react-router';

import { ParamParcelamento } from '../../../types';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
  showNotification(notification: Notification): void;
};

type State = {
  loading: boolean;
  paramParcelamento: ParamParcelamento;
};

export const validationSchema = Yup.object().shape({
  descricao: Yup.string()
    .max(250)
    .required()
    .label('Descrição do parâmetro'),
  termoConfissaoDivida: Yup.string()
    .max(4000)
    .label('Termo de confissão de dívida')
});

class ParamParcelamentoFormPage extends React.Component<Props, State> {
  state: State = {
    loading: false,
    paramParcelamento: {
      id: 0,
      descricao: '',
      tipo: '',
      ativo: true,
      disponivelCidadao: false,
      termoConfissaoDivida: ''
    }
  };

  componentDidMount() {
    this.setState({ loading: true });

    const { id } = this.props.match.params;
    ParamParcelamentoService.findById(id)
      .then(response => {
        this.setState({ paramParcelamento: response.data });
      })
      .catch(error => {
        Alert.error(
          {
            title: `Não foi possível carregar o parâmetro de parcelamento com id: ${id}.`
          },
          error
        );
      })
      .finally(() => this.setState({ loading: false }));
  }

  onConfirmSubmit = (data: ParamParcelamento) => {
    this.setState({ loading: true });
    return ParamParcelamentoService.alterar(data)
      .then(() => {
        this.props.showNotification({
          level: 'success',
          message: `Parâmetro de Parcelamento alterado com sucesso.`
        });
        this.props.history.replace('/parametro-parcelamento');
      })
      .catch(error => {
        Alert.error(
          { title: 'Não foi possível alterar o parâmetro de parcelamento.' },
          error
        );

        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, paramParcelamento } = this.state;

    return (
      <Container title="Parâmetro de Parcelamento" icon="file-invoice-dollar">
        <Loading loading={loading} />
        <Formik
          enableReinitialize
          initialValues={paramParcelamento}
          validationSchema={() => validationSchema}
          onSubmit={this.onConfirmSubmit}
          render={(formikProps: FormikProps<ParamParcelamento>) => (
            <React.Fragment>
              <Row>
                <BasicInput
                  data-test-id="tipoParametro"
                  size={6}
                  disabled={true}
                  name="tipo"
                  label="Tipo"
                  render={({ field }) => (
                    <RadioButton
                      {...field}
                      options={[
                        {
                          key: 'PARCELAMENTO',
                          value: 'P',
                          label: 'Parcelamento'
                        },
                        {
                          key: 'REPARCELAMENTO',
                          value: 'R',
                          label: 'Reparcelamento'
                        }
                      ]}
                      selectedValue={field.value}
                    />
                  )}
                />
                <FormikCheckBox
                  id="disponivelCidadao"
                  name="disponivelCidadao"
                  label="Disponível Cidadão"
                  size={2}
                  noLabel={false}
                />
                <FormikCheckBox
                  id="ativo"
                  name="ativo"
                  label="Parâmetro Ativo"
                  size={2}
                  noLabel={false}
                  disabled={true}
                />
              </Row>
              <Row>
                <FormikInputInteger
                  disabled={true}
                  size={2}
                  name="id"
                  label="Código"
                />
                <BasicInput size={10} name="descricao" label="Descrição" />
              </Row>
              <Row>
                <FormikTextArea
                  label="Termo de Confissão de Dívida"
                  name="termoConfissaoDivida"
                  maxLength={4000}
                  size={12}
                  resize="vertical"
                />
              </Row>

              <div className="btn-save">
                <FAB
                  data-test-id="buttonSave"
                  icon="check"
                  iconColor="white"
                  title="Alterar parâmetro"
                  onClick={formikProps.submitForm}
                />
              </div>
            </React.Fragment>
          )}
        />
      </Container>
    );
  }
}

const ConnectedComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(ParamParcelamentoFormPage);

export { ConnectedComponent as default, ParamParcelamentoFormPage };
