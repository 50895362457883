import { BreadCrumbRoute } from '@elotech/components';

import DeclaracaoContestacaoPage from '../pages/declaracao-itbi/DeclaracaoContestacaoPage';
import DeclaracaoItbiAnaliseFormPage from '../pages/declaracao-itbi/DeclaracaoItbiAnaliseFormPage';
import DeclaracaoItbiListPage from '../pages/declaracao-itbi/DeclaracaoItbiListPage';
import DeclaracaoItbiManualFormPage from '../pages/declaracao-itbi/DeclaracaoItbiManualFormPage';
import DeclaracaoItbiViewPage from '../pages/declaracao-itbi/DeclaracaoItbiViewPage';
import DeclaracaoTransferenciaPage from '../pages/declaracao-itbi/DeclaracaoTransferenciaPage';
import GeraDebito from '../pages/declaracao-itbi/GeraDebito.react';
import { Roles } from '../roles';

const routesItbi: BreadCrumbRoute[] = [
  {
    title: 'Declarações ITBI',
    icon: 'paste',
    path: '/declaracoes-itbi',
    component: DeclaracaoItbiListPage,
    role: Roles.itbi_visualizar.name
  },
  {
    title: 'Declarações ITBI',
    icon: 'paste',
    parent: '/declaracoes-itbi',
    path: '/declaracoes-itbi/novo',
    component: DeclaracaoItbiManualFormPage
  },
  {
    title: 'Declarações ITBI',
    icon: 'paste',
    parent: '/declaracoes-itbi',
    path: '/declaracoes-itbi/:id/edit',
    component: DeclaracaoItbiManualFormPage
  },
  {
    title: 'Declarações ITBI',
    icon: 'paste',
    parent: '/declaracoes-itbi',
    path: '/declaracoes-itbi/:id',
    component: DeclaracaoItbiAnaliseFormPage
  },
  {
    title: 'Declarações ITBI',
    icon: 'paste',
    parent: '/declaracoes-itbi',
    path: '/declaracoes-itbi/:id/resumo',
    component: DeclaracaoItbiViewPage
  },
  {
    title: 'Declarações ITBI',
    icon: 'paste',
    parent: '/declaracoes-itbi',
    path: '/declaracoes-itbi/:id/confirmacao-gerar-debito',
    component: GeraDebito,
    role: Roles.itbi_gerar_debito.name
  },
  {
    title: 'Declarações ITBI',
    icon: 'paste',
    parent: '/declaracoes-itbi',
    path: '/declaracoes-itbi/:id/transferencia',
    component: DeclaracaoTransferenciaPage,
    role: Roles.transferir_responsavel.name
  },
  {
    title: 'Declarações ITBI',
    icon: 'paste',
    parent: '/declaracoes-itbi',
    path: '/declaracoes-itbi/:id/analisar-contestacao',
    component: DeclaracaoContestacaoPage,
    role: Roles.analisar_contestacao.name
  }
];

export default routesItbi;
