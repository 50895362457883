import { Alert } from '@elotech/components';

const motivoValidator = (value, processo) => {
  return new Promise(resolve => {
    if (!value) {
      return resolve(`Descreva o motivo do(a) ${processo}!`);
    } else if (value.length > 4000) {
      return resolve(
        `Motivo do(a) ${processo} deve possuir no máximo 4000 caracteres.`
      );
    }
    return resolve();
  });
};

const AlertQuestion = prioritario => {
  if (prioritario) {
    return Alert.question({
      title: 'Deseja cancelar esta priorização?',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: true
    });
  } else {
    return Alert.question({
      title: 'Qual o motivo da priorização?',
      input: 'textarea',
      inputPlaceholder: 'Descreva o motivo da priorização',
      inputValidator: value => motivoValidator(value, 'priorização'),
      confirmButtonText: 'Priorizar',
      cancelButtonText: 'Cancelar'
    });
  }
};

const LoteLaudoUtils = {
  onIndeferirLaudo: (
    laudoService,
    laudo,
    showNotification,
    setState,
    onSearch
  ) => {
    return new Promise(response => {
      Alert.question({
        title: 'Qual o motivo do indeferimento?',
        input: 'textarea',
        inputPlaceholder: 'Descreva o motivo do indeferimento',
        inputValidator: value => motivoValidator(value, 'indeferimento'),
        confirmButtonText: 'Indeferir',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          setState({ submittingLaudo: true });

          laudoService
            .indeferir(laudo, { motivo: result.value })
            .then(result => {
              setState({ submittingLaudo: false });
              showNotification &&
                showNotification({
                  level: 'success',
                  message: 'Laudo indeferido com sucesso.'
                });
              onSearch && onSearch(undefined, undefined);
              response(result);
            })
            .catch(error => {
              setState({ submittingLaudo: false });
              Alert.error(
                { title: 'Não foi possível indeferir o laudo.' },
                error
              );
            });
        }
      });
    });
  },

  onViewFavoriteLaudo: motivoPrioritario => {
    Alert.info({
      title: 'Motivo da Priorização',
      text: motivoPrioritario
    });
  },

  onFavoriteLaudo: (
    laudo,
    laudoService,
    setState,
    showNotification,
    onSearch
  ) => {
    return new Promise(response => {
      AlertQuestion(laudo.prioritario).then(result => {
        if (result.value) {
          setState({ submittingLaudo: true });

          if (result.value === true) {
            result.value = undefined;
          }

          return laudoService
            .togglePrioritario(laudo.id, { motivo: result.value })
            .then(result => {
              setState({ submittingLaudo: false });
              showNotification({
                level: 'success',
                message: 'Prioridade do laudo alterada com sucesso.'
              });
              onSearch && onSearch(undefined, undefined);
              response(result);
            })
            .catch(error => {
              setState({ submittingLaudo: false });
              Alert.error(
                { title: 'Não foi possível priorizar o laudo.' },
                error
              );
            });
        }
      });
    });
  },

  onTransferirResponsavel: (laudo, history) => {
    history.push(`/laudos/${laudo}/transferencia`);
  },

  onEstornar: (laudo, laudoService, setState, showNotification, onSearch) => {
    return new Promise(response => {
      Alert.question({
        title: 'Deseja voltar esse laudo para análise?',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        allowOutsideClick: true
      }).then(result => {
        if (result.value) {
          setState({ submittingLaudo: true });
          return laudoService
            .estornar(laudo)
            .then(result => {
              showNotification({
                level: 'success',
                message: 'Laudo estornado com sucesso.'
              });
              onSearch && onSearch(undefined, undefined);
              response(result);
            })
            .catch(error => {
              Alert.error(
                { title: 'Não foi possível estornar o laudo.' },
                error
              );
            })
            .finally(() => setState({ submittingLaudo: false }));
        }
      });
    });
  },

  onEditLaudo: (laudo, history) => {
    history.push(`/laudos/${laudo}`);
  },

  onViewAnexos: (laudo, history) => {
    history.push(`/laudos/${laudo}/anexos`);
  },

  onGerarBoleto: (laudo, laudoService, setState, onSearch) => {
    return new Promise(resolve => {
      setState({ submittingLaudo: true });
      return laudoService
        .gerarBoleto(laudo.id)
        .then(result => {
          onSearch && onSearch(undefined, undefined);
          resolve(result);
        })
        .catch(error => {
          Alert.error({ title: 'Não foi possível gerar o boleto' }, error);
        })
        .finally(() => {
          setState({ submittingLaudo: false });
        });
    });
  },

  onGerarDebitos: (laudo, laudoService, setState, onSearch) => {
    return new Promise(resolve => {
      setState({ submittingLaudo: true });
      return laudoService
        .gerarDebitos(laudo.id)
        .then(result => {
          onSearch && onSearch(undefined, undefined);
          resolve(result.data);
        })
        .catch(error => {
          Alert.error({ title: 'Não foi possível gerar os débitos' }, error);
        })
        .finally(() => {
          setState({ submittingLaudo: false });
        });
    });
  }
};

export default LoteLaudoUtils;
