import axios, { AxiosPromise } from 'axios';

import { IntegracaoScheduler } from '../type';

export const getPackage = (dataReferencia: string): AxiosPromise<void> =>
  axios.get('/api/integracao/itbi-assincrono', {
    params: {
      since: dataReferencia
    }
  });

export const getPackageEndereco = (
  sincronizarTodosEnderecos: boolean
): AxiosPromise<void> =>
  axios.post('/api/integracao/itbi-enderecos-assincrono', {
    sincronizarTodosEnderecos
  });

export const verificarItbisPagosENotificar = (): AxiosPromise<void> =>
  axios.post('/api/integracao/pagamento-itbi');

export const verificarLaudosPagosENotificar = (): AxiosPromise<void> =>
  axios.post('/api/integracao/pagamento-laudo');

export const verificarPedidosENotificar = (): AxiosPromise<void> =>
  axios.post('/api/integracao/pedidos');

export const getProgressoIntegracao = (): AxiosPromise<{
  [key: string]: IntegracaoScheduler;
}> => axios.get('/api/integracao/progresso');
